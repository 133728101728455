import { useEffect, useRef, useState } from "react";
import style from "./SignerCarousel.module.css";
import Slider from "react-slick";
import axios from "axios";

const settingsWithModules = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: style.button__bar,
};

export const SignerCarousel = ({ currentNumber, setCurrentNumber }) => {
  // const [isVisible, setIsVisible] = useState(false);
  // const [currentNumber, setCurrentNumber] = useState(59700);
  // const targetNumber = 59753;
  // const animationIntervalRef = useRef(null);

  const sectionRef = useRef(null);

  // useEffect(() => {
  //   const sectionObserver = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           setIsVisible(true);

  //           const totalSteps = 100; // Set the total number of steps
  //           const stepSize = (targetNumber - currentNumber) / totalSteps;

  //           animationIntervalRef.current = setInterval(() => {
  //             if (currentNumber < targetNumber) {
  //               setCurrentNumber((prevNumber) => prevNumber + stepSize);
  //             } else {
  //               clearInterval(animationIntervalRef.current);
  //             }
  //           }, 10); // Use a smaller interval for smoother animation
  //         } else {
  //           clearInterval(animationIntervalRef.current);
  //           setIsVisible(false);
  //         }
  //       });
  //     },
  //     {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.5, // Adjust this threshold value as needed
  //     }
  //   );

  //   if (sectionRef.current) {
  //     sectionObserver.observe(sectionRef.current);
  //   }

  //   return () => {
  //     if (sectionRef.current) {
  //       sectionObserver.unobserve(sectionRef.current);
  //     }
  //     clearInterval(animationIntervalRef.current);
  //   };
  // }, [currentNumber]);

  return (
    <section className={style.container}>
      <div className={style.signerCarouselContainer} ref={sectionRef}>
        <div className={style.signersData}>
          <div className={style.signersDataText}>
            <p>DOI SIGNERS</p>
            <span>{Math.floor(currentNumber).toLocaleString()}</span>
          </div>
          <a href="#sign">Become a Signer</a>
        </div>
        <div className={style.signerCarousel}>
          {/* <p className={style.signerCarouselTitle}>Why did we sign it?</p> */}
          <div style={{ maxWidth: "800px" }}>
            {/* <div className={style.carouselImagePlaceholderContainer}>
              <Image
                className={style.carouselImagePlaceholder}
                src={"/assets/banner-divier-image.png"}
                alt=""
                position="relative"
                zIndex={-2}
                objectFit="cover"
                filter={"grayscale(100%)"}
                height={"360px"}

                // w="600px"
                // h={{ base: "200px", md: "300px", xl: "100%" }}
              />
            </div> */}
            {/* <Slider {...settingsWithModules}>
              {[...Array(4).keys()].map((item) => (
                <div className={style.slide} key={item}>
                  <div className={style.slideContainer}>
                    <div className={style.slideContainerSigner}>
                      <img src="/assets/signer_example.png" alt="" />
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </div>

                    <div className={style.slideLocation}>
                      <p>Greg Strause</p>
                      <span>Wyoming</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider> */}
          </div>
        </div>
      </div>
    </section>
  );
};
