import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import "../styles/Home.css";
import "../styles/size.css";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";

import { ReactSVG } from "react-svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import TagManager from "react-gtm-module";

const ReachOutRS = () => {
  const [activeIndex, setActiveIndex] = useState(2);

  const videoRef = useRef(null);

  const navigate = useNavigate();

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const goDown = () => {
    const targetElement = document.getElementById("down");
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const purchaseOptions = [
    { item: "small_signature" },
    { item: "medium_signature" },
    { item: "hancock_signature" },
  ];

  const handlePurchase = () => {
    if (activeIndex !== null) {
      // Navegar a la URL correspondiente según el índice activo
      axios
        .post("/api/generate-payment-link", {
          email: localStorage.getItem("email"),
          item: purchaseOptions[activeIndex].item,
        })
        .then((res) => {
          // TagManager.dataLayer({
          //   dataLayer: {
          //     event: "itemSold",
          //   },
          // });
          // redirect to the URL from the response
          window.location.href = res.data;
        });
    }
  };

  const emailLogged = localStorage.getItem("email") || null;

  const [signature, setSignature] = useState("");

  const [svg, setSvg] = useState("");

  const token = localStorage.getItem("token") || null;
  let [searchParams, setSearchParams] = useSearchParams();

  const [bought, setBought] = useState(false);

  const [videoPaused, setVideoPaused] = useState(false);

  const handleVideoToggle = () => {
    setVideoPaused(!videoPaused);
  };

  useEffect(() => {
    axios.get(`/api/user/orders?email=${emailLogged}`).then((res) => {
      console.log(res.data);
      if (res.data.boughtSignature == true) {
        setBought(true);
      }
    });
  }, []);

  useEffect(() => {
    if (emailLogged) {
      axios
        .get(`/api/user?email=${emailLogged}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const usr = res.data;

            setSignature(usr.signature.name);
            setSvg(usr.signature.svg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.log(error);
        // Autoplay was prevented, possibly due to browser policies
        // Handle the error or inform the user
      });
    }
  }, []);

  const svgWidths = ["17%", "20%", "26%"];
  const fontSizes = ["1.8rem", "2.5rem", "3.8rem"];
  return (
    <section
      style={{
        width: "100vw",
        minHeight: "93.8vh",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
      }}
      className="sec-size2"
    >
      {/* <a href="/thanks">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="is-mobile"
          style={{
            marginLeft: "1rem",
            position: "absolute",
            top: "80px",
            left: "10px",
            color: "white",
            height: "25px",
            marginBottom: "1rem",
          }}
        />
      </a> */}

      <Heading
        as="h1"
        fontSize="4xl"
        style={{
          color: "white",
          textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
          marginBottom: "1rem",
          marginTop: "2rem",
          textAlign: "center",
        }}
        className="titlers"
      >
        Roger Stone supports The DOI Project <br className="br" /> just like you
        do!
      </Heading>

      <div className="video-container">
        <video
          className="video"
          src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/BRAVE_CUT_v03.mp4"
          autoPlay
          loop
          playsInline
          controls // Show play/pause controls
          disablePictureInPicture // Disable picture-in-picture mode
          controlsList="nodownload noplaybackrate" // Disable download and playback speed controls
          disablefullscreen // Disable fullscreen mode
          ref={videoRef}
        />
      </div>

      <div
        id="down"
        className="div-first3"
        style={{ textAlign: "center", marginTop: "-2rem" }}
      >
        {/* <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginBottom: "1rem",
          }}
          className="title"
        >
          Help us protect our future!
        </Heading> */}
        <Text
          fontSize="xl"
          color={"white"}
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
          }}
          className="subro"
        >
          Be Brave like Roger Stone and join the DOIP Team in Delivering the
          physical copy of The DOI to congress!{" "}
        </Text>
        <Text
          className="subtext"
          fontSize="l"
          color={"white"}
          style={{
            // marginTop: "0.6rem",
            marginBottom: "0.5rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
            // fontWeight: "regular",
          }}
          // className="subro"
        >
          Select a size for your signature on the physical Declaration of
          Independence that will be presented to Congress.{" "}
        </Text>
      </div>
      <div className="reachoutrs">
        <div className="container container2">
          <div className="signature-overlay">
            {activeIndex >= 0 && activeIndex <= 2 && (
              <div className="preview-img2" style={{ position: "relative" }}>
                <img
                  className="preview-img2"
                  src="\assets\signature-preview_hancock.png"
                  alt=""
                />

                {svg ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "65%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: svgWidths[activeIndex],
                      maxWidth: "70vw !important",
                    }}
                  >
                    <ReactSVG src={svg} />
                  </div>
                ) : (
                  <h1
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "65%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontFamily: "Beth Ellen",
                      fontSize: fontSizes[activeIndex],
                      userSelect: "none",
                      WebkitUserSelect: "none",
                    }}
                    className={`size${activeIndex + 1}`}
                  >
                    {signature}
                  </h1>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="container container2 containerrs">
          <button
            className={`square2 ${activeIndex === 0 ? "active2" : ""}`}
            onClick={() => handleClick(0)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $3{" "}
            </h1>
            <h1 className="text">Percenters</h1>

            <h1 className="sub">Small Signature</h1>
          </button>
          <button
            className={`square2 ${activeIndex === 1 ? "active2" : ""}`}
            onClick={() => handleClick(1)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $13{" "}
            </h1>
            <h1 className="text">Colonies</h1>

            <h1 className="sub">Medium Signature</h1>
          </button>
          <button
            className={`square2 ${activeIndex === 2 ? "active2" : ""}`}
            onClick={() => handleClick(2)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span
              className="most-popular"
              style={{
                position: "absolute",
                top: "-5px",
                backgroundColor: "#b22234",
                width: "101%",
                borderRadius: "12px 12px 0px 0px",
                textAlign: "center",
              }}
            >
              {" "}
              Most Popular
            </span>
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $17.76{" "}
            </h1>
            <h1 className="text">Founders</h1>

            <h1 className="sub">John Hancock Sized</h1>
          </button>
        </div>
      </div>

      <div className="container3" style={{ marginBottom: "3rem" }}>
        <div className="button-container2">
          {bought ? (
            <button
              style={{
                backgroundColor: "#b22234",
                borderColor: "#b22234",
                cursor: "default",
              }}
              className="purchase-button2"
              onClick={handlePurchase}
              disabled={bought}
            >
              <div>
                <span>
                  You already paid for your Signature. Thanks for your support!
                </span>
              </div>
            </button>
          ) : (
            <button
              style={{
                backgroundColor: bought ? "gray" : "#b22234",
                borderColor: bought ? "gray" : "#b22234",
              }}
              className="purchase-button2"
              onClick={handlePurchase}
              disabled={bought}
            >
              <div>
                <span>Purchase Now</span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginLeft: "1rem" }}
                />
              </div>
              <div>
                {activeIndex === 0 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x Small signature
                  </div>
                )}
                {activeIndex === 1 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x Medium signature
                  </div>
                )}
                {activeIndex === 2 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x John Hancock sized signature
                  </div>
                )}
              </div>
            </button>
          )}

          {/* {bought ? (
            <a href="/shop" style={{ marginRight: "10px" }}>
              <button
                className="cancel-button2"
                // onClick={handleCancel} // Función de manejo de clic en cancelar
              >
                Take me to the Shop!
              </button>
            </a>
          ) : (
            <a href="/declaration" style={{ marginRight: "10px" }}>
              <button
                style={{ marginBottom: "2rem" }}
                className="cancel-button2"
                // onClick={handleCancel} // Función de manejo de clic en cancelar
              >
                Go back
              </button>
            </a>
          )} */}
        </div>
      </div>
      <button className="floating-button-pay" onClick={goDown}>
        <FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "1rem" }} />
        Join Us
      </button>
    </section>
  );
};

export default ReachOutRS;
