import style from "./OurMission.module.css";

export const OurMission = () => {
  return (
    <section className={style.container}>
      <div className={style.ourMissionContainer}>
        <div className={style.ourMissionData}>
          <div className={style.ourMissionTopData}>
            <p className={style.ourMissionTopDataTitle}>Our Mission</p>
            <p className={style.ourMissionTopDataDescription}>
              Unify our great nation, taking inspiration from the very document
              that embodies every American’s value.
            </p>
          </div>
          <p className={style.ourMissionDescription}>
            In an era where its significance seems to be overlooked by Congress,
            our project emphasizes its pivotal role in educating our youth and
            future generations. It's a reminder, a call to action, to recognize
            and uphold the principles that guarantee our freedom.
          </p>
          <a href="#sign">Stand with us!</a>
        </div>
        <img
          src="/assets/divided_face_image.webp"
          alt="decorative"
          className={style.dividedFaceImage}
        />
        <img
          src="/assets/divided_face_background.webp"
          alt="decorative"
          className={style.dividedFaceBg}
        />
      </div>
    </section>
  );
};
