import style from "./Freedom.module.css";
import { OpenQuote } from "./OpenQuote";
import { ClosingQuote } from "./ClosingQuote";

export const Freedom = () => {
  return (
    <div className={style.container}>
      <div className={style.freedomContainer}>
        <div
          className={style.freedomTopData}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <p className={style.freedomTopDataTitle}>Freedom of expression</p>
          <p className={style.freedomTopDataDescription}>
            Freedom of expression is a legacy we must protect for future
            generations. Losing this right would stifle individual voices and
            stifle progress. By defending it, we ensure a more open and
            enlightened future for our children.
          </p>
          <a href="#sign">I want to join</a>
        </div>
        <div
          className={style.freedomImageContainer}
          data-aos="fade-up"
          data-aos-delay={100}
          data-aos-once="true"
        >
          <img
            src="/assets/john_hancock.webp"
            alt="decorative"
            className={style.johnHancockImage}
          />
          <div className={style.freedomImageText}>
            <div className={style.freedomOpenQuote}>
              <OpenQuote />
            </div>
            <p>
              We must be unanimous; there must be no pulling different ways; we
              must hang together.
            </p>
            <div className={style.freedomClosingQuote}>
              <ClosingQuote />
            </div>
            <p>John Hancock</p>
          </div>
        </div>
      </div>
    </div>
  );
};
