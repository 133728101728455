export const ClosingQuote = () => {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8695 0.406252L33.8695 7.92129C33.8695 10.0546 33.4523 12.2364 32.618 14.4667C31.7503 16.7292 30.6156 18.8464 29.2139 20.8181C27.8123 22.8221 26.3105 24.519 24.7086 25.9089L17.7002 22.0787C18.8349 20.1716 19.8361 18.103 20.7038 15.8727C21.5715 13.6424 22.0054 10.992 22.0054 7.92129V0.406251L33.8695 0.406252ZM16.5989 0.406251V7.92129C16.5989 10.0546 16.1818 12.2364 15.3474 14.4667C14.4797 16.7292 13.3451 18.8464 11.9434 20.8181C10.5417 22.8221 9.03993 24.519 7.43802 25.9089L0.429688 22.0787C1.56437 20.1716 2.56556 18.103 3.43326 15.8727C4.30096 13.6424 4.73481 10.992 4.73481 7.92129L4.73481 0.40625L16.5989 0.406251Z"
        fill="#3F3E3E"
      />
    </svg>
  );
};
