import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import {
  Box,
  useBreakpointValue,
  Heading,
  Text,
  Center,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Testimonials = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [modalWidth, setModalWidth] = useState("50%");
  const containerRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [loading, setLoading] = useState(false);
  const currentPageRef = useRef(currentPage);
  const [isFetching, setIsFetching] = useState(false);
  let firstRender = false;

  function debounce(func, wait) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  }

  const debouncedHandleScroll = useRef(
    debounce(() => {
      if (isFetching) return;
      setIsFetching(true);
      if (!loading && containerRef.current) {
        const containerBottom =
          containerRef.current.offsetTop + containerRef.current.offsetHeight;
        if (window.innerHeight + window.scrollY >= containerBottom) {
          // let newPage = currentPageRef.current + 1;
          fetchTestimonials();
        }
      }
    }, 500) // Adjust the debounce delay as needed
  ).current;

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    // const newPage = currentPage + 1;
    if (!firstRender) fetchTestimonials(); // Fetch initial testimonials when the component mounts
    firstRender = true;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  const openModal = (video) => {
    setSelectedVideo(video);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setModalIsOpen(false);
  };

  const updateModalSize = () => {
    if (window.innerWidth <= 768) {
      setModalWidth("90vw");
    } else {
      setModalWidth("50%");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateModalSize);
    return () => {
      window.removeEventListener("resize", updateModalSize);
    };
  }, []);

  const fetchTestimonials = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/testimonials?page=${currentPageRef.current}&pageSize=${pageSize}`
      );
      const newTestimonials = response.data;
      if (newTestimonials.length > 0) {
        setTestimonials((prevTestimonials) => [
          ...prevTestimonials,
          ...newTestimonials,
        ]);
      }
      let newPage = currentPageRef.current + 1;
      setCurrentPage(newPage);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    } finally {
      setLoading(false);
    }
  };

  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
    },
    content: {
      width: modalWidth,
      height: "fit-content",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
    },
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
    height: "25px",
    color: "white",
    zIndex: "999",
  };

  const videoStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "3px",
  };

  return (
    <div
      ref={containerRef}
      style={{
        backgroundColor: "#171923",
        minHeight: "94vh",
      }}
    >
      <Center backgroundColor="white">
        <Box textAlign="center" mb="4" style={{ color: "black" }}>
          <Heading
            as="h1"
            fontSize="4xl"
            style={{
              marginBottom: "1rem",
              marginTop: "3rem",
              color: "#b22234",
            }}
          >
            Testimonials
          </Heading>
          <Box
            // bg="rgba(000, 000, 000, 0.1)"
            p={4}
            borderRadius="md"
            style={{ width: "41vw", textAlign: "center", width: "100vw" }}
          >
            <FontAwesomeIcon icon={faPeopleGroup} size="2x" />
            <Text mt={2}>
              <div style={{ textAlign: "center", padding: "1rem" }}>
                <Text
                  fontSize="xl"
                  style={{
                    marginBottom: "1rem",
                    margin: "0 auto",
                    fontWeight: "500",
                  }}
                >
                  Listen to What Our Supporters Have to Say About The DOI
                  Project and Why They Support Us
                </Text>
                <Text
                  fontSize="xl"
                  style={{
                    marginTop: "1rem",
                  }}
                >
                  If you'd like to share your testimonial about why you support
                  The DOI Project, you can record a video and send it to us via
                  email at
                  <b> info@sign1776.com</b>. Your video will be featured on this
                  page, joining the voices of our other esteemed supporters!
                </Text>
              </div>
            </Text>
          </Box>
        </Box>
      </Center>

      <Box
        display="grid"
        gridTemplateColumns={useBreakpointValue({
          base: "repeat(auto-fill, minmax(200px, 1fr))",
          lg: "repeat(3, 1fr)",
        })}
        gap="2.5rem"
        padding="20px"
        maxW="75vw"
        margin="0 auto"
        style={{ color: "black", marginBottom: "30px" }}
      >
        {testimonials.map((testimonial, index) => (
          <div
            key={testimonial._id + index}
            onClick={() => openModal(testimonial.payload)}
          >
            <img
              src={testimonial.thumbnail}
              alt="Video Thumbnail"
              style={videoStyle}
            />
            <p style={{ fontWeight: "bold", color: "white" }}>
              {testimonial.name}
            </p>
          </div>
        ))}
      </Box>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        style={modalStyle}
      >
        <FontAwesomeIcon
          icon={faTimes}
          style={closeButtonStyle}
          onClick={closeModal}
        />
        {selectedVideo && (
          <div
            style={{
              width: "100%",
              position: "relative",
              paddingTop: "56.25%",
            }}
          >
            <video
              controls
              autoPlay
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
            >
              <source src={selectedVideo} type="video/mp4" />
            </video>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Testimonials;
