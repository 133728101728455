import React from "react";

const Terms = () => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        color: "#000",
        padding: "50px",
        maxWidth: "800px",
        margin: "0 auto",
        textAlign: "justify",
        lineHeight: "1.5",
      }}
    >
      <div>
        <header></header>
        <p style={{marginBottom:"1rem"}}>
          PLEASE NOTE THAT THE SECTION LABELED "CLASS-ACTION WAIVER AND
          AGREEMENT TO ARBITRATION" BELOW CONTAINS A BINDING ARBITRATION CLAUSE
          AND CLASS ACTION WAIVER. IT AFFECTS YOUR RIGHTS IN ANY DISPUTE WITH
          US, INCLUDING, WITHOUT LIMITATION, DISPUTES ARISING OUT OF YOUR USE OF
          THE SERVICES AND/OR YOUR PURCHASE AND/OR USE OF ANY SERVICE OR
          PRODUCT.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Welcome! We're glad you're here and thank you for selecting the
          services that we offer through our websites, mobile applications or by
          telephone (the "Services"). As the developer of the Services ("we,"
          "us" or "our"), we want to provide you with the terms governing your
          use of the Services set forth below (these "Terms"). By registering or
          otherwise accessing, downloading, installing, and/or using the
          Services, you are agreeing with all these Terms.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Registration
        </h2>

        <p style={{marginBottom:"1rem"}}>
          Our mobile apps allow you to register and set up a user account to use
          the Services. We make registration optional by way of a Guest Checkout
          option, if you prefer. By registering or otherwise accessing,
          downloading, installing, and/or using the Services, you represent and
          warrant that (a) you are at least eighteen (18) years of age and (b)
          you have provided us with true, accurate and current information about
          yourself during the registration or Guest Checkout process (including,
          without limitation, your name and valid email address). You
          acknowledge and agree that erroneous information may lead to an
          inability for you to complete your transaction. You represent and
          warrant that you will provide and maintain true, complete and current
          account information, and keep your email address and phone number
          updated as long as your account remains active. Registration data and
          other information that you provide are governed by our Privacy Policy.
          You may not access or use the Services in any manner if you are
          younger than eighteen (18) years old. By providing your contact
          information to us, you agree that we may contact you in connection
          with your account and/or orders.
        </p>

        <p style={{marginBottom:"1rem"}}>
          You are solely responsible for all activities that occur under your
          account and for ensuring that you exit or log out of your account at
          the end of each session of use. Your password is confidential, and you
          may not give it to anyone else. You will notify us immediately of any
          unauthorized use of your account or password or any other breach of
          security known or suspected by you.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Mobile Services
        </h2>

        <p style={{marginBottom:"1rem"}}>
          Certain of the Services are available via a mobile device, which may
          include, without limitation (a) the ability to upload content to the
          Services, (b) the ability to browse the Services and (c) the ability
          to access certain features through a downloaded and installed
          application (collectively, the "Mobile Services"). To the extent to
          which you access the Services through a mobile device, your wireless
          service carrier's standard charges, data rates and other fees may
          apply. In addition, downloading, installing or using certain Mobile
          Services may be prohibited or restricted by your carrier, and it may
          be the case that not all Mobile Services work with all carriers or
          devices. By using the Mobile Services, you acknowledge and agree that
          certain information about your usage of the Mobile Services may be
          communicated to us.
        </p>

        <p style={{marginBottom:"1rem"}}>Your Content</p>

        <p style={{marginBottom:"1rem"}}>
          We are in the business of offering personalized products (the
          "Products"). The Products and/or the use of the Services to order the
          Products sometimes require information that you provide, including,
          without limitation, text, photographs, images and/or information that
          identifies, relates to, describes, is capable of being associated
          with, or could reasonably be linked, directly or indirectly, with a
          particular person, household or electronic device (collectively, "Your
          Content"). The Services and the Products are for your personal use
          only. You may not reproduce, duplicate, copy, sell, resell or
          otherwise exploit any part of the Services or Products except as
          permitted by these Terms, and you may not access or use the Services
          for any commercial purpose whatsoever. Any use of the Services other
          than as expressly authorized in these Terms is strictly prohibited.
          All rights not expressly granted in these Terms are hereby expressly
          reserved by us.
        </p>

        <p style={{marginBottom:"1rem"}}>
          By submitting Your Content to us, you grant us a nonexclusive,
          worldwide, royalty free, fully paid-up, transferable, sublicensable,
          perpetual, irrevocable license to reproduce, distribute copies of,
          prepare derivative works based upon, publicly perform, publicly
          display and otherwise use and exploit Your Content for the purpose of
          providing the Services and promoting to you other services we believe
          will be of interest to you. You represent and warrant that you either
          own Your Content or have a written license or other valid permission
          from the applicable rights owner(s) (for example, and without
          limitation, your photographer) to make Your Content available to us
          for use with the Services and on the Products you order.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Without limiting the generality of the foregoing paragraph, you
          acknowledge and agree that we will need to work with Your Content in
          order to provide you with the Services and the Products. For example,
          and without limitation, we will copy, and we may need to display
          and/or modify, as well as stream, upload, post, publish, display,
          email or otherwise transmit (including transmission to other
          countries) or use (hereinafter, "Transmit"), Your Content when making
          the Products, and we will distribute Your Content when shipping the
          Products to you. By the license granted above, you are giving us
          permission to do this and, because we are using Your Content to
          provide you with the Services and the Products, you will not charge us
          any royalty. We will retain Your Content on our servers so that it
          will be available for your future use. This will make it easier for
          you to reprint entire orders or use elements of Your Content in making
          Products for new orders, all without the need to upload Your Content
          again. We agree that our use of Your Content will be limited to the
          scope of the Services and the Products that we provide. And rest
          assured that we will never use Your Content to market, promote or
          advertise the Services to others without your permission.
        </p>

        <p style={{marginBottom:"1rem"}}>
          We reserve the right to reject any or all Your Content if we
          determine, in our sole discretion, that Your Content is inappropriate
          or otherwise inconsistent with the exercise of good judgment. For
          example, and without limitation, we may reject any of Your Content
          that we believe would fall within the Prohibited Uses of the Services
          set forth below.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Our Intellectual Property
        </h2>

        <p style={{marginBottom:"1rem"}}>
          You acknowledge and agree that the Services contain works of
          authorship (for example, but without limitation, software),
          inventions, brands, and content) ("Our Intellectual Property") that is
          protected by copyright, patent, trademark, trade secret and/or other
          laws, regulations and rules and is owned by us and/or our licensors or
          affiliates.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Without limitation, unless otherwise designated, we own all Our
          Intellectual Property, including, without limitation, all the
          trademarks and logos used in connection with the Services ("Our
          Trademarks"). Nothing in these Terms or otherwise grants any license
          or right to use any of Our Trademarks. You may not delete, change or
          modify in any way the copyright, trademark or other intellectual
          property notices contained in the Services. All goodwill generated
          from the use of Our Trademarks will inure to our exclusive benefit.
        </p>

        <p style={{marginBottom:"1rem"}}>
          The use of prints, images or screen captures from the Services is
          limited to your personal, non-commercial use. You may not use any data
          mining, robots, scraping or similar data gathering or extraction
          methods in connection with your use of the Services. You will not
          reproduce, distribute copies of, prepare derivative works based upon,
          publicly perform, publicly display or otherwise use, exploit, reverse
          engineer, reverse assemble or otherwise attempt to discover any source
          code, or attempt to sell, assign, sublicense, or otherwise transfer
          any right in or to Our Intellectual Property.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Third-party Intellectual Property
        </h2>

        <p style={{marginBottom:"1rem"}}>
          Certain of the Services and the Products contain images, photographs,
          layouts, designs and other content that may consist of the copyrights,
          trademarks, service marks, trade names and other intellectual property
          of third parties (collectively, the "Third-party Content").
          Third-party Content is provided for your convenience for the specific
          purposes for which we have provided it. You may not use Third-party
          Content:
        </p>
        <br />
        <p style={{marginBottom:"1rem"}}>
          1. For any other purposes whatsoever without the prior express
          authorization of its owner;
        </p>

        <p style={{marginBottom:"1rem"}}>
          2. Except solely as incorporated into a Product, or otherwise download
          it in its original, unaltered form outside the Service;
        </p>

        <p style={{marginBottom:"1rem"}}>
          3. In any manner that would violate the Prohibited Uses of the
          Services section of these Terms; or
        </p>

        <p style={{marginBottom:"1rem"}}>4. As a trademark, service mark, or logo.</p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Prohibited Uses of the Services
        </h2>

        <p style={{marginBottom:"1rem"}}>
          You are solely responsible for Your Content that you Transmit via the
          Services. We reserve the right to investigate and take appropriate
          legal action against anyone who, in our sole discretion, violates
          these Terms, including, without limitation, removing any offending
          content from the Services, suspending or terminating any account of
          such violator(s) and reporting such violator(s) to appropriate law
          enforcement authorities. As a condition for accessing and using the
          Services, you agree not to use the Services to:
        </p>

        <p style={{marginBottom:"1rem"}}>
          1. Transmit any content that (a) is unlawful, threatening, abusive,
          harassing, tortious, violent, defamatory, libelous, slanderous,
          vulgar, obscene, pornographic (involving minors or otherwise), hateful
          or abusive, or otherwise similarly objectionable, (b) portrays any
          person depicted therein in a manner that a reasonable person would
          find offensive or portrays any person [1] in connection with
          pornography, "adult videos," adult entertainment venues, escort
          services, dating services, or the like, [2] in connection with the
          advertisement or promotion of tobacco products, [3] as suffering from,
          or medicating for, a physical or mental ailment, or [4] engaging in
          immoral or criminal activities, (c) poses or creates a privacy or
          security risk to any person, (d) constitutes unsolicited or
          unauthorized advertising, promotional materials, junk mail, spam,
          chain letters, pyramid schemes, contests, sweepstakes or any other
          form of solicitation, (e) characterizes any unlawful or immoral
          activity as acceptable, glamorous or desirable, (f) glamorizes the use
          or "hard-core" illegal substances or drugs, (g) denigrates or offends
          any ethnic, racial, sexual or religious group, or persons who are
          physically or mentally challenged, (h) makes inappropriate use of
          swastikas or other symbols of racism or intolerance and/or glamorizes
          the actions of Hitler or other individuals or groups advocating ethnic
          cleansing, genocide, the erasure or destruction of a country or its
          government, civilization or ethnic group, or similar activities, (i)
          uses messages, marks or symbols that support cults or conspiracy
          theories that have been disproven or that are rooted, in whole or in
          part, in intolerance or (j) contains images or likeness of minors
          without the valid authority to do so;
        </p>

        <p style={{marginBottom:"1rem"}}>
          2. Harass another person by any means including, without limitation,
          by using their email account, address or password, or falsely state or
          otherwise misrepresent your affiliation with any person or entity;
        </p>

        <p style={{marginBottom:"1rem"}}>
          3. Violate any applicable local, state, national or international law,
          regulation or rule;
        </p>

        <p style={{marginBottom:"1rem"}}>
          4. Transmit or make available any content that you do not have the
          lawful right to Transmit, that would infringe the intellectual or
          proprietary rights of any third party (including, without limitation,
          copyright, trade secret, trademark, service mark or patent rights), or
          that would violate any person's right of privacy or publicity;
        </p>

        <p style={{marginBottom:"1rem"}}>
          5. Engage in any conduct that would interrupt, destroy, limit or harm
          the Services or enable you to gain unauthorized access to the
          Services, including, without limitation, by using viruses, Trojan
          horses, worms or malicious computer code, programs or files;
        </p>

        <p style={{marginBottom:"1rem"}}>
          6. Reproduce, copy, sell, or commercially use (including, without
          limitation, the right to access) the Services;
        </p>

        <p style={{marginBottom:"1rem"}}>
          7. Solicit personal information from anyone under the age of eighteen
          (18);
        </p>

        <p style={{marginBottom:"1rem"}}>
          8. Harvest or collect email addresses or other contact information of
          other users from the Services by electronic or other means; or
        </p>

        <p style={{marginBottom:"1rem"}}>
          9. Further or promote any criminal activity or provide instructional
          information about illegal activities.
        </p>

        <p style={{marginBottom:"1rem"}}>
          In case of any violation of the above, and without limitation, we
          reserve the right to terminate your account and/or block you from
          accessing the Services and disclose any information if required to do
          so by law or in the good-faith belief that such preservation or
          disclosure is reasonably necessary to (a) comply with legal process,
          applicable laws or government requests, (b) enforce these Terms, (c)
          respond to claims that any content violates the rights of third
          parties or (d) protect the rights, property, or personal safety of us,
          our users or the public.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Information You Provide
        </h2>

        <p style={{marginBottom:"1rem"}}>
          Any questions, comments, suggestions, ideas, feedback or other
          information about the Services ("Submissions") provided by you to us
          are non-confidential, and we will be entitled to the unrestricted use
          and distribution of Submissions for any purpose, without
          acknowledgment or compensation to you.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Practices Regarding Use and Storage of Your Content
        </h2>

        <p style={{marginBottom:"1rem"}}>
          We may preserve Your Content and may also delete or disclose Your
          Content if required to do so by law or in the good-faith belief that
          such preservation or disclosure is reasonably necessary to (a) comply
          with legal process, applicable laws or government requests, (b)
          enforce these Terms, (c) respond to claims that any content violates
          the rights of third parties or (d) protect the rights, property, or
          personal safety of us, our users or the public.
        </p>

        <p style={{marginBottom:"1rem"}}>
          We may establish practices and limits concerning use of the Services,
          including, without limitation, the maximum period of time that data or
          other content is retained by the Services and the maximum storage
          space that will be allotted on our servers on your behalf. We will
          have no responsibility or liability for the deletion or failure to
          store any data or other content maintained or Transmitted by or to the
          Services. You acknowledge and agree that we reserve the right to
          terminate accounts that are inactive for an extended period of time,
          such time to be determined in our sole discretion. You further
          acknowledge and agree that we reserve the right to change these
          general practices and limits at any time, in our sole discretion, with
          or without notice.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Rewards
        </h2>

        <p style={{marginBottom:"1rem"}}>
          From time to time, we may make available to you certain offers and/or
          functionality that we call "Rewards." There are no membership fees
          associated with Rewards. Rewards are strictly promotional and have no
          cash value. In order to unlock an available Reward, you must select
          the Reward via the Rewards screen and complete the required action.
          For example, and without limitation, we may offer some number of free
          products or services to you if you complete a certain number of
          transactions in a limited period of time. Once unlocked, you may
          redeem or activate the Reward as long as it is available via your
          account. Each Reward is limited to one (1) per customer unless we
          expressly indicate otherwise. We reserve the right to change, modify
          and/or eliminate any and all Rewards, regardless of status (including,
          without limitation, Rewards that are available, unlocked or redeemed)
          at any time and in our sole discretion. Any changes, modifications or
          eliminations will be effective immediately upon release of an
          applicable update to the Services, and you waive any right you may
          have to receive specific and/or prior notice of any such changes,
          modifications or eliminations. If you take any action that results in
          the unlocking and/or redemption of any Reward and such action is
          deemed incomplete or voided for any reason whatsoever, we may deduct,
          remove or eliminate the applicable Reward in our sole discretion. Your
          Rewards are personal to you and may not be sold, transferred or
          assigned to, or shared with others or used by you for any commercial
          purpose.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Promotional Offers
        </h2>

        <p style={{marginBottom:"1rem"}}>
          From time to time, we may make available to you certain promotional
          offers.
        </p>
        <p style={{marginBottom:"1rem"}}>
            Free Shipping Offers are subject to the following terms and
            conditions:
          </p>
        <ul style={{marginBottom:"1rem"}}>
          

          <li>
            They apply only to orders shipped to a single address in a
            geographic region into which we regularly ship.
          </li>

          <li>
            They are limited to one discount per order and per customer, and
            cannot be combined with other discounts, offers or promotions.
          </li>

          <li>They have no cash value, and cannot be resold.</li>

          <li>
            If they have a minimum order requirement, the order value before tax
            must meet the minimum order threshold for the offer to be activated.
          </li>

          <li>
            They do not apply to (a) any previous order(s), (b) express,
            priority, or overnight delivery, (c) taxes, (d) oversized items, (e)
            specially marked products, or (f) bulk or corporate purchases of ten
            (10) units or more.
          </li>

          <li>
            Except where required by law, they cannot be redeemed for cash,
            check, or credit.
          </li>

          <li>
            They do not affect normal retail prices (which are subject to
            change).
          </li>
        </ul>

        <p style={{marginBottom:"1rem"}}>
          Dollars Off Offers (and similarly-themed offers in places with other
          currencies) are subject to the following terms and conditions:
        </p>
        <ul style={{marginBottom:"1rem"}}>
          <li>
            They are limited to one discount per order and per customer, and
            cannot be combined with other discounts, offers or promotions.
          </li>

          <li>They have no cash value, and cannot be resold.</li>

          <li>
            If they have a minimum order requirement, the order value before tax
            must meet the minimum order threshold for the offer to be activated.
          </li>

          <li>
            They do not apply to (a) any previous order(s), (b) shipping, care,
            handling or taxes, (c) specially-marked products; or (d) bulk or
            corporate purchases of ten (10) units or more.
          </li>

          <li>
            Except where required by law, they cannot be redeemed for cash,
            check, or credit.
          </li>

          <li>
            They do not affect normal retail prices (which are subject to
            change).
          </li>

          <li>
            Percentage Off Offers are subject to the following terms and
            conditions:
          </li>

          <li>
            They are limited to one discount per order and per customer, and
            cannot be combined with other discounts, offers or promotions.
          </li>

          <li>They have no cash value, and cannot be resold.</li>

          <li>
            If they have a minimum order requirement, the order value before tax
            must meet the minimum order threshold for the offer to be activated.
          </li>

          <li>
            They do not apply to (a) any previous order(s), (b) shipping, care,
            handling or taxes, (c) specially-marked products, or (d) bulk or
            corporate purchases of ten (10) units or more.
          </li>

          <li>
            Except where required by law, they cannot be redeemed for cash,
            check, or credit.
          </li>

          <li>
            They do not affect normal retail prices (which are subject to
            change).
          </li>
        </ul>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Gift Cards
        </h2>

        <p style={{marginBottom:"1rem"}}>
          You may from time to time have the ability to purchase, receive, send,
          receive and/or activate through us digital gift cards (each, a "Gift
          Card").
        </p>

        <p style={{marginBottom:"1rem"}}>
          We will send each Gift Card to the recipient for which the purchaser
          thereof has provided us with the recipient information that we
          require. The recipient may be the purchaser themselves or a third
          party. Gift Cards are redeemable only through the merchant that is
          selected by the Gift Card purchaser in the purchase process (the
          "Merchant"), and the Gift Card balance is available only through that
          Merchant. The Gift Card balance is not available through us. We will
          provide each recipient of a Gift Card with an activation code to
          activate the Gift Card through us, so that it may then be redeemed
          with the Merchant. That activation code does not expire or have fees.
          The Merchant's terms and conditions also apply to both activation and
          redemption. We reserve the right to discontinue our gift cards product
          line or the gift cards of any individual Merchant(s), including,
          without limitation, before a recipient has either activated with us or
          redeemed with the Merchant an already-purchased Gift Card. If a Gift
          Card is discontinued after purchase by the Gift Card purchaser but
          before activation with us by the Gift Card recipient, we will replace
          the Gift Card with our choice of replacement Gift Card(s) from our
          choice of third party(ies), at equivalent value. We may provide the
          Gift Card purchaser information about their Gift Card recipient's Gift
          Card activation status.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Until such time as we deposit a Gift Card with a carrier (e.g., U.S.
          Postal Service or Royal Mail) for delivery to the Gift Card recipient,
          the risk of loss of, and title to, that Gift Card belongs to us. The
          risk of loss of, and title to, that Gift Card then passes to the Gift
          Card recipient when we deposit it with a carrier for delivery to such
          recipient. The Gift Card recipient is responsible for safeguarding the
          Gift Card from unauthorized use. We are not responsible if any
          delivered Gift Card is lost, stolen, or destroyed, or if a Gift Card
          is used without permission. There are a variety of scams that request
          payment by gift card. We are not responsible, and assume no liability
          to you, for any unlawful conduct or fraud by any third party
          associated with any Gift Card.
        </p>

        <p style={{marginBottom:"1rem"}}>
          By using a Gift Card, you agree to comply with these Terms and to not
          use a Gift Card in any manner that is misleading, deceptive, unfair,
          or otherwise harmful to us, our affiliates, or our customers. We
          reserve the right, without notice to you, to void a Gift Card without
          a refund, suspend or terminate customer accounts, suspend or terminate
          the ability to use our application(s) and/or website(s), cancel or
          limit orders, and bill alternative forms of payment if we suspect that
          a Gift Card is obtained, used or applied fraudulently, unlawfully, or
          otherwise in violation of these Terms.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Third-party Sites, Products &amp; Services
        </h2>

        <p style={{marginBottom:"1rem"}}>
          You may enable various online services, such as social networking
          sites, to be directly integrated into your experience with the
          Services. In addition, from time to time the Services may include
          links to third-party sites, services and/or products that we think may
          be of interest to you. To take advantage of these features, we may ask
          you to register for, or log into, the services of their respective
          providers. By enabling third-party services within the Services, you
          are allowing and authorizing us (a) to pass your log-in information to
          these service providers for this purpose and (b) to receive
          information and content from such third-party services. Please
          remember that the manner in which third-party services use, store and
          disclose your information is governed solely by the policies of such
          third parties, and we will have no liability or responsibility for the
          privacy practices or other actions of any third-party site or service
          that may be enabled within the Services. We make no representations or
          warranties concerning such third-party sites, services or products,
          and, accordingly, we are not liable for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on
          any such third-party sites, services and/or products.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Cookies
        </h2>

        <p style={{marginBottom:"1rem"}}>
          Our websites use "cookies." Cookies are small text files that reside
          on your device and identify you as a unique user. Cookies allow us to,
          among other things, measure activity and personalize your experience.
          For example, and without limitation, cookies enable us to remember
          your viewing preferences without requiring you to re-type a username
          or password. Cookies also allow us to track your status or progress
          when ordering Products from us. If you choose, you can set your device
          to reject cookies, or you can manually delete individual or all
          cookies on your device. However, if you reject or delete cookies, you
          may have some trouble accessing and using some of the pages and
          features on our websites. We also use other common
          information-gathering tools such as web beacons and embedded web
          links.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Payments
        </h2>

        <p style={{marginBottom:"1rem"}}>
          To the extent to which the Services and/or the Products or any portion
          thereof are made available for any fee or charge (including, without
          limitation, shipping and handling charges), you will be required to
          select a form of payment and provide us information regarding your
          credit card or other form of payment authorized by us. You represent
          and warrant that such information is true and correct and that you are
          authorized to use the selected form of payment. When you make a
          payment, you authorize us (and our designated payment processor(s)) to
          charge the full amount to the payment source you designate for the
          transaction. You will promptly update your account information with
          any changes (for example, and without limitation, a change in your
          billing address or credit card expiration date) that may occur. You
          will pay us all fees and charges incurred in accordance with the
          authorized form of payment and these Terms. If you dispute any fees or
          charges, you will let us know within sixty (60) days after the date
          that we invoice or otherwise charge you, and give us the opportunity
          to remediate any problem which you believe entitles you to dispute
          those fees or charges. You hereby grant to us the right to fix any
          payment processing errors that we may discover, and the right to
          correct any such processing errors by debiting or crediting the
          payment method used for the transaction found to be in error.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Returns &amp; Refunds
        </h2>

        <p style={{marginBottom:"1rem"}}>
          We want you to be 100% satisfied with your experience with the
          Services and the Products. If within the first thirty (30) days you
          are not completely satisfied with your order, we will either (a)
          provide a replacement in the case of a defect or (b) in all other
          cases, issue a complete refund, subject to the exceptions and
          restrictions listed below:
        </p>

        <p style={{marginBottom:"1rem"}}>
          1. Refunds are limited to amounts actually paid by you. This does not
          include the promotional value of any special offer or discount.
        </p>

        <p style={{marginBottom:"1rem"}}>
          2. In cases where the return/refund is due to your order arriving
          damaged or with a defect confirmed by us, we will in our sole
          discretion either (a) provide you with a prepaid return shipping
          label, (b) reimburse your cost of return shipping, or (c) direct you
          to discard the damaged or defective product. For all other cases,
          return shipping costs are your responsibility. Shipping and handling
          charges may apply to Services and Products that are otherwise offered
          on a free or promotional basis.
        </p>

        <p style={{marginBottom:"1rem"}}>
          3. All orders receiving bulk/wholesale pricing are final, and no
          refund will be given except in the case of material damage or defect
          upon your order's arrival. If the damage or defect cannot be verified
          over the phone or via email contact, you may need to return the item
          to us for inspection before a determination can be made as to a
          potential refund. Please be sure to request and carefully review
          digital proofs when placing a bulk/wholesale order.
        </p>

        <p style={{marginBottom:"1rem"}}>
          In addition to the above satisfaction guarantee, we also offer a
          limited lifetime warranty against defective workmanship on all our
          canvas wall décor Products, if such defective workmanship causes a
          canvas wall décor Product to be unusable for its intended purpose in a
          way that did not exist in the first thirty (30) days after you
          received it. To make such a warranty claim, please contact us, either
          via our website, via the self-service tools that may be provided in
          the app tray or drawer if using one of our mobile applications, or by
          telephone if you are using one of the Services that provides for
          telephone support.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Disclaimer of Warranties
        </h2>

        <p style={{marginBottom:"1rem"}}>
          YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES AND THE PRODUCTS ARE
          PROVIDED "AS IS, AS AVAILABLE," WITHOUT ANY WARRANTY OF ANY KIND OTHER
          THAN AS SET FORTH IN THE RETURNS AND REFUNDS SECTION OF THESE TERMS.
          WE HEREBY DISCLAIM ALL OTHER WARRANTIES (EXPRESS, IMPLIED, STATUTORY
          OR OTHERWISE), INCLUDING, WITHOUT LIMITATION, ALL WARRANTIES (A) WITH
          RESPECT TO THE SERVICES AND THE PRODUCTS (INCLUDING, WITHOUT
          LIMITATION, THEIR QUALITY, AVAILABILITY, PERFORMANCE AND
          FUNCTIONALITY), (B) WITH RESPECT TO THE QUALITY OR ACCURACY OF ANY
          INFORMATION OBTAINED FROM OR AVAILABLE THROUGH USE OF THE SERVICES,
          (C) THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS OR OPERATE ON OR WITH ANY
          PARTICULAR HARDWARE, PLATFORM OR SOFTWARE, (D) OF NON-INFRINGEMENT,
          (E) OF MERCHANTABILITY, AND (F) OF FITNESS FOR A PARTICULAR PURPOSE.
          NO ADVICE OR INFORMATION GIVEN BY US OR OUR REPRESENTATIVES WILL BE
          DEEMED TO CREATE A WARRANTY.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Limitation of Liability – General
        </h2>

        <p style={{marginBottom:"1rem"}}>
          WE WILL NOT BE LIABLE FOR NONPERFORMANCE OR DELAY IN PERFORMANCE
          CAUSED BY ANY REASON, WHETHER WITHIN OR OUTSIDE OF OUR CONTROL. IN NO
          EVENT WILL WE BE LIABLE UNDER CONTRACT, NEGLIGENCE, TORT, STRICT
          LIABILITY OR ANY OTHER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT,
          INCIDENTAL, EXEMPLARY, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES
          (INCLUDING, WITHOUT LIMITATION, LOSS OF USE, LOST PROFITS, LOSS OF
          DATA OR INFORMATION, OR LOSS OF BUSINESS GOODWILL OR OPPORTUNITY)
          WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR
          DAMAGES. WE WILL NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER RESULTING
          FROM (A) THE USE OF OR THE INABILITY TO USE THE SERVICES, (B) THE COST
          OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES, (C) UNAUTHORIZED
          ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA (D) STATEMENTS
          OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES OR (E) ANY PERSONAL
          INJURY OR PROPERTY DAMAGE THAT MAY RESULT FROM THE USE OF ANY OF THE
          PRODUCTS. IN NO EVENT WILL OUR LIABILITY TO YOU EXCEED THE GREATER OF
          FIFTY U.S. DOLLARS ($50.00) OR ANY AMOUNTS ACTUALLY PAID BY YOU TO US
          FOR THE SPECIFIC SERVICES OR PRODUCTS AT ISSUE DURING THE SIX (6)
          MONTHS PRIOR TO THE DATE ON WHICH THE BASIS FOR THE DISPUTE HAS
          OCCURRED. NO ACTION, REGARDLESS OF FORM, ARISING OUT OF THESE TERMS OR
          OUT OF THE SERVICES AND/OR THE PRODUCTS MAY BE BROUGHT BY YOU MORE
          THAN ONE (1) YEAR AFTER THE EVENT WHICH GAVE RISE TO THE CAUSE OF
          ACTION. SOME JURISDICTIONS DO NOT ALLOW A LIMITATION ON LIABILITY
          DAMAGES AS CONTAINED IN THESE TERMS AND, IN SUCH JURISDICTIONS, OUR
          LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Limitation of Liability – Film, Prints and Negatives
        </h2>

        <p style={{marginBottom:"1rem"}}>
          WE STRONGLY SUGGEST THAT YOU NOT PROVIDE US WITH ORIGINAL DIGITAL
          FILES, FILM, PRINTS OR NEGATIVES WITHOUT RETAINING A COPY. IF YOUR
          DIGITAL FILES, FILM, PRINTS OR NEGATIVES ARE DAMAGED, LOST OR
          OTHERWISE NOT RETURNED, OUR SOLE LIABILITY AND YOUR EXCLUSIVE REMEDY
          WILL BE AS SET FORTH IN THE PARAGRAPH IMMEDIATELY ABOVE.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Limitation of Liability – Data and Usage Restrictions
        </h2>

        <p style={{marginBottom:"1rem"}}>
          WE WILL NOT BE LIABLE IN ANY MANNER FOR ANY DATA OR USAGE LIMITS, CAPS
          OR RESTRICTIONS, OR ANY RATES OR CHARGES, APPLIED TO OR ASSESSED ON
          YOUR MOBILE DEVICE BY ANY THIRD PARTY THAT RESULT FROM YOUR USE OF THE
          SERVICES OR OTHERWISE. WE SUGGEST THAT, WHEN AVAILABLE, YOU USE YOUR
          MOBILE DEVICE WITH AN UNMETERED WI-FI CONNECTION IN ORDER TO MINIMIZE
          THE LIKELIHOOD OF ANY OF THE FOREGOING OCCURRING TO YOU.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Limitation of Liability – Photo Tiles Products
        </h2>

        <p style={{marginBottom:"1rem"}}>
          WE STRONGLY SUGGEST THAT YOU FOLLOW THE INSTRUCTIONS AND/OR WARNINGS
          PROVIDED WITH OUR PRODUCTS, IF APPLICABLE, WHEN YOU RECEIVE THEM. IN
          PARTICULAR, BUT WITHOUT LIMITATION, WE WILL NOT BE LIABLE IN ANY
          MANNER FOR ANY PERSONAL INJURY OR PROPERTY DAMAGE CAUSED BY A PHOTO
          TILE SHOULD IT FALL OFF YOUR WALL, REGARDLESS OF WHETHER THOSE
          INSTRUCTIONS/WARNINGS WERE FOLLOWED.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Copyright and Other Intellectual Property Rights
        </h2>

        <p style={{marginBottom:"1rem"}}>
          We respect the intellectual property rights of others and ask you to
          do the same. In accordance with the Digital Millennium Copyright Act
          (DMCA), if you believe that your work has been used in a way that
          constitutes copyright infringement, or your intellectual property
          rights have been otherwise violated, please provide us with the
          following information, using the contact form provided on our website
          or in the app tray or drawer of the mobile application you are using,
          if available, as the case may be. You may also contact us by mail at
          2831 Saint Rose Parkway, Henderson, NV 89052 USA.
        </p>

        <ul style={{marginBottom:"1rem"}}>
          <li>
            a description of the copyrighted work or other intellectual property
            that you claim has been infringed;
          </li>

          <li>
            a description of where the material that you claim is infringing is
            located on the site, along with links to the material at issue;
          </li>

          <li>
            a statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright or other
            intellectual property owner, its agent, or the law;
          </li>

          <li>
            a statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright or intellectual property owner or authorized to act on the
            copyright or intellectual property owner's behalf;
          </li>

          <li>your name, address, telephone number, and email address; and</li>

          <li>your electronic or physical signature.</li>
        </ul>

        <p style={{marginBottom:"1rem"}}>
          Counter-Notice: If you believe that the content that was removed (or
          to which access has been disabled) is not infringing, or that the
          disputed use is authorized by the copyright or other intellectual
          property owner, its agent, or the law, you may send a written
          counter-notice containing the following information to us:
        </p>
        <ul style={{marginBottom:"1rem"}}>
          {" "}
          <li>
            a description of the copyrighted work or other intellectual property
            that has been removed or to which access has been disabled;
          </li>
          <li>
            a description of where such material was located on the site before
            it was removed or disabled, along with URLs that led to the material
            at issue if you have them;
          </li>
          <li>
            a statement by you that you have a good faith belief that the
            content was authorized by the copyright or other intellectual
            property owner, its agent, or the law and was removed or disabled as
            a result of mistake or misidentification;
          </li>
          <li>
            a statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright or intellectual property owner or authorized to act on the
            copyright or intellectual property owner's behalf;
          </li>
          <li>
            your name, address, telephone number, and email address, a statement
            that you consent to the jurisdiction of the federal court located
            within the Central District of California and a statement that you
            will accept service of process from the person who provided
            notification of the alleged infringement or an agent of such person;
            and
          </li>
          <li>your electronic or physical signature.</li>
        </ul>

        <p style={{marginBottom:"1rem"}}>
          If a counter-notice is received by us, we will send a copy of the
          counter-notice to the original complaining party/copyright owner. In
          accordance with applicable law, we may replace or restore access to
          the removed content unless the original complaining party/copyright
          owner notifies us that it is seeking a court order against the alleged
          infringing party to prevent further infringement of the content at
          issue.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Repeat Infringer Policy: In accordance with the DMCA and other
          applicable law, we have adopted a policy of terminating, in
          appropriate circumstances and in our sole discretion, the accounts of
          users who are deemed to be repeat infringers.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Termination, Deactivation and Suspension
        </h2>

        <p style={{marginBottom:"1rem"}}>
          You or we may terminate your account at any time. If you violate these
          Terms, we also will have the right to deactivate or limit your access
          to the Services. You agree that any termination, limitation and/or
          deactivation may be effected by us without prior notice, and that we
          may immediately delete all related information, files and content in
          your account. We will not be liable in any manner for such
          termination, deactivation, limitation or deletion.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Special Notice for International Use; Export Controls
        </h2>

        <p style={{marginBottom:"1rem"}}>
          All software available in connection with the Services and the
          transmission of applicable data, if any, is subject to export controls
          established by law. No software may be downloaded from the Services or
          otherwise exported or re-exported in violation of export laws.
          Downloading or using such software is at your sole risk, and you must
          comply with all international and domestic law, regulations and rules
          regarding your use of the Services.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Indemnification
        </h2>

        <p style={{marginBottom:"1rem"}}>
          You hereby release, indemnify and hold us and our parents,
          subsidiaries, affiliates, officers, shareholders, members, directors,
          managers, employees, attorneys, representatives and agents, harmless
          from and against all claims, costs, damages, losses, liabilities, and
          expenses (including, without limitation, attorneys' fees and costs),
          actions and damages of all kinds based on, arising out of or in
          connection with your use of the Services and/or the Products, your
          breach of these Terms and/or your use of any third-party site, service
          and/or product. If you are a California resident, you hereby waive
          California Civil Code Section 1542, which states that: "A general
          release does not extend to claims that the creditor or releasing party
          does not know or suspect to exist in his or her favor at the time of
          executing the release, and that if known by him or her would have
          materially affected his or her settlement with the debtor or released
          party." If you are a resident of another jurisdiction, you hereby
          waive any comparable statute or doctrine.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Privacy Policy
        </h2>

        <p style={{marginBottom:"1rem"}}>
          The terms of our Privacy Policy are hereby incorporated by reference
          into these Terms.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Class-Action Waiver and Agreement to Arbitration
        </h2>

        <p style={{marginBottom:"1rem"}}>
          PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS RIGHTS THAT YOU MAY
          OTHERWISE HAVE. IT PROVIDES FOR RESOLUTION OF DISPUTES THAT YOU AND WE
          MAY HAVE WITH EACH OTHER BY USING INDIVIDUAL ARBITRATION, WHICH IS
          FINAL AND BINDING. IN INDIVIDUAL ARBITRATION, THERE IS NO JUDGE OR
          JURY AND THERE IS LESS DISCOVERY AND POTENTIAL FOR APPELLATE REVIEW
          THAN IN COURT. THIS ARBITRATION CLAUSE WILL SURVIVE TERMINATION OF
          THESE TERMS.
        </p>

        <p style={{marginBottom:"1rem"}}>
          ANY DISPUTE OR CLAIM MADE BY YOU AGAINST US ARISING OUT OF OR RELATING
          TO THESE TERMS, THE SERVICE AND/OR YOUR PURCHASE AND/OR USE OF THE
          PRODUCT(S), REGARDLESS OF WHETHER SUCH DISPUTE OR CLAIM IS BASED IN
          CONTRACT, TORT, PRODUCTS LIABILITY, STATUTE, FRAUD, MISREPRESENTATION,
          OR ANY OTHER LEGAL THEORY (TOGETHER, A "DISPUTE") WILL BE RESOLVED BY
          BINDING INDIVIDUAL ARBITRATION, AS DESCRIBED BELOW.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Either you or we will have the right to elect to initiate binding
          arbitration to resolve any Dispute by providing the other party with
          written notice of such election. You and we each hereby agree, and
          agree in the further to take all steps required, to waive the right to
          litigate any Dispute in court, be it by way of court trial, jury trial
          or class action, and agree that: (1) such arbitration will be governed
          by the Consumer Arbitration Rules of the American Arbitration
          Association (the "Rules"), which are available{" "}
          <a href="https://www.adr.org/sites/default/files/Consumer_Rules_Web_0.pdf">
            here
          </a>{" "}
          or by calling +1-800-778-7879; (2) the arbitration will be conducted
          by one arbitrator appointed in accordance with the Rules; (3) the
          language of the arbitration will be English; (4) the arbitration will
          be conducted near the location where you reside; (5) we each hereby
          irrevocably consent and submit to exclusive personal jurisdiction and
          venue as such for the purposes of arbitrating any such action; (6) the
          arbitrator in such arbitration will be without jurisdiction to conduct
          a class arbitration or other representative proceeding, and may not
          consolidate one person's claims with another; (7) all issues of
          enforceability of this arbitration provision, including, without
          limitation, issues relating to scope, validity, and unconscionability,
          will be decided by the arbitrator; (8) the entirety of any arbitration
          will be confidential, and neither you nor we will have any right to
          disclose the existence, content, or results of any arbitration, except
          as may be required by law or for purposes of enforcement of the
          arbitration award; (9) payment of all arbitrator compensation,
          expenses, and administrative fees (which include, without limitation,
          filing and hearing fees) will be governed by the Rules; (10) each of
          us will bear our own fees and costs related to any arbitration,
          including, without limitation, the expense of our respective counsel,
          experts, witnesses, and preparation and presentation of evidence at
          the arbitration; and (11) notwithstanding (9) and (10) above, the
          arbitrator will have the right to re-allocate their compensation,
          expenses and/or administrative fees, as well as your and our fees and
          costs related to the arbitration, if they determine that a claim,
          defense and/or counterclaim was filed for purposes of harassment or is
          patently frivolous.
        </p>

        <p style={{marginBottom:"1rem"}}>
          WHETHER IN INDIVIDUAL ARBITRATION OR COURT, YOU AND WE EACH WAIVE THE
          RIGHT TO PROSECUTE OR PARTICIPATE IN A CLASS ACTION, COLLECTIVE
          ACTION, CONSOLIDATED ACTION, OR OTHER REPRESENTATIVE ACTION. YOU AND
          WE MAY EACH SEEK RELIEF ONLY ON OUR OWN BEHALVES, AND ONLY TO THE
          EXTENT NECESSARY TO REMEDY INDIVIDUAL CLAIMS. THIS CLASS ACTION WAIVER
          IS A MATERIAL AND ESSENTIAL PART OF THIS ARBITRATION PROVISION.
        </p>

        <p style={{marginBottom:"1rem"}}>
          If this arbitration provision is found to be invalid, illegal or
          unenforceable, a modified provision will be substituted that carries
          out as nearly as possible your and our original intent as evidenced
          solely by the language above, and the validity, legality and
          enforceability of any of the remaining provisions of these Terms will
          not in any way be affected or impaired thereby. If for any reason this
          arbitration provision is deemed inapplicable, invalid, illegal or
          unenforceable, you and we each hereby waive, to the fullest extent
          allowed by law, any right to a jury trial, any right to recover
          punitive or exemplary damages, and any right to pursue any claims on a
          class, collective, or consolidated basis or in a representative
          capacity.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Judgment on any arbitration award may be entered in any court having
          proper jurisdiction.
        </p>

        <h2
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.5rem",
            marginTop: "0.5rem",
          }}
        >
          Miscellaneous
        </h2>

        <p style={{marginBottom:"1rem"}}>
          We reserve the right to change these Terms at any time, effective
          immediately upon posting here. Any updates, new services or
          modifications of existing services will be governed by these Terms.
          The continued use of the Services following the posting of changes to
          these Terms constitutes your acceptance to such changes. We strongly
          encourage you to periodically review these Terms. All prices and
          features of the Services and the Products are subject to change
          without notice.
        </p>

        <p style={{marginBottom:"1rem"}}>
          We reserve the right to modify, discontinue or suspend, temporarily or
          permanently, any of the Services (or any part thereof) and/or the
          Products, with or without notice. You agree that we will not be liable
          to you or to any third party for any modification, discontinuance or
          suspension of any of the Services or the Products.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Any communication we receive from you will be considered NOT to be
          confidential (other than information we may agree to keep confidential
          under our Privacy Policy). By sending us any information (other than
          information we may agree to keep confidential under our Privacy
          Policy) you grant us a nonexclusive, worldwide, royalty free, fully
          paid up, transferable, sublicensable, perpetual, irrevocable license
          to reproduce, distribute copies of, prepare derivative works based
          upon, publicly perform, publicly display and otherwise use and exploit
          that information.
        </p>

        <p style={{marginBottom:"1rem"}}>
          These Terms constitute the entire agreement between you and us
          governing your use of the Services and the Products. If there is any
          conflict or inconsistency between these Terms or any other terms or
          conditions available elsewhere regarding the Services and/or the
          Products, these Terms will govern and be given precedence.
        </p>

        <p style={{marginBottom:"1rem"}}>
          The waiver of any right under these Terms will not operate as past,
          present or future waiver of that right or of any other right. No
          waiver will be effective in any case unless acknowledged and agreed to
          by us in writing.
        </p>

        <p style={{marginBottom:"1rem"}}>
          If any provision of these Terms is found to be invalid, illegal or
          unenforceable, a modified provision will be substituted that carries
          out as nearly as possible your and our original intent as evidenced
          solely by the language of these Terms, and the validity, legality and
          enforceability of any of the remaining provisions of these Terms will
          not in any way be affected or impaired thereby. No joint venture,
          partnership, employment, or agency relationship exists between you and
          us as a result of these Terms or the use of the Services. We have the
          right to assign any or all our rights and obligations under these
          Terms at any time; however, all rights that you may have under these
          Terms or otherwise in and to the Services may not be assigned by you.
        </p>

        <p style={{marginBottom:"1rem"}}>
          Nothing contained in these Terms will be deemed to create, or be
          construed as creating, any third-party beneficiary right of action
          upon any third party, in any manner whatsoever.
        </p>

        <p style={{marginBottom:"1rem"}}>
          The Services are offered by us, and we are located at 2831 Saint Rose
          Parkway, Henderson, NV 89052, USA. If you are a California resident,
          you may have a copy of these Terms emailed to you by sending a letter
          to the foregoing address with your email address and a request for
          such Terms.
        </p>
      </div>
    </div>
  );
};

export default Terms;
