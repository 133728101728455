import React from "react";
import { Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import "../styles/Stats.css";

const Stats = () => {
  const ages = [
    { name: "John Adams", age: 40 },
    { name: "Samuel Adams", age: 53 },
    { name: "Josiah Bartlett", age: 45 },
    { name: "Carter Braxton", age: 40 },
    { name: "Charles Carroll", age: 38 },
    { name: "Samuel Chase", age: 39 },
    { name: "Abraham Clark", age: 50 },
    { name: "George Clymer", age: 42 },
    { name: "William Ellery", age: 32 },
    { name: "William Floyd", age: 42 },
    { name: "Benjamin Franklin", age: 70 },
    { name: "Elbridge Gerry", age: 32 },
    { name: "Button Gwinnett", age: 42 },
    { name: "Lyman Hall", age: 44 },
    { name: "John Hancock", age: 39 },
    { name: "Benjamin Harrison", age: 34 },
    { name: "John Hart", age: 65 },
    { name: "Joseph Hewes", age: 48 },
    { name: "Thomas Heyward Jr.", age: 30 },
    { name: "William Hooper", age: 34 },
    { name: "Stephen Hopkins", age: 69 },
    { name: "Francis Hopkinson", age: 39 },
    { name: "Samuel Huntington", age: 46 },
    { name: "Thomas Jefferson", age: 33 },
    { name: "Francis Lewis", age: 63 },
    { name: "Philip Livingston", age: 62 },
    { name: "Richard Henry Lee", age: 32 },
    { name: "Francis Lightfoot Lee", age: 51 },
    { name: "Benjamin Rush", age: 30 },
    { name: "Robert Morris", age: 42 },
    { name: "John Morton", age: 51 },
    { name: "Thomas Nelson Jr.", age: 36 },
    { name: "William Paca", age: 35 },
    { name: "Robert Treat Paine", age: 45 },
    { name: "John Penn", age: 46 },
    { name: "George Read", age: 42 },
    { name: "Caesar Rodney", age: 47 },
    { name: "George Ross", age: 47 },
    { name: "Benjamin Rush", age: 30 },
    { name: "Edward Rutledge", age: 26 },
    { name: "Roger Sherman", age: 56 },
    { name: "James Smith", age: 49 },
    { name: "Richard Stockton", age: 48 },
    { name: "Thomas Stone", age: 44 },
    { name: "George Taylor", age: 57 },
    { name: "Matthew Thornton", age: 62 },
    { name: "George Walton", age: 26 },
    { name: "William Whipple", age: 43 },
    { name: "William Williams", age: 45 },
    { name: "James Wilson", age: 34 },
    { name: "John Witherspoon", age: 53 },
    { name: "Oliver Wolcott", age: 41 },
  ];

  const colonies = [
    { name: "Connecticut", count: 4 },
    { name: "Delaware", count: 3 },
    { name: "Georgia", count: 3 },
    { name: "Maryland", count: 4 },
    { name: "Massachusetts", count: 5 },
    { name: "New Hampshire", count: 3 },
    { name: "New Jersey", count: 5 },
    { name: "New York", count: 4 },
    { name: "North Carolina", count: 3 },
    { name: "Pennsylvania", count: 9 },
    { name: "Rhode Island", count: 2 },
    { name: "South Carolina", count: 4 },
    { name: "Virginia", count: 7 },
  ];

  const uniqueAges = [...new Set(ages.map((person) => person.age))];
  const ageCounts = uniqueAges
    .reduce((counts, age) => {
      const count = ages.filter((person) => person.age === age).length;
      return [...counts, { age, count }];
    }, [])
    .sort((a, b) => a.age - b.age);

  const redColor = "#b22234";
  const whiteRedColor = "#EE7180";
  const blueColor = "#1c3f8c";
  const grayColor = 'rgba(200,200,200,0.7)'

  const whiteBlueColor = '#5470AD';
  const blackColor = '#2F2F2F'
  const darkGrayColor = '#464646'
  const mediumBlueColor = '#2C416D'


  const data = {
    labels: ageCounts.map((item) => item.age),
    datasets: [
      {
        label: "Firmantes de la Declaración de Independencia por edad",
        data: ageCounts.map((item) => item.count),
        backgroundColor: blueColor,
        borderWidth: 0,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          color: "rgba(0, 0, 0, 0.5)",
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          color: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const dataPie = {
    labels: [
      "Lawyers",
      "Merchants",
      "Plantation Owners",
      "Farmers",
      "Physicians",
      "Soldiers",
      "Clergymen",
      "Others",
    ],
    datasets: [
      {
        label: "Occupations of Signers of the Declaration of Independence",
        data: [33, 14, 9, 4, 4, 3, 3, 20],
        backgroundColor: [
          redColor, // Rojo
          grayColor, // Blanco
          blueColor, // Azul
          whiteRedColor,
          whiteBlueColor,
          blackColor,
          darkGrayColor,
          mediumBlueColor,
        ],
        borderWidth: 1,
      },
    ],
  };

  const optionsPie = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          fontColor: "rgba(0, 0, 0, 0.8)",
          fontSize: 12,
          padding: 20,
        },
      },
      tooltip: {
        bodyFontColor: "rgba(0, 0, 0, 0.8)",
        titleFontColor: "rgba(0, 0, 0, 0.8)",
      },
    },
  };

  const dataBarA = {
    labels: colonies.map((colony) => colony.name),
    datasets: [
      {
        label: "Firmantes por colonia",
        data: colonies.map((colony) => colony.count),
        backgroundColor: [
          redColor,
          grayColor,
          blueColor,
          redColor,
          grayColor,
          blueColor,
          redColor,
          grayColor,
          blueColor,
          redColor,
          grayColor,
          blueColor,
          redColor,
        ],
      },
    ],
  };

  const optionsBarA = {
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "rgba(0, 0, 0, 0.5)",
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          color: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const dataCon = {
    labels: [
      "Imprisonment",
      "Property loss",
      "Financial ruin",
      "Death during the war",
      "Family hardships",
    ],
    datasets: [
      {
        label: "Signers' hardships during the Revolutionary War",
        backgroundColor: redColor,
        borderRadius:8,
        data: [5, 12, 3, 8, 9],
      },
    ],
  };

  const optionsCon = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "0 auto",
        flexWrap: "wrap",
      }}
    >
      <div className="stat" style={{margin:'0px'}}>
        {/* Age */}
        <h3
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            marginBottom: "20px",
            textAlign: "center",
            // margin: "30px",
          }}
        >
          How old were they?
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <Bar data={data} options={options} />
        </div>
      </div>

      <div className="stat" style={{margin:'0px'}}>
        {/* Occupations */}
        <h3
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            marginBottom: "20px",
            textAlign: "center",
            margin: "30px",
          }}
        >
          What did they do for a living?
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "300px",
            margin: "0 auto",
          }}
        >
          <Pie data={dataPie} options={optionsPie} />
        </div>
      </div>

      <div className="stat" style={{margin:'0px'}}>
        {/* Colony */}
        <h3
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            marginBottom: "20px",
            textAlign: "center",
            margin: "30px",
          }}
        >
          Where did they live?
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "400px",
            margin: "0 auto",
            marginBottom: "30px",
          }}
        >
          <Bar data={dataBarA} options={optionsBarA} />
        </div>
      </div>

      <div className="stat" style={{margin:'0px'}}>
        {/* Colony */}
        <h3
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            marginBottom: "20px",
            textAlign: "center",
            margin: "30px",
          }}
        >
          What happened to them?
        </h3>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "400px",
            margin: "0 auto",
            marginBottom: "30px",
          }}
        >
          <Bar data={dataCon} options={optionsCon} />
        </div>
      </div>
    </div>
  );
};

export default Stats;
