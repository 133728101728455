import React from "react";

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { useState } from "react";
import axios from "axios";

import { Link, useParams, useSearchParams } from "react-router-dom";

const Unsubscribe = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const email = "tomas@whyw8t.com"
  const [email, setEmail] = useState(searchParams.get("email"));

  const toast = useToast();

  const handleUnsubscribe = () => {
    axios
      .post(`/api/unsubscribe?email=${email}&website=sign1776`)
      .then((res) => {
        toast({
          title: "You unsubscribed",
          description: "Hope to see you around again!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      minH={"94vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      style={{
        backgroundImage:
          "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        backgroundSize: "cover",
      }}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Transparencia blanca
            backdropFilter: "blur(10px)",
            marginTop: "-3rem",
          }}
        >
          <Stack align={"center"}>
            <Heading
              style={{ fontSize: "2rem", color: "white", textAlign: "center" }}
            >
              Are you sure you want to unsubscribe?
            </Heading>
          </Stack>
          <br />
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel style={{ color: "white" }}>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                disabled="true"
                style={{ color: "white" }}
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleUnsubscribe}
              >
                Unsubscribe
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Unsubscribe;
