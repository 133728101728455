import React, { useEffect, useState } from "react";
import DoiAtlas from "../components/DoiAtlas";

import "../styles/Declaration.css";
import axios from "axios";

const Declaration = () => {

  return (
    <div className="declaration">
      {/* <div
        className="countdown-section3"
        style={{ width: "100vw", minWidth: "100vw" }}
      >
        <div className="countdown-container3">
          <div className="countdown-title3">Countdown to July 4th</div>
          <div className="countdown3">
            <div className="countdown-item3">
              <div className="countdown-number3">{daysLeft}</div>
              <div className="countdown-label3">DAYS</div>
            </div>
            <div className="countdown-item3">
              <div className="countdown-number3">{hoursLeft}</div>
              <div className="countdown-label3">HOURS</div>
            </div>
            <div className="countdown-item3">
              <div className="countdown-number3">{minutesLeft}</div>
              <div className="countdown-label3">MIN.</div>
            </div>
            <div className="countdown-item3">
              <div className="countdown-number3">{secondsLeft}</div>
              <div className="countdown-label3">SEC.</div>
            </div>
          </div>
          <a href="/sign">
            {!loggedIn && (
              <button
                style={{
                  backgroundColor: "#fff",
                  color: "#222",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  border: "none",
                  fontWeight: "bold",
                  marginTop: "15px",
                }}
              >
                Sign now
              </button>
            )}
          </a>
          <label>
            <span></span>
          </label>
        </div>
      </div> */}
      <div
        // className="declaration"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginTop:"90px"
        }}
      >
        <DoiAtlas />
      </div>


    </div>
  );
};

export default Declaration;
