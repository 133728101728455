import { Timer } from "../Timer/Timer";
import style from "./Participate.module.css";
import { DeclarationSign } from "./DeclarationSign";
import { DeclarationSignMobile } from "./DeclarationSignMobile";

export const Participate = () => {
  return (
    <>
      <section className={style.container}>
        <div className={style.participateContainer}>
          <div className={style.participateTopDataContainer}>
            <Timer />
            <div className={style.participateDataContainer}>
              <p className={style.participateDataTitle}>
                EARLY PARTICIPATION BENEFIT
              </p>
              <p className={style.participateDataDescription}>
                By joining now your signature will be positioned at the very
                core of the Declaration of Independence, signifying not just
                your support but also your bravery as one of the pioneering
                signers.
              </p>
            </div>
          </div>
          <div className={style.backgroundText}>
            <p>the</p>
            <p>56</p>
            <p>and you</p>
          </div>
          <DeclarationSignMobile />
          <a href="#sign" className={style.signButton}>
            I want to sign now !
          </a>
        </div>
        <div className={style.backgroundTextDesktop}>
          <p>the</p>
          <p>56</p>
          <p>and you</p>
        </div>
        <DeclarationSign />
      </section>
    </>
  );
};
