export const data = [
  {
    delay: "50",
    iconPath: "/assets/newspaper.png",
    title: "Media Manipulation",
    description: `Media outlets are twisting facts and narratives to suit their own agendas, eroding the public's ability to make well-informed decisions`,
  },
  {
    delay: "200",
    iconPath: "/assets/censorship.png",
    title: "Censorship",
    description: `Cancel culture is silencing voices and shaping the future in ways that threaten our freedom of speech`,
  },
  {
    delay: "300",
    iconPath: "/assets/justice.png",
    title: "Political Prosecution",
    description: `The Government is manipulating the legal system to target individuals based on their political beliefs undermining the basic freedoms that America stands for`,
  },
];
