import { useEffect, useState } from "react";
import style from "./Timer.module.css";

export const Timer = () => {
  const [secondsLeft, setSecondsLeft] = useState(30 * 24 * 60 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const days = Math.floor(secondsLeft / (24 * 60 * 60));
  const hours = Math.floor((secondsLeft % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((secondsLeft % (60 * 60)) / 60);
  const seconds = secondsLeft % 60;

  return (
    <div className={style.countdownNumberContainer}>
      <div className={style.countdownNumberItem}>
        <p className={style.countdownNumber}>{days}</p>
        <p className={style.countdownLabel}>DAYS</p>
      </div>
      <div className={style.countdownNumberItem}>
        <p className={style.countdownNumber}>{hours}</p>
        <p className={style.countdownLabel}>HOURS</p>
      </div>
      <div className={style.countdownNumberItem}>
        <p className={style.countdownNumber}>{minutes}</p>
        <p className={style.countdownLabel}>MIN</p>
      </div>
      <div className={style.countdownNumberItem}>
        <p className={style.countdownNumber}>{seconds}</p>
        <p className={style.countdownLabel}>SEC</p>
      </div>
    </div>
  );
};
