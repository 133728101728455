export const OpenQuote = () => {
  return (
    <svg
      width="34"
      height="27"
      viewBox="0 0 34 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.130527 0.903811L0.130531 8.41885C0.130531 10.5522 0.547691 12.7339 1.38202 14.9642C2.24972 17.2268 3.3844 19.3439 4.78607 21.3156C6.18774 23.3196 7.68952 25.0166 9.29143 26.4065L16.2998 22.5762C15.1651 20.6692 14.1639 18.6005 13.2962 16.3702C12.4285 14.14 11.9946 11.4895 11.9946 8.41885V0.90381L0.130527 0.903811ZM17.4011 0.90381V8.41885C17.4011 10.5522 17.8182 12.7339 18.6526 14.9642C19.5203 17.2268 20.6549 19.3439 22.0566 21.3156C23.4583 23.3196 24.9601 25.0166 26.562 26.4065L33.5703 22.5762C32.4356 20.6692 31.4344 18.6005 30.5667 16.3702C29.699 14.14 29.2652 11.4895 29.2652 8.41885L29.2652 0.903809L17.4011 0.90381Z"
        fill="#3F3E3E"
      />
    </svg>
  );
};
