import { useLocation } from "react-router-dom";
import { CustomSignatureCard } from "../customSignatureCard/CustomSignatureCard";
import { CustomSignatureCeCard } from "../customSignatureCard/CustomSignatureCeCard";
import style from "./Hero.module.css";

export const Hero = () => {
  const location = useLocation();

  // Verificar el path actual y decidir qué componente renderizar
  const renderSignatureCard = () => {
    if (location.pathname === "/promo") {
      return <CustomSignatureCard />;
    } else if (location.pathname === "/cepromo") {
      return <CustomSignatureCeCard />;
    } else {
      // Renderizar un componente predeterminado o mostrar un mensaje de error
      return <p>not a valid path</p>;
    }
  };

  return (
    <section className={style.container}>
      <div className={style.heroContainer}>
        <div className={style.heroData}>
          <img src="/assets/Doi_alternative.png" alt="DOI logo" />
          <div className={style.heroDataText}>
            <h1>
              Be part of a <span>national movement</span> against censorship,
              Political Prosecution and Media Manipulation
            </h1>
            <p>
              Help us prevent the erasure of our founding document. We will
              deliver all signatures to congress on an updated Declaration of
              Independence
            </p>
          </div>
        </div>
        {renderSignatureCard()}
      </div>
    </section>
  );
};
