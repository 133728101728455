import { Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const VerifyEmailPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "93.8vh",
        backgroundColor: "#f5f5f5",
        backgroundImage:
          "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        backgroundSize: "cover",
        padding: "16px",
      }}
    >
      <div
        style={{
          textAlign: "center",
          maxWidth: "400px",
          borderRadius: "8px",
          padding: "32px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "rgba(0, 0, 0, 0.15)", // Transparencia blanca
          backdropFilter: "blur(10px)", // Efecto de desenfoque
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#fff",
            marginBottom: "16px",
          }}
        >
          Account Verification
        </h1>
        <p
          style={{
            fontSize: "18px",
            color: "#fff",
            marginBottom: "12px",
            lineHeight: "1.5",
            textAlign:'justify'
          }}
        >
          Thank you for signing! We've sent a verification link to your email.
        </p>
        <p
          style={{
            fontSize: "18px",
            color: "#fff",
            marginBottom: "12px",
            lineHeight: "1.5",
            textAlign:"justify"
          }}
        >
          Please check your inbox and follow the instructions to get your signature approved. 
           
        </p>
        <p
          style={{
            fontSize: "18px",
            color: "#fff",
            marginBottom: "12px",
            lineHeight: "1.5",
            textAlign:"justify"
          }}
        >
           If you can't find it there please make sure you check your spam folder too!
        </p>
        <Link to="/resend">
          <button
            style={{
              backgroundColor: "#fff",
              color: "#222",
              padding: "8px 20px",
              borderRadius: "5px",
              border: "none",
              fontWeight: "bold",
              marginTop: "15px",
            }}
          >
            Resend verification email
          </button>
        </Link>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
