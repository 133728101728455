import React, { useEffect } from "react";

import "../styles/Home.css";
import TagManager from "react-gtm-module";

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";

const ThanksStripe = () => {
  const logOut = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  // get "value" and "eventId" url params
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get("value");
  // const eventId = searchParams.get("eventId");

  // use a useEffect that triggers only on first render
  useEffect(() => {
    if (value) {
      TagManager.dataLayer({
        dataLayer: {
          event: "itemSold",
          value: value,
          currency: "USD",
        },
      });
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "93.8vh",
        backgroundImage:
          "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        backgroundSize: "cover",
        // filter: "grayscale(100%)",
        // backgroundPosition: "center calc(70% - 20%)",
      }}
    >
      <section
        className="thanks-sec2 sec-pad"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparencia blanca
          backdropFilter: "blur(10px)", // Efecto de desenfoque
          // backgroundColor: "black",
          borderRadius: "8px",
          padding: "30px 50px",
          textAlign: "center",
          maxWidth: "fit-content",
          margin: "0 auto",
          display: "flex",
          height: "fit-content",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          marginBottom: "4rem",
        }}
      >
        <h1
          className="sec-title"
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#fff",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
          }}
        >
          <span className="sec-title2" style={{ fontSize: "2rem" }}>
            Thanks for being counted and signing
          </span>{" "}
          <br /> Taking a position is bravery and tells the world
          where you stand!
        </h1>
      </section>

      <div
        style={{ display: "flex", flexDirection: "row" }}
        className="thanks-container"
      >
        <section
          className="thanks-sec"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparencia blanca
            backdropFilter: "blur(10px)", // Efecto de desenfoque
            borderRadius: "8px",
            borderRadius: "8px",
            padding: "30px",
            textAlign: "center",
            maxWidth: "400px",
            margin: "0 auto",
            display: "flex",
            height: "fit-content",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            height: "fit-content",
          }}
        >
          <img
            className="modal-img"
            style={{
              objectFit: "cover",
              height: "8rem",
              borderRadius: "100px",
              width: "8rem",
              margin: "0 auto",
            }}
            src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/process.png"
            alt=""
          />
          <p
            style={{
              fontSize: "18px",
              color: "#fff",
              marginBottom: "35px",
              textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            }}
          >
            Do you want to find your signature in the declaration of
            independence?
          </p>
          <a href={`/declaration`}>
            <button
              style={{
                backgroundColor: "#2C4C70",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "bold",
                padding: "15px 30px",
                borderRadius: "8px",
                border: "none",
                cursor: "pointer",
                display: "block",
                margin: "0 auto",
              }}
            >
              Take me there
            </button>
          </a>
        </section>
        <section
          className="thanks-sec"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparencia blanca
            backdropFilter: "blur(10px)", // Efecto de desenfoque
            borderRadius: "8px",
            borderRadius: "8px",
            padding: "30px",
            textAlign: "center",
            maxWidth: "400px",
            margin: "0 auto",
            display: "flex",
            height: "fit-content",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            marginLeft: "25px",
          }}
        >
          <img
            className="modal-img"
            style={{
              objectFit: "cover",
              height: "8rem",
              borderRadius: "100px",
              width: "8rem",
              margin: "0 auto",
            }}
            src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/congress.jpeg"
            alt=""
          />
          <p
            style={{
              fontSize: "18px",
              color: "#fff",
              marginBottom: "12px",
              textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            }}
          >
            Get a replica of the Declaration of Independence with YOUR signature
            on it!{" "}
          </p>

          <br />

          <a href="/shop">
            <button
              style={{
                backgroundColor: "#B22234",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "bold",
                padding: "15px 30px",
                borderRadius: "8px",
                border: "none",
                cursor: "pointer",
                display: "block",
                margin: "0 auto",
              }}
            >
              Take me to the shop!
            </button>
          </a>
        </section>
      </div>
      <div
        className="thanks-sec"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparencia blanca
          backdropFilter: "blur(10px)", // Efecto de desenfoque
          borderRadius: "8px",
          borderRadius: "8px",
          padding: "30px",
          textAlign: "center",
          maxWidth: "500px",
          display: "flex",
          height: "fit-content",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          marginLeft: "25px",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginTop: "2rem",
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "600",
          }}
          className="titleth"
        >
          Operation John hancock{" "}
        </Heading>

        <Text
          fontSize="xl"
          color={"white"}
          style={{
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
          }}
          className="subtitleth"
        >
          Earn stars by sharing your referral link with your friends and family!
        </Text>
        <a href="/referral">
          <button
            style={{
              // backgroundColor: "white",
              backgroundColor: "#B22234", // Azul oscuro
              marginTop: "2rem",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "15px 40px",
              fontWeight: "bold",
              fontSize: "16px",
              letterSpacing: "1px",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Sombra
            }}
          >
            Participate
          </button>
        </a>
      </div>
      <div>
        <section
          className="thanks-sec"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparencia blanca
            backdropFilter: "blur(10px)", // Efecto de desenfoque
            borderRadius: "8px",
            borderRadius: "8px",
            padding: "30px",
            textAlign: "center",
            maxWidth: "400px",
            margin: "0 auto",
            display: "flex",
            height: "fit-content",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            marginLeft: "25px",
          }}
        >
          {" "}
          <button
            style={{ color: "#fff", fontWeight: "600" }}
            onClick={() => logOut()}
          >
            <p>Do you want someone else to sign on this same device? </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ textDecoration: "underline", paddingRight: "5px" }}>
                Log Out
              </p>
              <p> to let them sign too!</p>
            </div>
          </button>
        </section>
      </div>
    </div>
  );
};

export default ThanksStripe;
