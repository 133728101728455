import {
  Box,
  Flex,
  Text,
  Heading,
  Input,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faAward,
  faSignature,
  faDollarSign,
  faUsers,
  faPenFancy,
  faAsterisk,
  faPlus,
  faLink,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/referrals.css";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";

import {
  faInstagram,
  faXTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import {
  faPaperPlane,
  faEnvelope,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

export const Referrals = () => {
  // fetch data from API when component mounts
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const email = localStorage.getItem("email");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const toast = useToast();
  const [bought, setBought] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/user/raffle?email=${email}`, {
        headers: {
          Authorization: `Bearer ${token})}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
        // console.log(data);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
    // setIsLoading(false);
  }, [email, token]);

  const emailLogged = localStorage.getItem("email") || null;

  useEffect(() => {
    if (emailLogged) {
      axios
        .get(`/api/user?email=${emailLogged}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const usr = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  }, []);

  const handleCopyLink = () => {
    const input = document.getElementById("referralLinkInput");
    input.select();
    document.execCommand("copy");
    toast({
      title: "Copied to clipboard!",
      duration: 3000,
    });
  };

  let shareUrl = "";
  if (data) {
    shareUrl = data.referralLink;
  } else {
    shareUrl = "https://sign1776.com";
  }
  const subject =
    "I just signed the Declaration of Independence! Do you dare to do the same?";
  const encodedUrl = encodeURIComponent(shareUrl);
  const shareText =
    "I just signed The Declaration of Independence! Be brave like me and reaffirm your commitment to the most important document in American History!";
  const encodedShareText = encodeURIComponent(shareText);

  useEffect(() => {
    axios.get(`/api/user/orders?email=${emailLogged}`).then((res) => {
      console.log(res.data);
      if (res.data.boughtSignature == true) {
        setBought(true);
      }
    });
  }, []);

  return (
    <Flex
      align="center"
      justify={"center"}
      py={4}
      px={2}
      flexDir="column"
      spacing={8}
      style={{
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/homePen.png')",
        backgroundSize: "cover",
        height: "fit-content",
        color: "white",
        minHeight: "93.7vh",
      }}
    >
      <Heading className="raffle-title" style={{ marginTop: "4rem" }}>
        Operation John Hancock
      </Heading>
      <Heading
        fontSize="lg"
        style={{ marginTop: "0.5rem" }}
        className="raffle-sub"
      >
        Earn stars by sharing your referral link with your friends and family!
      </Heading>

      {isLoading && <Text>Loading...</Text>}
      {data && (
        <Heading
          as="h1"
          size="lg"
          fontWeight="bold"
          textAlign="center"
          className="raffle-link"
          style={{ width: "35vw" }}
        >
          <br />
          <Text style={{ marginBottom: "0.5rem", fontSize: "1.3rem" }}>
            Share Your Link
          </Text>
          <Flex>
            <Input
              id="referralLinkInput"
              readOnly
              // value="hhh"
              value={data.referralLink}
              onClick={handleCopyLink}
              style={{
                borderColor: "#b22234",
                border: "2px solid #b22234",
                borderRadius: "10px 0px 0px 10px",
                cursor: "pointer",
              }}
            />
            <IconButton
              aria-label="Copy Link"
              style={{
                backgroundColor: "#b22234",
                borderRadius: "0px 10px 10px 0px ",
              }}
              icon={<FontAwesomeIcon icon={faLink} />}
              onClick={handleCopyLink}
            />
          </Flex>
          <span
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
            className="raffle-howmany"
          >
            {" "}
            Stars earned: {data.raffleTickets}!
          </span>
        </Heading>
      )}
      {error && (
        <Text
          bgColor="red.400"
          p={4}
          borderRadius={4}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          There was an error fetching the data
        </Text>
      )}

      <Box
        style={{ marginBottom: "2rem" }}
        // className="share-section"
        // bg="rgba(000, 000, 000, 0.1)"
        // p={4}
        // borderRadius="md"
        // backdropFilter="blur(5px)"
        // style={{ width: "41vw", textAlign: "center", marginTop: "2rem" }}
      >
        {/* <Heading fontSize="3xl" mt={2} style={{ marginBottom: "1rem" }}>
          Invite people to be part of the operation!
        </Heading> */}
        <Flex justifyContent="center">
          <a
            href={`mailto:?subject=${subject}&body=${encodedShareText}%0A${encodedUrl}`}
          >
            <FontAwesomeIcon
              style={{ height: "40px", margin: "0.5rem" }}
              icon={faEnvelope} // Email icon
            />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${encodedShareText}`}
          >
            <FontAwesomeIcon
              style={{ height: "40px", margin: "0.5rem" }}
              icon={faFacebook}
            />
          </a>
          <a href={`sms:&body=${encodedShareText}%0A${encodedUrl}`}>
            <FontAwesomeIcon
              style={{ height: "40px", margin: "0.5rem" }}
              icon={faComment} // Messaging icon
            />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodedShareText}`}
          >
            <FontAwesomeIcon
              style={{ height: "40px", margin: "0.5rem" }}
              icon={faXTwitter}
            />
          </a>
        </Flex>
      </Box>

      <Box
        className="raffle-prize"
        bg="rgba(000, 000, 000, 0.1)"
        p={4}
        borderRadius="md"
        backdropFilter="blur(5px)"
        style={{ width: "41vw", textAlign: "center" }}
      >
        <FontAwesomeIcon icon={faTrophy} size="2x" />
        <Heading fontSize="3xl" mt={2} style={{ marginBottom: "-2rem" }}>
          Draw Prizes
        </Heading>
        <Text mt={2}>
          <div style={{ textAlign: "left", padding: "1rem" }}>
            {" "}
            <br />
            <span style={{ fontWeight: "bold", fontSize: "1.05rem" }}>
              Opportunity to Join the DOIP Team in Delivering the physical copy
              of the Declaration of Independence to congress! This opportunity
              includes:
            </span>
            <br />
            <br />
            • Fully Paid trip to D.C for two (3 Days, 2 Nights).
            <br />
            • Flights from any of the 50 states.
            <br />
            • Lodging, Meals, Events.
            <br />
            • Planned for September 2024
            <br />
            • Five winners will be announced 45 days before the travel date!
            <br />
            <br />{" "}
            <span style={{ fontWeight: "bold", fontSize: "1.05rem" }}>
              Ready to win big? Here’s how:
            </span>
            <br />
            <div style={{ marginTop: "0.5rem" }}></div>
            <span style={{ fontWeight: "bold" }}>Draw </span>- Win one of the
            first four All-Inclusive Packages in a Random Drawing—the more Stars
            you have, the better your chances!
            <br />
            <div style={{ marginTop: "1rem" }}></div>
            <span style={{ fontWeight: "bold" }}>Final Prize</span>- The fifth
            All-Inclusive Package will be awarded to the signer with the most
            Stars earned!
          </div>
        </Text>
      </Box>
      <Heading
        className="raffle-title2"
        style={{ marginTop: "2rem", marginBottom: "2rem", fontSize: "1.8rem" }}
      >
        More Stars, More Chances!
      </Heading>

      <Box
        display={{ base: "block", md: "grid" }}
        gridTemplateColumns={{ md: "repeat(2, 1fr)" }}
        gap={4}
        maxWidth="60vw"
        className="raffle-box"
      >
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          <FontAwesomeIcon icon={faSignature} size="2x" />
          <Heading fontSize="lg" mt={2}>
            Your Digital Signature
          </Heading>
          <Text mt={2}>- 1 star for your digital signature.</Text>
        </Box>
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          <FontAwesomeIcon icon={faPenFancy} size="2x" />
          <Heading fontSize="lg" mt={2}>
            Your Physical Signature
          </Heading>
          <Text mt={2}>
            - 3 stars by becoming a Percenter (Small Signature)
            <br /> - 15 stars by being part of the Colonies (Medium Signature)
            <br /> - 25 stars by becoming a founder (John Hancock
            Sized Signature)
          </Text>
        </Box>
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          <FontAwesomeIcon icon={faAsterisk} size="2x" />
          <Heading fontSize="lg" mt={2}>
            Referral Bonuses
          </Heading>
          <Text mt={2}>
            - 2 Stars for each referred individual that digitally signs.
            <br /> - 5 Stars for each referred individual that becomes a COLONY
            or FOUNDER.
          </Text>
        </Box>
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          <FontAwesomeIcon icon={faPlus} size="2x" />
          <Heading fontSize="lg" mt={2}>
            Additional Incentives{" "}
          </Heading>
          <Text mt={2}>
            - 20 Stars for every 10 COLONIES or FOUNDERS referred. <br />-
            Framed hard copy of the DOI (Declaration of Independence) for
            referring 20 individuals that become COLONIES or FOUNDERS <br />
          </Text>
        </Box>
      </Box>

      {bought ? (
        <a href="/shop">
          <button
            style={{
              // backgroundColor: "white",
              backgroundColor: "#B22234", // Azul oscuro
              marginTop: "2rem",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "15px 40px",
              fontWeight: "bold",
              fontSize: "16px",
              letterSpacing: "1px",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Sombra
              marginBottom: "2rem",
            }}
          >
            Get The Replica
          </button>
        </a>
      ) : (
        <a href="/select-size">
          <button
            style={{
              // backgroundColor: "white",
              backgroundColor: "#B22234", // Azul oscuro
              marginTop: "2rem",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "15px 40px",
              fontWeight: "bold",
              fontSize: "16px",
              letterSpacing: "1px",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Sombra
              marginBottom: "2rem",
            }}
          >
            Get Physical Signature
          </button>
        </a>
      )}
    </Flex>
  );
};
