import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Textarea,
  useBreakpointValue,
  useToast
} from "@chakra-ui/react";
import "../styles/Home.css";
import "../styles/Thanks.css";

import axios from "axios";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();
  };

  const sendEmail = () => {
    // const recipient = "support@sign1776.com";
    // const subject = "Mensaje desde el formulario de contacto";
    // const body = `Email: ${email}\nMensaje: ${message}`;

    // const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    //   subject
    // )}&body=${encodeURIComponent(body)}`;

    // window.open(mailtoLink);

    axios
      .post("/api/contact", {
        email: email,
        message: message,
      })
      .then((res) => {
        if (res.status === 200) {
          toast({
            title: "Message sent!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast({
          title: "There was an error, please try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  // Obtener el ancho del formulario basado en el breakpoint actual
  const formWidth = useBreakpointValue({
    base: "65vw",
    sm: "65vw",
    md: "35vw",
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="93.8vh"
      backgroundImage="url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')"
      backgroundSize="cover"
      padding="20px"
      color="#fff"
      textAlign="center"
    >
      <form
        onSubmit={handleSubmit}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Transparencia blanca
          backdropFilter: "blur(10px)", // Efecto de desenfoque
          padding: "3rem",
          borderRadius: "12px",
        }}
      >
        <h2
          style={{ fontSize: "24px", marginBottom: "20px", fontWeight: "bold" }}
        >
          Contact Us
        </h2>

        <Box w={formWidth} mb="20px">
          <Input
            style={{ color: "black" }}
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fontSize="16px"
            borderRadius="5px"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            placeholder="Your email address..."
            required
            maxLength={100}
          />
        </Box>
        <Box w={formWidth} mb="20px">
          <Textarea
            style={{ color: "black" }}
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fontSize="16px"
            borderRadius="5px"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            height="150px"
            placeholder="Write down your message..."
            required
            maxLength={1000}
          />
        </Box>
        <Button
          type="submit"
          padding="10px 20px"
          fontSize="16px"
          borderRadius="5px"
          backgroundColor="#4285f4"
          color="#fff"
          cursor="pointer"
          transition="background-color 0.3s ease"
        >
          Send
        </Button>
      </form>
    </Box>
  );
};

export default ContactUs;
