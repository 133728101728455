import style from "./Against.module.css";
import { data } from "./data";

export const Against = () => {
  return (
    <section className={style.container}>
      <div className={style.againstContainer}>
        <p className={style.againstTitle}>WHAT WE ARE UP AGAINST</p>

        <div className={style.againstItems}>
          {data.map((item, i) => (
            <div
              className={style.againstItem}
              key={i}
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay={item.delay}
            >
              <img src={item.iconPath} alt={`${item.title} image`} />
              <div className={style.againstItemText}>
                <p className={style.againstItemTextTitle}>{item.title}</p>
                <p className={style.againstItemTextDescription}>
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
