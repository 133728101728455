import style from "./Carousel.module.css";
import { founders } from "./data";

export const Carousel = () => {
  return (
    <section className={style.container}>
      <div className={style.carouselContainer}>
        <div className={style.carouselText}>
          <p className={style.carouselTitle}>BE PART OF HISTORY</p>
          <p className={style.carouselDescription}>
            Join our founding fathers to reaffirm the Declaration's enduring
            value.
          </p>
        </div>
        <div className={style.carousel}>
          {founders.map((founder, i) => (
            <div
              className={style.carouselItem}
              key={i}
              data-aos="fade-up"
              data-aos-delay={founder.delay}
              data-aos-once="true"
            >
              <img
                className={
                  founder.type === "founder"
                    ? style.carouselImage
                    : style.youImage
                }
                src={founder.imageUrl}
                alt="founding father"
              />
              <div
                className={
                  founder.type === "founder"
                    ? style.carouselSignImage
                    : style.youSignImage
                }
              >
                <img src={founder.signImageUrl} alt="founding father sign" />
              </div>
            </div>
          ))}
        </div>
        <a href="#sign">Join them now</a>
      </div>
    </section>
  );
};
