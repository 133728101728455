import React, { useEffect, useState } from "react";
import "../styles/Declaration.css";
import { ReactSVG } from "react-svg";
import axios from "axios";

const AtlasBox = ({ signature, key }) => {
  const Svg = () => {
    const [svg, setSvg] = useState(null);
    const [svgLoaded, setSvgLoaded] = useState(false);
  
    useEffect(() => {
      axios.get(signature.svg).then((res) => {
        setSvg(res.data);
        setSvgLoaded(true);
      });
    }, []);
  
    const divStyle = {
      background: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}") no-repeat center / contain`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
      height: '100%',
    };
  
    return (
      <div style={divStyle}>
        {!svgLoaded && <p style={{
              className:"signature_text",
              gridrow:"2",
              textAlign: "center",
              fontSize: "1cqw",
              marginBottom: "0.8rem",
            }}>Loading signature...</p>}
      </div>
    );
  };

  return (
    signature?.location &&
    (signature?.name || signature?.svg) && (
      <div
        id={signature.name ? signature.name : signature.svg}
        key={`${key}`}
        className="doi-box"
        style={{
          display:"grid",
          gridTemplateRows: "1.2fr 0.8fr",
          gridTemplateColumns:"1fr",
          justifyContent:"center",
          textAlign:"center",
          rows:"2",
          columns:"1",
          padding:"4px",
          gridColumn: `${signature.location.innerColumn + 1}`,
          gridRow: `${signature.location.innerRow + 1}`,
        }}
      >
            
          <div style={{gridrow:"1",gridColumn:"1",width:"100%",height:"100%",justifyContent:"center",alignContent:"center",textAlign:"100%"}}>
            {signature?.media && signature?.status =="approved" &&(<div
                className="img-doi"
                style={{
                  background: `url(${signature.media}) no-repeat center / cover`,
                  gridrow:"1",
                  margin: "0 auto",
                  width:"8cqw",
                  height:"100%",
                }}
              />
              ) }
          </div>
          <div style={{gridrow:"2",gridColumn:"1", height:"100%", width:"100%"}}>
            {signature.svg && (
              <div  style={{width:"100%",height:"100%",gridrow:"2"}}>
                <Svg />
              </div>
            )}
            {!signature.svg && (
            <div
            style={{
              className:"signature_text",
              gridrow:"2",
              textAlign: "center",
              fontSize: "1.5cqw",
              marginBottom: "0.8rem",
            }}
            >
            {signature.name}
            </div>)}
          </div>
        
      </div>
    )
  );
};
export default AtlasBox;
