import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import Card from "../components/Card";
import signers from "../components/signersData2.json";

import { useLocation } from "react-router-dom";

import "../styles/Signers.css";

const Signers = () => {
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");

  const [searchArray, setSearchArray] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const heroName = params.get("heroName") || "";

  const [isSmallerThanTablet] = useMediaQuery("(max-width: 768px)");
  const [isLargerThanMobile] = useMediaQuery("(min-width: 481px)");

  const isSmallerThanDesktop = isLargerThanMobile && !isSmallerThanTablet;

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (heroName) {
      signers.map((items, index) => {
        console.log(items);
        console.log(index);
        items.map((signer) => {
          if (heroName === signer.Name) {
            console.log(signer);
            setIndex(index);
          }
        });
      });
    }
  }, [heroName]);

  useEffect(() => {
    setSearchArray([]);
    findHero(search);
  }, [search]);

  const findHero = () => {
    const results = signers.reduce((accumulator, page) => {
      const matchingItems = page.filter(
        (item) => item.Name.toUpperCase().indexOf(search.toUpperCase()) !== -1
      );
      return [...accumulator, ...matchingItems];
    }, []);

    setSearchArray(results);
  };

  return (
    <Box
      p={5}
      style={{
        backgroundImage:
          "url(https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/signers/fondo-papel-viejo-vendimia-grunge.jpg)",
        backgroundSize: "fill",
        // backgroundPosition: "0px -100px",
        textAlign: "center",
        // height: "100%",
      }}
      className="background-fill"
    >
      <Box
        as="h1"
        fontSize="2xl"
        mb={5}
        style={{
          fontFamily: "Bitter",
          fontWeight: "bold",
          fontSize: "2.8rem",
          marginTop: "5rem",
        }}
      >
        The Signers Archive
      </Box>
      <Box
        as="p"
        mb={5}
        style={{
          fontFamily: "Bitter",
          fontWeight: "bold",
          fontSize: "1.2rem",
          marginBottom: "4rem",
        }}
      >
        Learn more about the Sacrifices made by the brave men who signed the
        Declaration of Independence.
      </Box>
      <input
        style={{
          backgroundColor: "transparent",
          border: "3px solid black",
          fontSize: "1rem",
          fontFamily: "Gentium Plus",
          fontWeight: "bold",
          borderRadius: "12px",
          height: "60px",
          width: "70vw",
          paddingLeft: "1rem",
          marginBottom: "4rem",
          color: "black",
          outline: "none", // Elimina el borde en el foco
        }}
        placeholder="Search for your hero..."
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box display="flex" justifyContent="center">
        <SimpleGrid columns={[1, 2, 2, 4]} spacing={5}>
          {search === ""
            ? signers[index].map((item, index) => (
                <div id={`${item.Name}`}>
                  <Card key={index} item={item} />
                </div>
              ))
            : searchArray.map((item, index) => (
                <div id={`${item.Name}`}>
                  <Card key={index} item={item} />
                </div>
              ))}
        </SimpleGrid>
      </Box>

      <Flex
        // style={{
        //   // backgroundColor: "black",
        //   color: "black",
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        //   width: "55vw",
        //   padding: "1.5rem",
        //   borderRadius: "16px",
        //   // opacity: "0.8",
        //   marginBottom: "5rem",
        //   margin: "0 auto",
        //   fontSize: "1.3rem",
        //   fontFamily: "Bitter",
        //   fontWeight: "700",
        // }}
        fontSize={{ base: "sm", md: "md", lg: "lg", xl: "xl" }}
        fontFamily="bitter"
        fontWeight="700"
        padding="1rem"
        margin="0 auto"
        align="center"
        justify="space-around"
        w={{ base: "90vw", md: "55vw" }}
      >
        <Button
          onClick={() => {
            if (index !== 0) {
              setIndex(0);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
              });
            }
          }}
          pointerEvents={index === 0 ? "none" : "auto"}
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
        >
          <Text color={index === 0 ? "transparent" : "black"}>{"<<"}</Text>
        </Button>
        <Button
          onClick={() => {
            if (index !== 0) {
              setIndex(index - 1);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
              });
            }
          }}
          pointerEvents={index === 0 ? "none" : "auto"}
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
        >
          <Text color={index === 0 ? "transparent" : "black"}>{"< BACK"}</Text>
        </Button>

        <Text whiteSpace={"nowrap"}>Page {index + 1} of 5</Text>

        <Button
          onClick={() => {
            if (index < 4) {
              setIndex(index + 1);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
              });
            }
          }}
          pointerEvents={index === 4 ? "none" : "auto"}
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
        >
          <Text color={index === 4 ? "transparent" : "black"}>{"NEXT >"}</Text>
        </Button>
        <Button
          onClick={() => {
            if (index !== 4) {
              setIndex(4);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
              });
            }
          }}
          pointerEvents={index === 4 ? "none" : "auto"}
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
        >
          <Text color={index === 4 ? "transparent" : "black"}>{">>"}</Text>
        </Button>
      </Flex>
      <button className="button-floating" onClick={handleClick}>
        Top
      </button>
    </Box>
  );
};

export default Signers;
