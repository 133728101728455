import { useEffect, useRef, useState } from "react";
import style from "./CustomSignatureCard.module.css";
import isEmail from "validator/lib/isEmail";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import SignaturePad from "signature_pad";

export const CustomSignatureCeCard = () => {
  const [activeTab, setActiveTab] = useState("TEXT");
  const [signature, setSignature] = useState("");
  const [signatureDataURL, setSignatureDataURL] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [file, setFile] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email"));
  const toast = useToast();
  const navigate = useNavigate();
  const [referralCode, setReferralCode] = useState(
    searchParams.get("referralCode") || localStorage.getItem("referralCode")
  );

  const canvasRef = useRef();
  const signaturePadRef = useRef();
  const signatureRef = useRef();
  signatureRef.current = signatureDataURL;
  const pid = searchParams.get("pid") || null;
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEmail(email)) {
      toast({
        title: "Invalid email.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    //SIGNATURE PAD PART
    if (activeTab === "PEN" && signaturePadRef.current && isEmail(email)) {
      const dataURL = signaturePadRef.current.toSVG();
      const tempElement = document.createElement("div");
      tempElement.setAttribute("style", "visibility: hidden");
      document.body.appendChild(tempElement);
      tempElement.innerHTML = dataURL;
      const svgElement = tempElement.getElementsByTagName("svg")[0];
      const bbox = svgElement.getBBox();
      const viewBox = [
        bbox.x - 5,
        bbox.y - 5,
        bbox.width + 10,
        bbox.height + 10,
      ].join(" ");
      svgElement.setAttribute("viewBox", viewBox);
      svgElement.removeAttribute("width");
      svgElement.removeAttribute("height");
      const croppedSVG = svgElement.outerHTML;
      document.body.removeChild(tempElement);

      setSignatureDataURL(croppedSVG);
      signatureRef.current = croppedSVG;
    }

    if (!signature.length > 0 && !signatureRef.current.length > 0) {
      toast({
        title: "Invalid signature.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    } else if (
      isEmail(email) &&
      (signature.length > 0 || signatureRef.current.length > 0)
    ) {
      axios
        .post("/api/register", {
          email: email,
          signature: {
            name: signature.length > 0 ? signature : null,
          },
          payload: file ? file : null,
          fileExtension: fileExtension ? fileExtension : null,
          svg: signatureRef.current.length > 0 ? signatureRef.current : null,
          pid: pid,
          utm: {
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
          },
          referralCode: referralCode,
        })
        .then((res) => {
          if (res.status === 200) {
            TagManager.dataLayer({
              dataLayer: {
                event: "userSigned",
              },
            });
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("signature", res.data.signature);
            localStorage.setItem("email", email);
            navigate("/thanks");
          }
        })
        .catch((err) => {
          console.log(err)
          toast({
            title: err.response.data.error ? err.response.data.error : "Error registering signature.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSignatureChange = (event) => {
    setSignature(event.target.value);
  };

  signatureRef.current = signatureDataURL;

  useEffect(() => {
    if (canvasRef.current && activeTab === "PEN") {
      canvasRef.current.width = canvasRef.current.offsetWidth;
      canvasRef.current.height = canvasRef.current.offsetHeight;
      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, [activeTab]);

  const handleResetSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={style.signatureContainer}
      id="sign"
    >
      <div className={style.signatureFormInputSection}>
        <div>
          <p className={style.signatureFormInputTitle}>Sign now!</p>
          <p className={style.signatureFormInputDescription}>
            Each signature serves as a powerful endorsement of the core
            principles that safeguard our American Freedoms
          </p>
          <div className={style.signatureBoxContainer}>
            <div className={style.signatureBoxTabs}>
              <span
                className={activeTab === "TEXT" ? style.active : ""}
                onClick={() => {
                  setActiveTab("TEXT");
                  setSignatureDataURL("");
                }}
              >
                Name
              </span>
              <span
                className={activeTab === "PEN" ? style.active : ""}
                onClick={() => {
                  setActiveTab("PEN");
                  setSignature("");
                }}
              >
                Pen
              </span>
            </div>
            {activeTab === "PEN" && (
              <div className={style.padContainer}>
                <canvas
                  ref={canvasRef}
                  className={style.pad}
                  width={350}
                  height={190}
                />
                <button type="button" onClick={handleResetSignature}>
                  <p>Clear</p>
                </button>
              </div>
            )}
            {activeTab === "TEXT" && (
              <div className={style.signaturePad}>
                <p>{signature}</p>
              </div>
            )}
          </div>
        </div>
        <div className={style.signatureInputs}>
          {activeTab === "TEXT" && (
            <>
              <div className={style.formGroup}>
                <input
                  type="text"
                  id="signature"
                  name="signature"
                  autoComplete="off"
                  placeholder=" "
                  required={true}
                  value={signature}
                  onChange={handleSignatureChange}
                />
                <label htmlFor="signature">Name on Signature*</label>
              </div>
            </>
          )}
          {/* <div className={style.formGroup}>
            <input
              type="email"
              id="email"
              name="email"
              placeholder=" "
              autoComplete="off"
              required={true}
              value={email}
              onChange={handleEmailChange}
            />
            <label htmlFor="email">Email*</label>
          </div> */}
        </div>
      </div>
      <button>sign</button>
    </form>
  );
};
