import { Box, Flex } from "@chakra-ui/react";
import { Outlet, useSearchParams } from "react-router-dom";
import WithSubnavigation from "./Navigation";
import { useState } from "react";

export default function NavBar() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [referralCode, setReferralCode] = useState(
    searchParams.get("referralCode") || localStorage.getItem("referralCode")
  );
  if (referralCode) {
    localStorage.setItem("referralCode", referralCode);
  }
  return (
    <Flex flexDir="column">
      <WithSubnavigation />
      <Outlet />
    </Flex>
  );
}
