import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { useState } from "react";
import axios from "axios";

export default function Login() {
  const [email, setEmail] = useState("");

  const toast = useToast();
  const handleSendEmail = async () => {
    axios
      .post("/api/login", { email })
      .then((res) => {
        toast({
          title: "Email sent",
          description: "Check your email to login",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleChange = (e) => setEmail(e.target.value);

  return (
    <Flex
      minH={"94vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      style={{
        backgroundImage:
          "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        backgroundSize: "cover",
      }}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Transparencia blanca
            backdropFilter: "blur(10px)",
            marginTop: "-3rem",
          }}
        >
          <Stack align={"center"}>
            <Heading style={{ fontSize: "2rem", color: "white" }}>
              Sign in to your account
            </Heading>
          </Stack>
          <br />
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel style={{ color: "white" }}>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={handleChange}
                style={{ color: "white" }}
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleSendEmail}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
