import style from "./DeclarationCTA.module.css";

export const DeclarationCTA = () => {
  return (
    <div className={style.container}>
      <div className={style.declarationContainer}>
        <div className={style.declarationData}>
          <p className={style.declarationDataTitle}>
            declaration of independence
          </p>
          <p className={style.declarationDataDescription}>
            That whenever any Form of Government becomes destructive for these
            purposes, the people have the right to alter or abolish it.
          </p>
          <a href="#sign">Claim your right</a>
        </div>
        <img
          src="/assets/Thomas_Jefferson_image.webp"
          alt="Thomas Jefferson paint"
          className={style.jefferson}
        />
        <img src="/assets/stamp.webp" alt="US stamp" className={style.stamp} />
      </div>
    </div>
  );
};
