export const founders = [
  {
    delay: "10",
    imageUrl: "/assets/founding-fathers/1.webp",
    signImageUrl: "/assets/founding-fathers/WytheSign.webp",
    type: "founder",
  },
  {
    delay: "50",
    imageUrl: "/assets/founding-fathers/2.webp",
    signImageUrl: "/assets/founding-fathers/ShermanSign.webp",
    type: "founder",
  },
  {
    delay: "100",
    imageUrl: "/assets/founding-fathers/3.webp",
    signImageUrl: "/assets/founding-fathers/franklinSign.webp",
    type: "founder",
  },
  {
    delay: "150",
    imageUrl: "/assets/founding-fathers/you-image.webp",
    signImageUrl: "/assets/founding-fathers/YOU.webp",
    type: "you",
  },
  {
    delay: "200",
    imageUrl: "/assets/founding-fathers/4.webp",
    signImageUrl: "/assets/founding-fathers/JeffersonSign.webp",
    type: "founder",
  },
  {
    delay: "250",
    imageUrl: "/assets/founding-fathers/5.webp",
    signImageUrl: "/assets/founding-fathers/HancockSign.webp",
    type: "founder",
  },
];
