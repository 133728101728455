import style from "./BannerText.module.css";
export const BannerText = () => {
  return (
    <div className={style.bannerTextContainer}>
      <p>
        The Unanimous Declaration <span>of the thirteen unites</span> States of
        America
      </p>
    </div>
  );
};
