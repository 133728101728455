import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalClosebutton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  border,
} from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLocation } from "react-router-dom";
import "../styles/card.css";

const Card = ({ item }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpenMobile, onOpenMobile, onCloseMobile } = useDisclosure();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Define responsive styles
  const fontSize = useBreakpointValue({
    base: "md",
    md: "lg",
    lg: "xl",
    sm: "sm",
  });
  const fontSizeName = useBreakpointValue({
    base: "md",
    md: "lg",
    lg: "xl",
    sm: "sm",
  });
  const marginTop = useBreakpointValue({ base: "0", md: "6rem", sm: "2.5rem" });
  const marginLeft = useBreakpointValue({ base: "0", md: "5rem" });

  const width = useBreakpointValue({ base: "45vw", md: "840px", sm: "100vw" });
  // const height = useBreakpointValue({ base: "45vw", md: "840px", sm: "100vw" });

  const porTraitwidth = useBreakpointValue({
    base: "45vw",
    md: "55%",
    sm: "50%",
  });
  const porTraitheight = useBreakpointValue({
    base: "45vw",
    // md: "40%",
    sm: "50%",
  });

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const [heroName, setHeroName] = useState(""); // Removed the initialization from URL
  const [onLoadFired, setOnLoadFired] = useState(false); // New state to track onLoad
  const onLoadRef = useRef(false);

  useEffect(() => {
    // Only runs once when the component is mounted
    const params = new URLSearchParams(location.search);
    setHeroName(params.get("heroName") || "");
  }, []);

  useEffect(() => {
    if (heroName) {
      // console.log("heroName:", heroName);
      // console.log("item.Name:", item.Name);

      const hasOpenedBefore = localStorage.getItem(`opened-${item.Name}`);
      if (!hasOpenedBefore && heroName === item.Name) {
        // console.log("Opening modal for:", item.Name);
        setTimeout(() => {
          onOpen();
          localStorage.setItem(`opened-${item.Name}`, true);
        }, 100);
      }
    }
  }, [item.Name, heroName, onOpen]);

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      style={{
        marginBottom: "8rem",
        backgroundImage:
          "url(https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/signers/card-removebg-preview.png)",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        textAlign: "center",
        // height: "60vh",
        border: "0px",
        filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.3))",
        minWidth: "250px",
        minHeight: "450px",
      }}
    >
      <Button
        onClick={onOpen}
        style={{
          width: "100%",
          height: "100%",
          boxShadow: "none",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LazyLoadImage
          style={{
            width: "60%",
            margin: "0 auto",
            marginTop: "3rem",
            borderRadius: "4px",
            rotate: "3deg",
            minWidth: "211px",
            minHeight: "277px",

            maxWidth: "211px",
            maxHeight: "277px",
          }}
          effect="blur"
          src={item.img}
          // placeholderSrc={item.img}
        />
        <Image
          style={{
            width: "60%",
            // maxWidth:"190px",
            maxHeight: "65px",
            minHeight: "65px",
            // margin: "0 auto",
            marginTop: "1rem",
          }}
          src={item.signature}
          alt="signature"
        />

        <Box p="6" style={{ marginTop: "-20px", marginBottom: "10px" }}>
          <Box
            as="h4"
            lineHeight="tight"
            style={{
              fontFamily: "Gentium Plus",
              fontWeight: "bold",
              fontSize: fontSize,
            }}
          >
            {item.Name}
          </Box>
        </Box>
      </Button>

      {isMobile ? (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            className="img-paper"
            style={{
              backgroundImage:
                "url(https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/signers/card-removebg-preview.png)",
              backgroundRotate: "90deg",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundColor: "transparent",
              textAlign: "center",
              border: "0px",
              filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.3))",
              boxShadow: "0px 0px 0px #000",
              // backgroundColor:'red',
              width: "100vw",
              height: "fit-content",
              paddingBottom: "4rem",
              // marginTop: "5rem",
            }}
          >
              <Button
                className="custom-btn" tabindex="-1"
                sx={{
                  border: "none",
                  outline: "none", // Elimina el estilo de enfoque predeterminado
                  boxShadow: "none", // Elimina la sombra de enfoque predeterminada
                  // Otros estilos si los necesitas
                }}
                style={{
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  position: "relative",
                  zIndex: "9",
                  textAlign: "right",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  width: "100%",
                  margin: "0 auto",
                  marginTop: "1rem",
                  marginBottom: "-1rem",
                  fontSize: "1.3rem",
                }}
                mr={3}
                onClick={onClose}
              >
                X
              </Button>

            <div
              style={{
                width: "100%",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                style={{
                  width: "45vw",
                  height: "25vh",
                  margin: "0 auto",
                  marginTop: "3rem",
                  marginLeft: "0px",
                  marginRight: "0px",
                  marginBottom: "0px",
                  rotate: "3deg",
                }}
                src={item.img}
                alt="retrato"
                loading="lazy"
              />

              <div
                style={{ backgroundColor: "transparent", marginTop: "1.2rem" }}
              >
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: "1.4rem",
                    textAlign: "center",
                    marginBottom: "0.6rem",
                  }}
                >
                  {item.Name}
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "1.5rem",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "0.8rem",
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Born
                    </span>
                    <span>{item.Birth}</span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "0.8rem",
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Died
                    </span>
                    <span>{item.DeathDate}</span>
                  </div>
                </Box>

                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: fontSize,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "16px",
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Occupation
                      </span>
                      <span style={{ textAlign: "left" }}>
                        {item.Occupation}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1.5rem",
                        marginTop: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "0.8rem",
                          marginBottom: "-5px",
                          color: "#4A4137",
                          marginLeft: "-2.5rem",
                        }}
                      >
                        Birthplace
                      </span>
                      <span style={{ textAlign: "left" }}>
                        {item.Birthplace}
                      </span>
                    </div>
                  </div>
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: fontSize,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: fontSize,
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Sacrifice
                    </span>
                    <span
                      style={{
                        textAlign: "justify",
                        width: "auto",
                        fontSize: "16px",
                      }}
                    >
                      {item.Sacrifice}
                    </span>
                  </div>
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: fontSize,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "1.5rem",
                      marginTop: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "0.4rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "1rem",
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Death
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Death} yo
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "0.4rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: fontSize,
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Age in 1776
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Sign}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "0.4rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: fontSize,
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Marriages
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Marriages}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: fontSize,
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Children
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Children}
                      </span>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </ModalContent>
        </Modal>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            className="img-paper"
            style={{
              backgroundImage:
                "url(https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/signers/card-rot.png)",
              backgroundRotate: "90deg",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundColor: "transparent",
              textAlign: "center",
              border: "0px",
              filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.3))",
              boxShadow: "0px 0px 0px #000",
              // backgroundColor:'red',
              minWidth: width,
              // height: "60vh",
              paddingBottom: "3rem",
            }}
          >
            <Button
              className="custom-btn" tabindex="-1"
              sx={{
                border: "none",
                outline: "none", // Elimina el estilo de enfoque predeterminado
                boxShadow: "none", // Elimina la sombra de enfoque predeterminada
                // Otros estilos si los necesitas
              }}
              style={{
                backgroundColor: "transparent",
                fontWeight: "bold",
                position: "relative",
                zIndex: "9",
                textAlign: "right",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "100%",
                margin: "0 auto",
                marginTop: "1rem",
                marginBottom: "-1rem",
                fontSize: "1.3rem",
                paddingRight:'3rem'
              }}
              mr={3}
              onClick={onClose}
            >
              X
            </Button>
            <div
              style={{
                width: "100%",
                margin: "0 auto",
                display: "flex",
                marginTop: "-1.2rem",
              }}
            >
              <Image
                style={{
                  width: porTraitwidth,
                  height: porTraitheight,
                  maxWidth: "300px",
                  maxHeight: "350px",
                  margin: "0 auto",
                  marginTop: marginTop,
                  marginLeft: marginLeft,
                  marginBottom: "0px",
                  rotate: "3deg",
                  marginRight: "2rem",
                }}
                src={item.img}
                loading="lazy"
              />

              <div
                style={{ backgroundColor: "transparent", marginTop: marginTop }}
              >
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: "35px",
                    textAlign: "left",
                  }}
                >
                  {item.Name}
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: "20px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "1.5rem",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: fontSize,
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Born
                    </span>
                    <span>{item.Birth}</span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: fontSize,
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Died
                    </span>
                    <span>{item.DeathDate}</span>
                  </div>
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "1.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: fontSize,
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Birthplace
                    </span>
                    <span style={{ textAlign: "left" }}>{item.Birthplace}</span>
                  </div>
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: fontSize,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "1.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "16px",
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Occupation
                    </span>
                    <span style={{ textAlign: "left" }}>{item.Occupation}</span>
                  </div>
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: fontSize,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "1.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: fontSize,
                        marginBottom: "-5px",
                        color: "#4A4137",
                      }}
                    >
                      Sacrifice
                    </span>
                    <span
                      style={{
                        textAlign: "justify",
                        width: "auto",
                        fontSize: "16px",
                        marginRight: "2rem",
                      }}
                    >
                      {item.Sacrifice}
                    </span>
                  </div>
                </Box>
                <Box
                  as="h4"
                  lineHeight="tight"
                  style={{
                    fontFamily: "Gentium Plus",
                    fontWeight: "bold",
                    fontSize: fontSize,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "1.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1.5rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: fontSize,
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Death
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Death} yo
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1.5rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: fontSize,
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Age in 1776
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Sign}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1.5rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: fontSize,
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Marriages
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Marriages}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1.5rem",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: fontSize,
                          marginBottom: "-5px",
                          color: "#4A4137",
                        }}
                      >
                        Children
                      </span>
                      <span style={{ textAlign: "left", fontWeight: "600" }}>
                        {item.Children}
                      </span>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default Card;
