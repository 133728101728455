import { Box, Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import crypto from "crypto-js";

const Verify = ({ action }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email"));
  const [token, setToken] = useState(searchParams.get("token"));
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isMailingLogin, setIsMailingLogin] = useState(false);

  const verifyUser = async () => {
    try {
      if (action === 'mailinglogin') {
        setIsMailingLogin(true);
      }

      if (action !== 'mailinglogin') {
        setMessage("We are processing your verification request...");
      }
      const response = await axios.post(
        `/api/verify/${action}?email=${email}&token=${token}`,
        {
          token,
        }
      );
      console.log(response);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("signature", response.data.signature);
      localStorage.setItem("email", email);
      // trigger navigation to the home page on a 2 second timeout
      if (action !== 'mailinglogin') {
        setMessage(
          "Your verification was approved successfully, you are being redirected back to the website."
        );
      }

      if (action === 'mailinglogin') {
        // setTimeout(() => {
          window.location.href = "/select-size";
        // }, 1000);
      } else {
        setTimeout(() => {
          window.location.href = "/thanks";
        }, 2000);
       }
    } catch (error) {
      setError(true);
      if (error.response.data.error === "jwt expired") {
        setMessage(
          "Your email expired! Click below and we'll send you a new one!"
        );
      }
      // setMessage(error.response.data.error);
    }
  };

  const resendEmail = async () => {
    try {
      axios.post("/api/register", {
        email,
      });
      setMessage("We have sent you a new email, please check your inbox.");
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  useEffect(() => {
    verifyUser();
  }, []);

  return (
   <div>
    {!isMailingLogin &&     <Flex
      className="verify"
      align="center"
      justify="center"
      h="100vh"
      bgColor="#f5f5f5"
    >
      {error ? (
        <Flex
          flexDir="column"
          borderRadius={12}
          p={8}
          bgColor="#fff"
          w="50%"
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", color: "#333" }}
        >
          {message?.includes("expired") ? (
            <Box align="center" p={8}>
              <Text
                style={{
                  textAlign: "center",
                  marginBottom: "2rem",
                  color: "#333",
                  fontWeight: "600",
                }}
                fontSize="1.6rem"
              >
                {message}
              </Text>
              <Button
                bgColor="#000"
                onClick={resendEmail}
                style={{
                  textAlign: "center",
                  color: "white",
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                Click Here
              </Button>
            </Box>
          ) : (
            <Text
              style={{
                textAlign: "center",
                marginBottom: "2rem",
                color: "#333",
                fontWeight: "600",
              }}
              fontSize="1.6rem"
            >
              {message}
            </Text>
          )}
          {/* Try again. */}
          <Link to="/" align="center" bgColor="blue">
            <Box align="center">
              <Button style={{ color: "white", backgroundColor: "black" }}>
                Go Home
              </Button>
            </Box>
          </Link>
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          borderRadius={12}
          p={8}
          bgColor="#fff"
          w="50%"
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", color: "#333" }}
        >
          <Text
            style={{
              textAlign: "center",
              marginBottom: "2rem",
              color: "#333",
              fontWeight: "600",
            }}
            fontSize="1.6rem"
          >
            {message}
          </Text>
        </Flex>
      )}
    </Flex> }
    </div>
  );
};

export default Verify;
