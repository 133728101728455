import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../styles/Home.css";
import "../styles/size.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Heading, Text } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import TagManager from "react-gtm-module";

import isEmail from "validator/lib/isEmail";
import { Box, Divider, useToast } from "@chakra-ui/react";
import "../styles/signaturepage.css";
import SignaturePad from "signature_pad";

const ReachOutRS_Sign = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const token = localStorage.getItem("token") || null;
  const emailLogged = localStorage.getItem("email") || null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [pid, setPid] = useState(
    searchParams.get("pid") || localStorage.getItem("pid")
  );

  const [referralCode, setReferralCode] = useState(
    localStorage.getItem("referralCode") || searchParams.get("referralCode")
  );

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const fileTypes = ["JPG", "PNG", "JPEG", "HEIC"];
  const [showUpdateProfileForm, setShowUpdateProfileForm] = useState(false);

  const videoRef = useRef(null);
  const [videoPaused, setVideoPaused] = useState(false);

  const handleVideoToggle = () => {
    setVideoPaused(!videoPaused);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.log(error);
        // Autoplay was prevented, possibly due to browser policies
        // Handle the error or inform the user
      });
    }
  }, []);

  const svgWidths = ["25%", "35%", "50%"];
  const fontSizes = ["1.8rem", "2.5rem", "3.8rem"];
  const initState = {
    email: "",
    name: "",
    lastName: "",
    zipCode: "",
    ethnicity: "",
    occupation: "",
    religion: "",
    signature: "",
    media: "",
    svg: "",
  };
  const [pictureUser, setPictureUser] = useState(null);

  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [signature, setSignature] = useState("");
  const [email, setEmail] = useState("");
  const [showSignatureForm, setShowSignatureForm] = useState(true);
  const [form, setForm] = useState(initState);
  const [fileExtension, setFileExtension] = useState("");
  const [errorMainPhoto, setErrorMainPhoto] = useState(false);
  const [pictureUploaded, setPictureUploaded] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  const [activeTab, setActiveTab] = useState("TEXT");
  const [file, setFile] = useState(null);
  const [isFilesPopupOpen, setIsFilesPopupOpen] = useState(false);
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);

  //signature pad

  const [signatureDataURL, setSignatureDataURL] = useState("");
  const canvasRef = useRef();
  const signaturePadRef = useRef();
  const signatureRef = useRef();
  signatureRef.current = signatureDataURL;

  useEffect(() => {
    if (canvasRef.current && activeTab === "PEN") {
      canvasRef.current.width = canvasRef.current.offsetWidth;
      canvasRef.current.height = canvasRef.current.offsetHeight;
      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, [activeTab]);

  // Función para reiniciar la firma
  const handleResetSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const handleChangeFile = (file) => {
    if (!file) setFile(file);
    else {
      // Crear una nueva instancia de FileReader
      setFileExtension(file.name.match(/\.[0-9a-z]+$/i)[0]);
      const reader = new FileReader();
      // Función que se ejecuta cuando se completa la lectura del archivo
      reader.onloadend = () => {
        // Obtener el resultado de la lectura en Base64
        const base64Data = reader.result;

        // Puedes guardar el resultado en el estado o hacer algo con él
        setFile(base64Data);
      };

      // Leer el archivo como URL de datos (Base64)
      reader.readAsDataURL(file);
    }
  };

  // Grab UTM params

  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");

  useEffect(() => {
    if (emailLogged) {
      axios
        .get(`"/api/user?email=${emailLogged}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const usr = res.data;
            setForm({
              ...form,
              email: usr.email,
              name: usr.name,
              lastName: usr.lastName,
              zipCode: usr.zipCode,
              ethnicity: usr.ethnicity,
              occupation: usr.occupation,
              religion: usr.religion,
              signature: usr.signature.name,
              media: usr.signature.media ? usr.signature.media[0] : null,
              svg: usr.signature.svg ? usr.signature.svg : null,
              status: usr.signature.status,
            });
            setPictureUser(usr.signature.media ? usr.signature.media[0] : null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [emailLogged]);

  useEffect(() => {
    if (token) setShowSignatureForm(false);
  }, [token]);

  const handleSignatureChange = (event) => {
    setSignature(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEmail(email)) {
      toast({
        title: "Invalid email.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    //SIGNATURE PAD PART
    if (activeTab === "PEN" && signaturePadRef.current && isEmail(email)) {
      console.log("envio de la firma");

      const dataURL = signaturePadRef.current.toSVG();

      const tempElement = document.createElement("div");
      tempElement.setAttribute("style", "visibility: hidden");
      // getBBox() only works if the element is rendered into the page.
      document.body.appendChild(tempElement);
      tempElement.innerHTML = dataURL;
      const svgElement = tempElement.getElementsByTagName("svg")[0];
      // This gets the bounding box around the signature.
      const bbox = svgElement.getBBox();
      // Use the bounding box's coordinates as the svg's viewBox.
      // This eliminates the whitespace by "zooming in" on the
      // bounding box.  Include 5px extra, as the signature's
      // edges get slightly clipped otherwise.
      const viewBox = [
        bbox.x - 5,
        bbox.y - 5,
        bbox.width + 10,
        bbox.height + 10,
      ].join(" ");
      svgElement.setAttribute("viewBox", viewBox);
      // Let the svg have the size and aspect ratio of the
      // cropped result, not the original canvas.
      svgElement.removeAttribute("width");
      svgElement.removeAttribute("height");
      const croppedSVG = svgElement.outerHTML;
      document.body.removeChild(tempElement);

      setSignatureDataURL(croppedSVG);
      signatureRef.current = croppedSVG;
    }

    if (!signature.length > 0 && !signatureRef.current.length > 0) {
      toast({
        title: "Invalid signature.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    } else if (
      isEmail(email) &&
      (signature.length > 0 || signatureRef.current.length > 0)
    ) {
      axios
        .post("/api/register", {
          email: email,
          signature: {
            name: signature.length > 0 ? signature : null,
          },
          payload: file ? file : null,
          fileExtension: fileExtension ? fileExtension : null,
          svg: signatureRef.current.length > 0 ? signatureRef.current : null,
          pid: pid,
          utm: {
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
          },
          referralCode: referralCode,
        })
        .then((res) => {
          if (res.status === 200) {
            // toast({
            //   title: "Please check your email to verify your account!",
            //   status: "success",
            //   duration: 9000,
            //   isClosable: true,
            // });
            // navigate("/verify-email-page");
            TagManager.dataLayer({
              dataLayer: {
                event: "userSigned",
              },
            });
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("signature", res.data.signature);
            localStorage.setItem("email", email);
            navigate("/thanks");
          }
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: err.response
              ? err.response.data.error
              : "Error registering signature.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  useEffect(() => {
    // Cálculo del tiempo restante
    const countDownDate = new Date("July 4, 2024 00:00:00").getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDaysLeft(days);
      setHoursLeft(hours);
      setMinutesLeft(minutes);
      setSecondsLeft(seconds);
    }, 1000);
    return () => clearInterval(x);
  }, []);

  const validateForm = () => {
    return (
      !!form["email"] &&
      !!form["name"] &&
      !!form["lastName"] &&
      !!form["zipCode"]
    );
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    const valid = validateForm();
    const updates = {
      name: form["name"],
      lastName: form["lastName"],
      zipCode: form["zipCode"],
      ethnicity: form["ethnicity"],
      occupation: form["occupation"],
      religion: form["religion"],
    };
    if (valid) {
      axios
        .patch(
          "/api/user",
          {
            email: form["email"],
            updates,
            mediaUpdates: {
              payload: file ? file : null,
              fileExtension: fileExtension ? fileExtension : null,
            },
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toast({
              title: "Profile updated!",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: "Error updating profile.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const handleImageClick = () => {
    setIsFilesPopupOpen(true);
  };

  const logOut = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <section
      style={{
        width: "100vw",
        minHeight: "93.8vh",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
      }}
      className="sign-rs-div"
    >
      <Heading
        as="h1"
        fontSize="4xl"
        style={{
          color: "white",
          textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
          marginBottom: "1rem",
          marginTop: "2rem",
          textAlign: "center",
        }}
        className="title-sign-rs"
      >
        Roger Stone supports The DOI Project <br className="br" /> just like you
        do!
      </Heading>

      <div className="video-container">
        <video
          className="video"
          src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/BRAVE_CUT_v03.mp4"
          autoPlay
          loop
          playsInline
          controls // Show play/pause controls
          disablePictureInPicture // Disable picture-in-picture mode
          controlsList="nodownload noplaybackrate" // Disable download and playback speed controls
          disablefullscreen // Disable fullscreen mode
          ref={videoRef}
        />
      </div>

      <div
        id="down"
        className="div-first"
        style={{ textAlign: "center", marginTop: "-2rem" }}
      >
        <Text
          fontSize="xl"
          color={"white"}
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
          }}
          className="subrors"
        >
          Be Brave like Roger Stone and join the DOIP Team by signing The
          Declaration of Independence{" "}
        </Text>
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginBottom: "1rem",
            marginTop: "2rem",
            textAlign: "center",
          }}
          className="title-sign-rs"
        >
          Sign the Declaration of Independence
        </Heading>
        {/* SIGNATURE FORM */}
        {showSignatureForm && (
          <div className=" container-sign">
            <form onSubmit={handleSubmit} className="form2">
              <div
                className="tabs"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <button
                  type="button"
                  className={`tab ${activeTab === "TEXT" ? "active" : ""}`}
                  onClick={() => {
                    setActiveTab("TEXT");
                    setSignatureDataURL("");
                  }}
                  style={{
                    flex: 1,
                    padding: "10px",
                    background: activeTab === "TEXT" ? "#000" : "#f0f0f0",
                    color: activeTab === "TEXT" ? "#fff" : "#000",
                    border: "none",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    cursor: "pointer",
                    transition: "background 0.3s ease-in-out", // Agregar transición al color de fondo
                  }}
                >
                  TEXT
                </button>
                <button
                  type="button"
                  className={`tab ${activeTab === "PEN" ? "active" : ""}`}
                  onClick={() => {
                    setActiveTab("PEN");
                    setSignature("");
                  }}
                  style={{
                    flex: 1,
                    padding: "10px",
                    background: activeTab === "PEN" ? "#000" : "#f0f0f0",
                    color: activeTab === "PEN" ? "#fff" : "#000",
                    border: "none",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                    cursor: "pointer",
                    transition: "background 0.3s ease-in-out", // Agregar transición al color de fondo
                  }}
                >
                  PEN
                </button>
              </div>
              <br />
              {activeTab === "PEN" && (
                <div
                  className="canvas-container"
                  style={{ position: "relative" }}
                >
                  <canvas
                    style={{
                      border: "1px solid black",
                      borderRadius: "12px",
                      marginBottom: "10px",
                    }}
                    ref={canvasRef}
                    id="signature-pad"
                    className="signature-pad"
                    width={350}
                    height={190}
                  />
                  <button
                    type="button"
                    onClick={handleResetSignature}
                    style={{
                      position: "absolute",
                      color: "white",
                      borderRadius: "5px",
                      bottom: 20,
                      right: 10,
                      padding: "5px 10px",
                      backgroundColor: "black",
                      border: "1px solid #ccc",
                      cursor: "pointer",
                    }}
                  >
                    Clear
                  </button>
                </div>
              )}
              {activeTab === "TEXT" && (
                <>
                  <div className="signature-box">{signature}</div>
                  <div className="form-group">
                    <input
                      type="text"
                      id="signature"
                      name="signature"
                      value={signature}
                      onChange={handleSignatureChange}
                      autoComplete="off"
                      placeholder=" "
                      required={true}
                    />
                    <label htmlFor="name">Name on signature*</label>
                  </div>
                </>
              )}
              <br />
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder=" "
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="off"
                  required={true}
                />
                <label htmlFor="name">Email*</label>
              </div>
              <div style={{ marginTop: "1rem" }}>
                {!file && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  ></div>
                )}

                {file != null && (
                  <div
                    style={{
                      marginLeft: "10px",
                      margin: "0 auto",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FaCheckCircle style={{ color: "green" }} />
                      <b style={{ marginLeft: "3px" }}>Image uploaded!</b>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={file}
                        alt="userImage"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                        onClick={handleImageClick}
                        className="image"
                      />
                    </div>
                  </div>
                )}
              </div>
              <br />
              <div className="button-register">
                <button type="submit" className="button-register">
                  Sign
                </button>
              </div>
              <Link to="/login">
                <Box mt={4}>
                  <Text
                    fontSize="sm"
                    color="gray.800"
                    fontWeight={600}
                    cursor={"pointer"}
                    style={{
                      width: "100%",
                      padding: "15px",
                      textAlign: "justify",
                    }}
                  >
                    Already signed? Click here to log in and edit your profile.
                  </Text>
                </Box>
              </Link>
              <Link to="/resend">
                <Text
                  fontSize="sm"
                  color="gray.800"
                  fontWeight={600}
                  cursor={"pointer"}
                  mt={2}
                  style={{
                    width: "100%",
                    padding: "15px",
                    textAlign: "justify",
                  }}
                >
                  If you never recieved your confirmation email, click here to
                  retry.
                </Text>
              </Link>
            </form>
          </div>
        )}
      </div>
    </section>
  );
};

export default ReachOutRS_Sign;
