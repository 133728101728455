import React, { useState, useEffect, useRef, useCallback } from "react";
import isEmail from "validator/lib/isEmail";
import { Box, Divider, Text, useToast } from "@chakra-ui/react";
import Button from "@mui/material/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../styles/signaturepage.css";
import axios from "axios";
import SignaturePad from "signature_pad";
import Modal from "react-modal";
import { FileUploader } from "react-drag-drop-files";
import { FileOpen } from "@mui/icons-material";

import { FaCheckCircle, FaTrash } from "react-icons/fa";
import { BsUpload } from "react-icons/bs";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import crypto from "crypto-js";
import { Helmet } from "react-helmet";
import RedditPixel from "react-reddit-pixel";

// Establecer el elemento raíz para los modales
Modal.setAppElement("#root");

const SignatureCe = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = localStorage.getItem("token") || null;
  const emailLogged = localStorage.getItem("email") || null;
  const pid = localStorage.getItem("pid") || null;
  const [referralCode, setReferralCode] = useState(
    searchParams.get("referralCode") || localStorage.getItem("referralCode")
  );

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const fileTypes = ["JPG", "PNG", "JPEG"];
  const [showUpdateProfileForm, setShowUpdateProfileForm] = useState(false);

  const initState = {
    email: "",
    name: "",
    lastName: "",
    zipCode: "",
    ethnicity: "",
    occupation: "",
    religion: "",
    signature: "",
    media: "",
    svg: "",
  };
  const [pictureUser, setPictureUser] = useState(null);

  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [signature, setSignature] = useState("");
  const [email, setEmail] = useState(searchParams.get("email"));
  const [showSignatureForm, setShowSignatureForm] = useState(true);
  const [form, setForm] = useState(initState);
  const [fileExtension, setFileExtension] = useState("");
  const [errorMainPhoto, setErrorMainPhoto] = useState(false);
  const [pictureUploaded, setPictureUploaded] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  const [activeTab, setActiveTab] = useState("TEXT");
  const [file, setFile] = useState(null);
  const [isFilesPopupOpen, setIsFilesPopupOpen] = useState(false);
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);

  //signature pad

  const [signatureDataURL, setSignatureDataURL] = useState("");
  const canvasRef = useRef();
  const signaturePadRef = useRef();
  const signatureRef = useRef();
  signatureRef.current = signatureDataURL;

  useEffect(() => {
    if (canvasRef.current && activeTab === "PEN") {
      canvasRef.current.width = canvasRef.current.offsetWidth;
      canvasRef.current.height = canvasRef.current.offsetHeight;
      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, [activeTab]);

  // Función para reiniciar la firma
  const handleResetSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const handleChangeFile = (file) => {
    if (!file) setFile(file);
    else {
      // Crear una nueva instancia de FileReader
      setFileExtension(file.name.match(/\.[0-9a-z]+$/i)[0]);
      const reader = new FileReader();
      // Función que se ejecuta cuando se completa la lectura del archivo
      reader.onloadend = () => {
        // Obtener el resultado de la lectura en Base64
        const base64Data = reader.result;

        // Puedes guardar el resultado en el estado o hacer algo con él
        setFile(base64Data);
      };

      // Leer el archivo como URL de datos (Base64)
      reader.readAsDataURL(file);
    }
  };

  // Grab UTM params

  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");

  useEffect(() => {
    if (emailLogged) {
      axios
        .get(`/api/user?email=${emailLogged}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const usr = res.data;
            setForm({
              ...form,
              email: usr.email,
              name: usr.name,
              lastName: usr.lastName,
              zipCode: usr.zipCode,
              ethnicity: usr.ethnicity,
              occupation: usr.occupation,
              religion: usr.religion,
              signature: usr.signature.name,
              media: usr.signature.media ? usr.signature.media[0] : null,
              svg: usr.signature.svg ? usr.signature.svg : null,
            });
            setPictureUser(usr.signature.media ? usr.signature.media[0] : null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [emailLogged]);

  useEffect(() => {
    if (token) setShowSignatureForm(false);
  }, [token]);

  const handleSignatureChange = (event) => {
    setSignature(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEmail(email)) {
      toast({
        title: "Invalid email.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    //SIGNATURE PAD PART
    if (activeTab === "PEN" && signaturePadRef.current && isEmail(email)) {
      setIsRegistering(true);

      console.log("envio de la firma");

      const dataURL = signaturePadRef.current.toSVG();

      const tempElement = document.createElement("div");
      tempElement.setAttribute("style", "visibility: hidden");
      // getBBox() only works if the element is rendered into the page.
      document.body.appendChild(tempElement);
      tempElement.innerHTML = dataURL;
      const svgElement = tempElement.getElementsByTagName("svg")[0];
      // This gets the bounding box around the signature.
      const bbox = svgElement.getBBox();
      // Use the bounding box's coordinates as the svg's viewBox.
      // This eliminates the whitespace by "zooming in" on the
      // bounding box.  Include 5px extra, as the signature's
      // edges get slightly clipped otherwise.
      const viewBox = [
        bbox.x - 5,
        bbox.y - 5,
        bbox.width + 10,
        bbox.height + 10,
      ].join(" ");
      svgElement.setAttribute("viewBox", viewBox);
      // Let the svg have the size and aspect ratio of the
      // cropped result, not the original canvas.
      svgElement.removeAttribute("width");
      svgElement.removeAttribute("height");
      const croppedSVG = svgElement.outerHTML;
      document.body.removeChild(tempElement);

      setSignatureDataURL(croppedSVG);
      signatureRef.current = croppedSVG;
    }

    if (!signature.length > 0 && !signatureRef.current.length > 0) {
      toast({
        title: "Invalid signature.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    } else if (
      isEmail(email) &&
      (signature.length > 0 || signatureRef.current.length > 0)
    ) {
      axios
        .post("/api/register", {
          email: email,
          signature: {
            name: signature.length > 0 ? signature : null,
          },
          payload: file ? file : null,
          fileExtension: fileExtension ? fileExtension : null,
          svg: signatureRef.current.length > 0 ? signatureRef.current : null,
          pid: pid,
          utm: {
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
          },
          referralCode: referralCode,
        })
        .then((res) => {
          if (res.status === 200) {
            // toast({
            //   title: "Please check your email to verify your account!",
            //   status: "success",
            //   duration: 9000,
            //   isClosable: true,
            // });
            // navigate("/verify-email-ce", { state: { email } });
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("signature", res.data.signature);
            localStorage.setItem("email", email);
            navigate("/thanks", { state: { email } });
          }
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: err.response
              ? err.response.data.error
              : "Error registering signature.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  useEffect(() => {
    // Cálculo del tiempo restante
    const countDownDate = new Date("July 4, 2024 00:00:00").getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDaysLeft(days);
      setHoursLeft(hours);
      setMinutesLeft(minutes);
      setSecondsLeft(seconds);
    }, 1000);
    return () => clearInterval(x);
  }, []);

  const validateForm = () => {
    return (
      !!form["email"] &&
      !!form["name"] &&
      !!form["lastName"] &&
      !!form["zipCode"]
    );
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    const valid = validateForm();
    const updates = {
      name: form["name"],
      lastName: form["lastName"],
      zipCode: form["zipCode"],
      ethnicity: form["ethnicity"],
      occupation: form["occupation"],
      religion: form["religion"],
    };
    if (valid) {
      axios
        .patch(
          "/api/user",
          {
            email: form["email"],
            updates,
            mediaUpdates: {
              payload: file ? file : null,
              fileExtension: fileExtension ? fileExtension : null,
            },
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toast({
              title: "Profile updated!",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: "Error updating profile.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const handleImageClick = () => {
    setIsFilesPopupOpen(true);
  };

  const logOut = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <>
      {isRegistering && email ? (
        <Box zIndex={-99} w={"1px"} h={"1px"} position="absolute">
          <img src="https://tags.w55c.net/rs?id=3f42e0f52ac54e12a18f0bfdc495e733&t=signups" />
        </Box>
      ) : null}
      <section
        className="signature-section"
        style={{
          backgroundColor: "#fff",
          color: "#000",
          padding: "50px",
          minHeight: "93.8vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        {isFilesPopupOpen && (
          <Modal
            isOpen={isFilesPopupOpen}
            onRequestClose={() => setIsFilesPopupOpen(false)}
            className="modal modal-content"
            style={{
              content: {
                position: "relative",
                top: "15%",
                width: "400px",
                height: "fit-content",
                margin: "auto",
                padding: "30px",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f8f8f8",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>Upload image</h3>
              <button
                style={{ alignSelf: "flex-end" }}
                onClick={() => setIsFilesPopupOpen(false)}
              >
                X
              </button>
            </div>
            <div>
              <p>All images uploaded should be:</p>
              <ul>
                <li>Maximum file size: 5MB</li>
                <li>File type: PNG, JPG</li>
              </ul>
              <p>
                By uploading an image, you confirm it doesn't contain sexual
                content, violate laws, or infringe on rights. We reserve the
                right to remove violating images and take further action if
                necessary.
              </p>
            </div>
            <br></br>
            <div>
              <FileUploader
                handleChange={handleChangeFile}
                name="file"
                types={fileTypes}
                multiple={false}
                label={"Click here to upload or drop files"}
              />
            </div>
            <br></br>
            {file != null || pictureUser != null ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={file ? file : pictureUser}
                    alt="userImage"
                    style={{ maxWidth: "100%", maxHeight: "100px" }}
                  />
                  <button
                    style={{
                      borderRadius: "12px",
                      padding: "8px 15px",
                    }}
                    onClick={() => handleChangeFile(null)}
                  >
                    <FaTrash />
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "12px",
                      padding: "8px 15px",
                    }}
                    onClick={() => setIsFilesPopupOpen(false)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "12px",
                    padding: "8px 15px",
                  }}
                  onClick={() => setIsFilesPopupOpen(false)}
                >
                  Close
                </button>
              </div>
            )}
          </Modal>
        )}
        {isPreviewPopupOpen && (
          <Modal
            isOpen={isPreviewPopupOpen}
            onRequestClose={() => setIsPreviewPopupOpen(false)}
            className="modal modal-content"
            style={{
              content: {
                position: "relative",
                top: "15%",
                width: "400px",
                height: "fit-content",
                margin: "auto",
                padding: "30px",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f8f8f8",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                style={{ alignSelf: "flex-end" }}
                onClick={() => setIsPreviewPopupOpen(false)}
              >
                X
              </button>
            </div>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                textAlign: "center",
              }}
            >
              Preview
            </h3>

            <br />
            <div className="signaturePreview">
              {file ? (
                <img
                  style={{
                    borderRadius: "70px",
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    margin: "0 auto",
                  }}
                  src={file}
                  alt=""
                />
              ) : (
                <></>
              )}

              {signature ? (
                <h1
                  style={{
                    textAlign: "center",
                    fontFamily: "Beth Ellen",
                    marginTop: "1rem",
                    marginBottom: "2rem",
                    fontSize: "2rem",
                    userSelect: "none",
                    WebkitUserSelect: "none",
                  }}
                >
                  {signature}
                </h1>
              ) : (
                <img
                  style={{ height: "15vh", margin: " 0 auto" }}
                  src={signatureDataURL}
                  alt=""
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
                // marginTop: "20px",
              }}
            >
              <button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "12px",
                  padding: "8px 15px",
                }}
                onClick={() => setIsPreviewPopupOpen(false)}
              >
                Close
              </button>
            </div>
          </Modal>
        )}

        {/* TITLE */}
        {showSignatureForm ? (
          <h1
            className="title-page"
            style={{
              fontSize: "2.3rem",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
              marginBottom: "2rem",
              paddingTop: "2rem",
            }}
          >
            Sign the Declaration of Independence
          </h1>
        ) : (
          <h1
            className="title-page"
            style={{
              fontSize: "2.3rem",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
            }}
          >
            Update Profile
          </h1>
        )}

        {/* SIGNATURE FORM */}
        {showSignatureForm && (
          <div className=" container-sign">
            <form onSubmit={handleSubmit} className="form2">
              <div
                className="tabs"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <button
                  type="button"
                  className={`tab ${activeTab === "TEXT" ? "active" : ""}`}
                  onClick={() => {
                    setActiveTab("TEXT");
                    setSignatureDataURL("");
                  }}
                  style={{
                    flex: 1,
                    padding: "10px",
                    background: activeTab === "TEXT" ? "#000" : "#f0f0f0",
                    color: activeTab === "TEXT" ? "#fff" : "#000",
                    border: "none",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    cursor: "pointer",
                    transition: "background 0.3s ease-in-out", // Agregar transición al color de fondo
                  }}
                >
                  TEXT
                </button>
                <button
                  type="button"
                  className={`tab ${activeTab === "PEN" ? "active" : ""}`}
                  onClick={() => {
                    setActiveTab("PEN");
                    setSignature("");
                  }}
                  style={{
                    flex: 1,
                    padding: "10px",
                    background: activeTab === "PEN" ? "#000" : "#f0f0f0",
                    color: activeTab === "PEN" ? "#fff" : "#000",
                    border: "none",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                    cursor: "pointer",
                    transition: "background 0.3s ease-in-out", // Agregar transición al color de fondo
                  }}
                >
                  PEN
                </button>
              </div>
              <br />
              {activeTab === "PEN" && (
                <div
                  className="canvas-container"
                  style={{ position: "relative" }}
                >
                  <canvas
                    style={{
                      border: "1px solid black",
                      borderRadius: "12px",
                      marginBottom: "10px",
                    }}
                    ref={canvasRef}
                    id="signature-pad"
                    className="signature-pad"
                    width={350}
                    height={190}
                  />
                  <button
                    type="button"
                    onClick={handleResetSignature}
                    style={{
                      position: "absolute",
                      color: "white",
                      borderRadius: "5px",
                      bottom: 20,
                      right: 10,
                      padding: "5px 10px",
                      backgroundColor: "black",
                      border: "1px solid #ccc",
                      cursor: "pointer",
                    }}
                  >
                    Clear
                  </button>
                </div>
              )}
              {activeTab === "TEXT" && (
                <>
                  <div className="signature-box">{signature}</div>
                  <div className="form-group">
                    <input
                      type="text"
                      id="signature"
                      name="signature"
                      value={signature}
                      onChange={handleSignatureChange}
                      autoComplete="off"
                      placeholder=" "
                      required={true}
                    />
                    <label htmlFor="name">Name on signature*</label>
                  </div>
                </>
              )}
              <br />
              {/* DONT SHOW THE EMAIL */}
              {/* <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder=" "
                value={email}
                onChange={handleEmailChange}
                autoComplete="off"
                required={true}
              />
              <label htmlFor="name">Email*</label>
            </div> */}
              <div style={{ marginTop: "1rem" }}>
                {!file && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {/* <button
                      style={{
                        border: "2px solid black",
                        fontWeight: "600",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px 15px",
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        setIsFilesPopupOpen(true);
                      }}
                    >
                      {" "}
                      <BsUpload
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          marginRight: "10px",
                        }}
                      />
                      Upload image
                    </button> */}
                    {/* <p style={{ fontSize: "0.85rem", marginTop: "10px" }}>
                      Upload an image to accompany your signature!
                    </p> */}
                  </div>
                )}

                {file != null && (
                  <div
                    style={{
                      marginLeft: "10px",
                      margin: "0 auto",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FaCheckCircle style={{ color: "green" }} />
                      <b style={{ marginLeft: "3px" }}>Image uploaded!</b>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={file}
                        alt="userImage"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                        onClick={handleImageClick}
                        className="image"
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* <button
                className="button-register"
                style={{ marginTop: "2rem" }}
                onClick={(event) => {
                  event.preventDefault();
                  if (signaturePadRef.current && activeTab == "PEN") {
                    var croppedCanvas = document.createElement("canvas"),
                      croppedCtx = croppedCanvas.getContext("2d");

                    croppedCanvas.width = canvasRef.current.width;
                    croppedCanvas.height = canvasRef.current.height;
                    croppedCtx.drawImage(canvasRef.current, 0, 0);

                    // Next do the actual cropping
                    var w = croppedCanvas.width,
                      h = croppedCanvas.height,
                      pix = { x: [], y: [] },
                      imageData = croppedCtx.getImageData(
                        0,
                        0,
                        croppedCanvas.width,
                        croppedCanvas.height
                      ),
                      x,
                      y,
                      index;

                    for (y = 0; y < h; y++) {
                      for (x = 0; x < w; x++) {
                        index = (y * w + x) * 4;
                        if (imageData.data[index + 3] > 0) {
                          pix.x.push(x);
                          pix.y.push(y);
                        }
                      }
                    }
                    pix.x.sort(function (a, b) {
                      return a - b;
                    });
                    pix.y.sort(function (a, b) {
                      return a - b;
                    });
                    var n = pix.x.length - 1;

                    w = pix.x[n] - pix.x[0];
                    h = pix.y[n] - pix.y[0];
                    var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

                    croppedCanvas.width = w;
                    croppedCanvas.height = h;
                    croppedCtx.putImageData(cut, 0, 0);

                    const dataURL = croppedCanvas.toDataURL();
                    setSignatureDataURL(dataURL);
                    signatureRef.current = dataURL;
                  }

                  setIsPreviewPopupOpen(true);
                }}
              >
                Signature Preview
              </button> */}
              <br />
              <div className="button-register">
                <button type="submit" className="button-register">
                  Sign
                </button>
              </div>
              <Link to="/login">
                <Box mt={4}>
                  <Text
                    fontSize="sm"
                    color="gray.800"
                    fontWeight={600}
                    cursor={"pointer"}
                    style={{
                      width: "100%",
                      padding: "15px",
                      textAlign: "justify",
                    }}
                  >
                    Already signed? Click here to log in and edit your profile.
                  </Text>
                </Box>
              </Link>
              <Link to="/resend">
                <Text
                  fontSize="sm"
                  color="gray.800"
                  fontWeight={600}
                  cursor={"pointer"}
                  mt={2}
                  style={{
                    width: "100%",
                    padding: "15px",
                    textAlign: "justify",
                  }}
                >
                  If you never recieved your confirmation email, click here to
                  retry.
                </Text>
              </Link>
            </form>
          </div>
        )}

        {/* UPDATE PROFILE FORM */}
        <div className="container-1 container2">
          {!showSignatureForm && (
            <form onSubmit={handleUpdateUser} className=" form-update">
              <div className="posDiv">
                <div
                  class="form-group form-group2"
                  style={{ marginTop: "15px" }}
                >
                  <input
                    type="text"
                    id="email"
                    name="email"
                    disabled={true}
                    value={form.email}
                    required
                    onChange={(event) => {
                      setForm({ ...form, email: event.target.value });
                    }}
                    autoComplete="off"
                    placeholder=" "
                  />
                  <label for="name">Email*</label>
                </div>
                <br />
                <div class="form-group form-group2">
                  <input
                    type="name"
                    id="name"
                    required
                    value={form.name}
                    name="name"
                    placeholder=" "
                    onChange={(event) => {
                      setForm({ ...form, name: event.target.value });
                    }}
                    autoComplete="off"
                  />
                  <label for="name">Name*</label>
                </div>
                <br />
                <div class="form-group form-group2">
                  <input
                    type="lastName"
                    id="lastName"
                    name="lastName"
                    value={form.lastName}
                    required
                    placeholder=" "
                    onChange={(event) => {
                      setForm({ ...form, lastName: event.target.value });
                    }}
                    autoComplete="off"
                  />
                  <label for="name">Last Name*</label>
                </div>
                <br />
                <div class="form-group form-group2">
                  <input
                    type="zipCode"
                    id="zipCode"
                    required
                    name="zipCode"
                    value={form.zipCode}
                    placeholder=" "
                    onChange={(event) => {
                      setForm({ ...form, zipCode: event.target.value });
                    }}
                    autoComplete="off"
                  />
                  <label for="name">Zip Code*</label>
                </div>
                <br />
                <div class="form-group form-group2">
                  <input
                    type="ethnicity"
                    id="ethnicity"
                    name="ethnicity"
                    value={form.ethnicity}
                    placeholder=" "
                    onChange={(event) => {
                      setForm({ ...form, ethnicity: event.target.value });
                    }}
                    autoComplete="off"
                  />
                  <label for="name">Ethnicity</label>
                </div>
                <br />
                <div class="form-group form-group2">
                  <input
                    type="occupation"
                    id="occupation"
                    name="occupation"
                    placeholder=" "
                    value={form.occupation}
                    onChange={(event) => {
                      setForm({ ...form, occupation: event.target.value });
                    }}
                    autoComplete="off"
                  />
                  <label for="name">Occupation</label>
                </div>
                <br />
                <div class="form-group form-group2">
                  <input
                    type="religion"
                    id="religion"
                    name="religion"
                    value={form.religion}
                    placeholder=" "
                    onChange={(event) => {
                      setForm({ ...form, religion: event.target.value });
                    }}
                    autoComplete="off"
                  />
                  <label for="name">Religion</label>
                </div>
                <br />
                <div
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  {pictureUser && !file ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={pictureUser}
                        onClick={handleImageClick}
                        alt="userImage"
                        className="image"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </div>
                  ) : file ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {file != null && (
                        <div
                          style={{
                            marginLeft: "10px",
                            margin: "0 auto",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FaCheckCircle style={{ color: "green" }} />
                            <b style={{ marginLeft: "3px" }}>Image uploaded!</b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              src={file}
                              alt="userImage"
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                              onClick={handleImageClick}
                              className="image"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="button-container"
                      style={{ margin: "0 auto" }}
                    >
                      <button
                        style={{
                          border: "2px solid black",
                          fontWeight: "600",
                          borderRadius: "12px",
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 15px",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setIsFilesPopupOpen(true);
                        }}
                      >
                        {" "}
                        <BsUpload
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            marginRight: "10px",
                          }}
                        />
                        Upload image
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <br></br>
              {/* falta condicional de mostrar el svg o la firma escrita */}
              {form.svg ? (
                <div className="signature-box">
                  <ReactSVG
                    style={{ position: "relative", width: "75%" }}
                    src={form["svg"]}
                  />
                </div>
              ) : (
                <div className="signature-box">{form["signature"]}</div>
              )}
              <div className="register-button-container">
                <button className="button-register">Update my profile</button>
                <button
                  className="button-register"
                  onClick={() => window.history.back()}
                >
                  Go Back
                </button>
              </div>
              <button
                style={{ color: "#000", fontWeight: "600", marginTop: "5px" }}
                onClick={(event) => {
                  event.preventDefault();
                  logOut();
                }}
              >
                Do you want someone else to sign on this same device? Log Out to
                let them sign too!
              </button>
            </form>
          )}
        </div>

        {/* COUNTDOWN FORM */}
        {/* {showSignatureForm && (
          <div className="countdown-section2">
            <div className="countdown-container2">
              <div className="countdown-title2">Countdown to July 4th</div>
              <div className="countdown2">
                <div className="countdown-item2">
                  <div className="countdown-number2">{daysLeft}</div>
                  <div className="countdown-label2">DAYS</div>
                </div>
                <div className="countdown-item2">
                  <div className="countdown-number2">{hoursLeft}</div>
                  <div className="countdown-label2">HOURS</div>
                </div>
                <div className="countdown-item2">
                  <div className="countdown-number2">{minutesLeft}</div>
                  <div className="countdown-label2">MIN.</div>
                </div>
                <div className="countdown-item2">
                  <div className="countdown-number2">{secondsLeft}</div>
                  <div className="countdown-label2">SEC.</div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </section>
    </>
  );
};

export default SignatureCe;
