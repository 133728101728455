import style from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faXTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <div className={style.footerContainer}>
      <div className={style.footerTopContainer}>
        <a href="/">
          <img src="/assets/Doi_alternative.png" alt="doi logo" />
        </a>
        <div className={style.footerMediaContainer}>
          <a href="https://instagram.com/sign1776?igshid=OGQ5ZDc2ODk2ZA==">
            <FontAwesomeIcon style={{ height: "32px" }} icon={faInstagram} />
          </a>
          <a href="https://x.com/doiproject">
            <FontAwesomeIcon
              style={{
                height: "32px",
              }}
              icon={faXTwitter}
            />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100092544002652&mibextid=LQQJ4d">
            <FontAwesomeIcon style={{ height: "32px" }} icon={faFacebook} />
          </a>
        </div>
      </div>
      <div className={style.footerTermsContainer}>
        <div className={style.footerTermsLinks}>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms and Conditions</a>
          <a href="/contact">Contact Us</a>
        </div>
        <p>© 2023 Declaration of Independence Project. All Rights Reserved</p>
      </div>
    </div>
  );
};
