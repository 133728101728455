import React, { useState, useEffect } from "react";

import axios from "axios";

import "../styles/Home.css";
import "../styles/size.css";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";

import { ReactSVG } from "react-svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import TagManager from "react-gtm-module";

const Sizes = () => {
  const [activeIndex, setActiveIndex] = useState(2);

  const navigate = useNavigate();

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const purchaseOptions = [
    { item: "small_signature", value: 3 },
    { item: "medium_signature", value: 13 },
    { item: "hancock_signature", value: 17.76 },
  ];

  const handlePurchase = () => {
    if (activeIndex !== null) {
      // Navegar a la URL correspondiente según el índice activo
      axios
        .post("/api/generate-payment-link", {
          email: localStorage.getItem("email"),
          item: purchaseOptions[activeIndex].item,
        })
        .then((res) => {
          // TagManager.dataLayer({
          //   dataLayer: {
          //     event: "itemSold",
          //     value: purchaseOptions[activeIndex].value,
          //     currency: "USD",
          //   },
          // });
          // redirect to the URL from the response
          window.location.href = res.data;
        });
    }
  };

  const emailLogged = localStorage.getItem("email") || null;

  const [signature, setSignature] = useState("");

  const [svg, setSvg] = useState("");

  const token = localStorage.getItem("token") || null;
  let [searchParams, setSearchParams] = useSearchParams();

  const [bought, setBought] = useState(false);

  useEffect(() => {
    axios.get(`/api/user/orders?email=${emailLogged}`).then((res) => {
      console.log(res.data);
      if (res.data.boughtSignature == true) {
        setBought(true);
      }
    });
  }, []);

  useEffect(() => {
    if (emailLogged) {
      axios
        .get(`/api/user?email=${emailLogged}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const usr = res.data;

            setSignature(usr.signature.name);
            setSvg(usr.signature.svg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  }, []);

  const svgWidths = ["17%", "20%", "26%"];
  const fontSizes = ["1.8rem", "2.5rem", "3.8rem"];

  return (
    <section
      style={{
        // width: "100vw",
        minHeight: "93.8vh",
        height: "100vh",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        top: "0px",
        position: "relative",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/reunion.webp')",
      }}
      className="sec-size"
    >
      <button onClick={() => window.history.back()}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="is-mobile"
          style={{
            marginLeft: "1rem",
            position: "absolute",
            top: "50px",
            left: "10px",
            color: "white",
            height: "25px",
            marginBottom: "1rem",
            pointerEvents: "pointer",
            zIndex: "100",
          }}
        />
      </button>
      <div
        className="div-first2"
        style={{
          textAlign: "center",
          // marginTop: "-3rem",
        }}
      >
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
          }}
          className="title-size is-desktop"
        >
          Select a size for your physical Signature
        </Heading>
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
          }}
          className="title-size is-mobile"
        >
          Select a size for your physical Signature
        </Heading>
        <Text
          fontSize="lg"
          color={"white"}
          style={{
            marginBottom: "0rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
          }}
          className="sub-size"
        >
          Add your name to the physical Declaration of Independence that will be
          presented to Congress.
        </Text>
      </div>
      <div className="reachout">
        <div className="container container2">
          <div className="signature-overlay">
            {activeIndex >= 0 && activeIndex <= 2 && (
              <div className="preview-img2" style={{ position: "relative" }}>
                <img
                  className="preview-img2"
                  src="\assets\signature-preview_hancock.png"
                  alt=""
                />

                {svg ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "65%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: svgWidths[activeIndex],
                      maxWidth: "70vw !important",
                    }}
                  >
                    <ReactSVG src={svg} />
                  </div>
                ) : (
                  <h1
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "65%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontFamily: "Beth Ellen",
                      fontSize: fontSizes[activeIndex],
                      userSelect: "none",
                      WebkitUserSelect: "none",
                    }}
                    className={`size${activeIndex + 1}`}
                  >
                    {signature}
                  </h1>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="container container2">
          <button
            className={`square2 ${activeIndex === 0 ? "active2" : ""}`}
            onClick={() => handleClick(0)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $3{" "}
            </h1>
            <h1 className="text">Percenters</h1>

            <h1 className="sub">Small Signature</h1>
          </button>
          <button
            className={`square2 ${activeIndex === 1 ? "active2" : ""}`}
            onClick={() => handleClick(1)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $13{" "}
            </h1>
            <h1 className="text">Colonies</h1>

            <h1 className="sub">Medium Signature</h1>
          </button>
          <button
            className={`square2 ${activeIndex === 2 ? "active2" : ""}`}
            onClick={() => handleClick(2)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span
              className="most-popular"
              style={{
                position: "absolute",
                top: "-5px",
                backgroundColor: "#b22234",
                width: "101%",
                borderRadius: "12px 12px 0px 0px",
                textAlign: "center",
              }}
            >
              {" "}
              Most Popular
            </span>
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $17.76{" "}
            </h1>
            <h1 className="text">Founders</h1>

            <h1 className="sub">John Hancock Sized</h1>
          </button>
        </div>
      </div>

      <div className="container3">
        <div className="button-container2">
          {bought ? (
            <button
              style={{
                backgroundColor: "#b22234",
                borderColor: "#b22234",
                cursor: "default",
              }}
              className="purchase-button2"
              onClick={handlePurchase}
              disabled={bought}
            >
              <div>
                <span>
                  You already paid for your Signature. Thanks for your support!
                </span>
              </div>
            </button>
          ) : (
            <button
              style={{
                backgroundColor: bought ? "gray" : "#b22234",
                borderColor: bought ? "gray" : "#b22234",
              }}
              className="purchase-button3"
              onClick={handlePurchase}
              disabled={bought}
            >
              <div>
                <span>Purchase Now</span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginLeft: "1rem" }}
                />
              </div>
              <div>
                {activeIndex === 0 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x Small signature
                  </div>
                )}
                {activeIndex === 1 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x Medium signature
                  </div>
                )}
                {activeIndex === 2 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x John Hancock sized signature
                  </div>
                )}
              </div>
            </button>
          )}

          {bought ? (
            <a href="/shop" style={{ marginRight: "10px" }}>
              <button
                className="cancel-button2"
                // onClick={handleCancel} // Función de manejo de clic en cancelar
              >
                Take me to the Shop!
              </button>
            </a>
          ) : (
            <button
              href="/declaration"
              style={{ marginRight: "10px" }}
              onClick={() => window.history.back()}
            >
              <button
                className="cancel-button2"
                // onClick={handleCancel} // Función de manejo de clic en cancelar
              >
                Go back
              </button>
            </button>
          )}
        </div>
      </div>

      {/* <div className="button-container" style={{ minWidth: "99vw" }}>
        <a href="/thanks" style={{ marginRight: "10px" }}>
          <button
            className="cancel-button"
            // onClick={handleCancel} // Función de manejo de clic en cancelar
          >
            Cancel
          </button>
        </a>

        <button
          style={{
            backgroundColor: bought ? "gray" : "#b22234",
            borderColor: bought ? "gray" : "#b22234",
          }}
          className="purchase-button"
          onClick={handlePurchase}
          disabled={bought}
        >
          Purchase now
        </button>
      </div> */}
    </section>
  );
};

export default Sizes;
