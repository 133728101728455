import { Flex, Text, position, useToast } from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "../styles/DoiAtlas.css";
import AtlasBox from "./DoiBox";
import axios from "axios";
import { Textsms } from "@mui/icons-material";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faUniversalAccess,
} from "@fortawesome/free-solid-svg-icons";

function DoiAtlas(props) {
  const [hoverRow, setHoverRow] = useState(0);
  const [showInput, setShowInput] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedRow, setSelectedRow] = useState(0);
  const [gridRows, setGridRows] = useState(300);
  const [gridColumns, setGridColumns] = useState(300);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [permaColumn, setPermaColumn] = useState(0);
  const [permaRow, setPermaRow] = useState(0);
  const [animationRow, setAnimationRow] = useState(0);
  const [animationColumn, setAnimationColumn] = useState(0);
  const [animationMatrix, setAnimationMatrix] = useState(0);
  const [hoverColumn, setHoverColumn] = useState(0);
  const [hasSelected, setHasSelected] = useState(false); //toggles between different components
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [imgLoaded, setImgLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState("");
  const [matrix, setMatrix] = useState(null);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [signatureGrid, setSignatureGrid] = useState([]);
  const imgRef = useRef(null);
  const toast = useToast();
  const [shiftDirection, setShiftDirection] = useState("");
  const [translateTime, setTranslateTime] = useState(0.8);
  const [searchName, setSearchName] = useState("");
  const [userName, setUserName] = useState("");
  const [textReadable, setTextReadable] = useState(false);
  const [bought, setBought] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  //default size if not defined
  if (props?.gridRows) {
    setGridRows(props.gridRows);
  }
  if (props?.gridColumns) {
    setGridColumns(props.gridColumns);
  }

  useEffect(() => {
    // setLoggedIn(true);

    let token = localStorage.getItem("token");
    let tempLoggedIn = false;
    if (token) {
      setLoggedIn(true);
      tempLoggedIn = true;
      let email = localStorage.getItem("email");

      axios.get(`/api/user/orders?email=${email}`).then((res) => {
        console.log(res.data);
        //VOLVER A CAMBIAR ACA
        if (res.data.boughtSignature == true) {
          setBought(true);
        }
      });
    }
  }, []);

  //if user is loggedIn set variables to find them
  useEffect(() => {
    let token = localStorage.getItem("token");
    let tempLoggedIn = false;
    if (token) {
      setLoggedIn(true);
      tempLoggedIn = true;
    }

    if (tempLoggedIn) {
      let email = localStorage.getItem("email");
      axios
        .get(`/api/user?email=${email}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setPermaColumn(res.data.signature.location.column);
          setSelectedColumn(res.data.signature.location.column);
          setSelectedRow(res.data.signature.location.row);
          setPermaRow(res.data.signature.location.row);
          setSearchName(
            res.data.signature.name
              ? res.data.signature.name
              : res.data.signature.svg
          );
          setUserName(
            res.data.signature.name
              ? res.data.signature.name
              : res.data.signature.svg
          );
        });
      // .catch((err) => {
      //   toast({
      //     title: "Error",
      //     description: "User email not found in declaration",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // });
    }
  }, []);

  //calculate where in the matrix the user is hovering
  useEffect(() => {
    let box = document.querySelector(".doi");
    let width = box.offsetWidth;
    let height = box.offsetHeight;

    if (mousePosition.y > 3 && mousePosition.x > 3) {
      let row = (mousePosition.y / height) * gridRows;
      let column = (mousePosition.x / width) * gridColumns;
      setHoverRow(Math.floor(row));
      setHoverColumn(Math.floor(column));
    }
    // console.log(mousePosition.y, height);
  }, [mousePosition]);

  //get the submatrix for the selected row and column
  useEffect(() => {
    if (hasSelected) {
      axios
        .post("/api/matrix", {
          location: { row: selectedRow, column: selectedColumn },
        })
        .then((res) => {
          setMatrix(res.data);
        });
    }
  }, [selectedRow, selectedColumn, hasSelected]);

  useEffect(() => {
    let box = document.querySelector(".doi");
    let width = box.offsetWidth;
    let height = box.offsetHeight;

    if (mousePosition.y > 3 && mousePosition.x > 3) {
      let row = (mousePosition.y / height) * gridRows;
      let column = (mousePosition.x / width) * gridColumns;
      setHoverRow(Math.floor(row));
      setHoverColumn(Math.floor(column));
    }
    // console.log(mousePosition.y, height);
  }, [mousePosition]);

  //get the submatrix for the next row and column after navigating
  useEffect(() => {
    if (hasSelected) {
      axios
        .post("/api/matrix", {
          location: { row: animationRow, column: animationColumn },
        })
        .then((res) => {
          setAnimationMatrix(res.data);
        });
    }
  }, [animationRow, animationColumn]);

  //scroll the window to the searchName for findmysignature or findsomeone
  useEffect(() => {
    let element = document.getElementById(searchName);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      // console.log(y);
      const yOffset = window.innerHeight / 2 - element.offsetHeight / 2;
      window.scrollTo({ top: y - yOffset, behavior: "smooth" });
    }
  }, [signatureGrid]);

  //fill signatureGrid with black filter elements
  useEffect(() => {
    if (matrix) {
      console.log(matrix);
      let temporaryMatrix = [];
      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 12; j++) {
          let id = "filter" + (i * 12 + j);
          temporaryMatrix.push(
            <div
              id={`${id}`}
              key={`${id}`}
              className="emptyBox"
              style={{
                gridColumn: `${i + 1}`,
                gridRow: `${j + 1}`,
                backgroundColor: "black",
                opacity: "0.3",
              }}
            ></div>
          );
        }
      }
      //replace corresponding filter element with doibox element for every user
      for (let [index, userObj] of matrix.entries()) {
        if (typeof userObj.signature == "object") {
          temporaryMatrix.splice(
            userObj.signature.location.innerColumn * 12 +
              userObj.signature.location.innerRow,
            1,
            AtlasBox({
              signature: userObj.signature,
              key: index,
            })
          );
        } else if (typeof userObj.name == "string") {
          temporaryMatrix.splice(
            userObj.location.column * 12 + userObj.location.row,
            1,
            <div
              id={userObj.name}
              key={`${userObj.name}`}
              className="doi-box"
              style={{
                gridColumn: `${userObj.location.column + 1}`,
                gridRow: `${userObj.location.row + 1}`,
              }}
            >
              {userObj.name}
            </div>
          );
        }
      }
      setSignatureGrid(temporaryMatrix);
    }
  }, [hasSelected, selectedRow, selectedColumn, matrix]);

  useEffect(() => {
    let temporaryMatrix = [];
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 12; j++) {
        let id = "filter" + (i * 12 + j);
        temporaryMatrix.push(
          <div
            id={`${id}`}
            key={`${id}`}
            className="emptyBox"
            style={{
              gridColumn: `${i + 1}`,
              gridRow: `${j + 1}`,
              backgroundColor: "black",
              opacity: "0.3",
            }}
          ></div>
        );
      }
    }
    setAnimationMatrix(temporaryMatrix);
  }, []);

  const selectionGridStyle = {
    gridColumn: "1",
    gridRow: "1",
    backgroundColor: "transparent",
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
  };

  const signatureGridStyle = {
    gridColumn: "1",
    gridRow: "1",
    display: "grid",
    textalign: "center",
    verticalalign: "middle",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridTemplateRows: "repeat(12, 1fr)",
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
    transform: `translate(${translateX}%, ${translateY}%)`,
    transition: `transform ${translateTime}s`,
  };
  const animationGridLeft = {
    gridColumn: `1`,
    gridRow: `1`,
    left: "100%",
    transform: `translate(${translateX}%, ${translateY}%)`,
    transition: `transform ${translateTime}s`,
  };
  const animationGridRight = {
    gridColumn: `1`,
    gridRow: `1`,
    right: "100%",
    transform: `translate(${translateX}%, ${translateY}%)`,
    transition: `transform ${translateTime}s`,
  };
  const animationGridTop = {
    gridColumn: `1`,
    gridRow: `1`,
    top: "100%",
    transform: `translate(${translateX}%, ${translateY}%)`,
    transition: `transform ${translateTime}s`,
  };
  const animationGridBottom = {
    gridColumn: `1`,
    gridRow: `1`,
    bottom: "100%",
    transform: `translate(${translateX}%, ${translateY}%)`,
    transition: `transform ${translateTime}s`,
  };

  const imageStyle = {
    gridRow: "1",
    gridColumn: "1",
    position: "relative",
    transformOrigin: `${selectedColumn / 2.99}% ${selectedRow / 2.99}%`,
    overflow: "invisible",
    width: "100%",

    zIndex: "",
    padding: "0px",
    //transition:"transform-origin 0.5s",
  };

  const filterStyle = {
    backgroundImage: "url(https://sign1776.com/api/filter)",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "100%",
    gridRow: "1",
    gridColumn: "1",
    imageRendering: "pixelated",
    position: "relative",
    opacity: "0.3",
    transformOrigin: `${selectedColumn / 2.99}% ${selectedRow / 2.99}%`,
    overflow: "invisible",
    // width: "100%",

    zIndex: "1",
    padding: "0px",
  };

  //set coordinates and toggle hasSelected
  function handleOptionClick(e) {
    let box = document.querySelector(".doi");
    let width = box.offsetWidth;
    let height = box.offsetHeight;
    console.log(e.nativeEvent.offsetX, e.nativeEvent.offsetY);

    let row = (e.nativeEvent.offsetY / height) * gridRows;
    let column = (e.nativeEvent.offsetX / width) * gridColumns;
    setHoverRow(Math.floor(row));
    setHoverColumn(Math.floor(column));
    console.log(Math.floor(row), Math.floor(column));

    setSelectedColumn(Math.floor(column));
    setSelectedRow(Math.floor(row));
    setHasSelected(true);
  }

  const handleToggleText = (nextChecked) => {
    setTextReadable(nextChecked);
  };
  const handleEnterKeyField = (event) => {
    if (event.key === "Enter") {
      findSomeone();
    }
  };

  function handleFindMySignature() {
    let token = localStorage.getItem("token");

    let email = localStorage.getItem("email");
    axios
      .get(`/api/user?email=${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.signature);
        setSelectedColumn(res.data.signature.location.column);
        setSelectedRow(res.data.signature.location.row);
        // setSearchName(
        //   res.data.signature.name
        //     ? res.data.signature.name
        //     : res.data.signature.svg
        // );
        // setUserName(
        //   res.data.signature.name
        //     ? res.data.signature.name
        //     : res.data.signature.svg
        // );
        setHasSelected(true);
      });
    // setSearchName(userName);
    // setSelectedColumn(permaColumn);
    // setSelectedRow(permaRow);
  }

  function handleMouseMove(e) {
    if (e.nativeEvent.offsetX > 0 && e.nativeEvent.offsetY > 0) {
      setMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    }
  }

  function returnToSel() {
    setHasSelected(false);
  }

  function handleImageLoad() {
    setImgLoaded(true);
  }

  function moveComponent(direction) {
    const amount = 100; // adjust this value to change the distance moved
    var vertical;
    var horizontal;
    switch (direction) {
      case "left":
        setTranslateX(amount);
        setTranslateY(0);
        setAnimationColumn(selectedColumn - 1);
        setAnimationRow(selectedRow);
        break;
      case "right":
        setTranslateX(-amount);
        setTranslateY(0);
        setAnimationColumn(selectedColumn + 1);
        setAnimationRow(selectedRow);
        break;
      case "up":
        setTranslateY(amount);
        setTranslateX(0);
        setAnimationRow(selectedRow - 1);
        setAnimationColumn(selectedColumn);
        break;
      case "down":
        setTranslateY(-amount);
        setTranslateX(0);
        setAnimationRow(selectedRow + 1);
        setAnimationColumn(selectedColumn);
        break;
      default:
        break;
    }
  }

  function handleTransitionEnd() {
    // Set transition to none to prevent snap back from animating
    setTranslateTime(0);
    setSelectedColumn(animationColumn);
    setSelectedRow(animationRow);
    setTimeout(() => {
      setTranslateX(0);
      setTranslateY(0);
    }, 400);
    // Reset translateX and translateY

    // Set transition back to its original value after a brief delay
    setTimeout(() => {
      setTranslateTime(0.8);
    }, 450);
  }

  function findSomeone() {
    axios
      .get(`/api/user/signature?email=${email}`)
      .then((res) => {
        setSelectedColumn(res.data.signature.location.column);
        setSelectedRow(res.data.signature.location.row);
        setSearchName(
          res.data.signature.name
            ? res.data.signature.name
            : res.data.signature.svg
        );
        setHasSelected(true);
      })
      .catch((err) => {
        console.log("error", err);
        if (err.response.status === 404) {
          toast({
            title: "Not found",
            description: "We couldn't find a signature linked to that address.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        } else if (err.response.status === 429) {
          toast({
            title: "Error",
            description: "Please wait a minute before searching again.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "Something went wrong. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
  }

  const handleClick = () => {
    setShowInput(!showInput);
  };

  const [showAccess, setShowAccess] = useState(false);

  const showButtons = () => {
    // console.log("hola");
    setShowAccess(!showAccess);
  };

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        width: "100%",
      }}
    >
      {hasSelected && (
        <button className="floating-button" onClick={returnToSel}>
          Go back
        </button>
      )}
      {hasSelected && (
        <div
          style={{
            backgroundColor: "white",
            opacity: "1",
            bottom: "80px",
            right: "20px",
            position: "fixed",
            width: "80px",
            height: "80px",
            borderRadius: "20px",
            zIndex: "4",
          }}
        >
          <i
            class="arrow"
            title="arrow icon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "0%",
              rotate: "270deg",
              zIndex: "3",
            }}
            onClick={() => moveComponent("up")}
          ></i>
          <i
            class="arrow"
            title="arrow icon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "0%",
              bottom: "50%",
              rotate: "180deg",
              zIndex: "8",
            }}
            onClick={() => moveComponent("left")}
          ></i>
          <i
            class="arrow"
            title="arrow icon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: "0%",
              top: "50%",
              rotate: "0",
              zIndex: "3",
            }}
            onClick={() => moveComponent("right")}
          ></i>

          <i
            class="arrow"
            title="arrow icon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: "50%",
              bottom: "0%",
              rotate: "90deg",
              zIndex: "3",
            }}
            onClick={() => moveComponent("down")}
          ></i>
        </div>
      )}
      {!hasSelected && !loggedIn && (
        <Flex //top bar buttons
          align="center"
          justify="center"
          fontWeight={300}
          fontSize="lg"
          position="relative"
          w="100%"
          style={{
            backgroundColor: "rgba(0, 0, 0)",
            margin: "0 auto",
            paddingTop: "20px",
            paddingBottom: "10px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ position: "relative", left: "" }}>
            <div
              onClick={() => {
                if (!showInput) {
                  setShowInput(!showInput);
                }
              }}
              className="button header"
              style={{
                backgroundColor: "rgb(44, 76, 112)",
                color: "#fff",
                padding: "0px",
                borderRadius: "12px",
                border: "none",
                fontWeight: "bold",
                position: "relative",
                cursor: "pointer",
              }}
            >
              {!showInput && (
                <div style={{ padding: "8px 20px" }}>
                  Find Someone’s Signature
                </div>
              )}
              {showInput && (
                <>
                  <input
                    className="non-floating-button btn-header-find"
                    style={{
                      margin: "0px",
                      left: "5px",
                      height: "90%",
                      fontWeight: "bold",
                      color: "rgb(255,255,255)",
                      backgroundColor: "rgba(0,0,0,0)",
                    }}
                    type="email"
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleEnterKeyField}
                  />
                  <button
                    className="non-floating-button"
                    position="absolute"
                    style={{ right: "0px" }}
                    onClick={() => {
                      findSomeone();
                      setShowInput(!showInput);
                    }}
                  >
                    Search
                  </button>
                </>
              )}
            </div>
            {/* Muestra el input según el valor de showInput */}
          </div>

          {/* <audio ref={audioRef} src="/assets/dec_audio.mp3" />
          <button className="play-pause-button" onClick={togglePlay}style={{marginRight:"3vw",position:"absolute",right:"80px"}}>
             {isPlaying ? (
             <>
               <FontAwesomeIcon icon={faPause} /> Pause
             </>
             ) : (
             <>
               <FontAwesomeIcon icon={faPlay} /> Play
              </>
             )}
          </button>
          <div style={{marginRight:"3vw",position:"absolute",right:"0px"}}>
          <Switch
            onChange={handleToggleText}
              checked={textReadable}
              onColor="#DDD49E"
              className="react-switch"
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 22,
                    fontFamily: "Allura",
                    marginTop: "-15",
                    paddingTop: 6,
                    paddingRight: 4,
                  }}
                >
                  A
                </div>}
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 18,
                    paddingBottom: 1,
                    paddingLeft: 6,
                  }}
                >
                  A
            </div>}
          />
          </div> */}
          <a href="/sign">
            <button
              className="button header"
              style={{
                backgroundColor: "rgb(178, 34, 52)",
                color: "#fff",
                padding: "8px 20px",
                borderRadius: "12px",
                border: "none",
                fontWeight: "bold",

                marginLeft: "15px",
              }}
            >
              Sign Declaration
            </button>
          </a>
        </Flex>
      )}

      {!hasSelected && (
        <div
          className="accesibility"
          style={{
            position: "fixed",
            zIndex: "999",
            bottom: "20px",
            left: "20px",
          }}
        >
          {showAccess && (
            <div
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
                position: "fixed",
                bottom: "11%",
                left: "1%",
                zIndex: "9",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <audio ref={audioRef} src="/assets/dec_audio.mp3" />
              <button
                className="play-pause-button"
                onClick={togglePlay}
                style={{ marginTop: "1rem" }}
              >
                {isPlaying ? (
                  <>
                    <FontAwesomeIcon
                      style={{ color: "black" }}
                      icon={faPause}
                    />{" "}
                    <span style={{ color: "black" }}>Pause</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon style={{ color: "black" }} icon={faPlay} />{" "}
                    <span style={{ color: "black" }}>Play</span>
                  </>
                )}
              </button>
              <div>
                <Switch
                  onChange={handleToggleText}
                  checked={textReadable}
                  onColor="#DDD49E"
                  className="react-switch"
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 22,
                        fontFamily: "Allura",
                        marginTop: "-15",
                        paddingTop: 6,
                        paddingRight: 4,
                      }}
                    >
                      A
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 18,
                        paddingBottom: 1,
                        paddingLeft: 6,
                      }}
                    >
                      A
                    </div>
                  }
                />
              </div>
            </div>
          )}
          <FontAwesomeIcon
            icon={faUniversalAccess}
            style={{ height: "65px", color: "black", cursor: "pointer" }}
            onClick={showButtons}
          ></FontAwesomeIcon>
        </div>
      )}

      {loggedIn && (
        <Flex
          align="center"
          justify="center"
          fontWeight={300}
          fontSize="lg"
          position="relative"
          w="100%"
          paddingBottom={"3"}
          style={{
            backgroundColor: "rgba(0, 0, 0)",
            margin: "0 auto",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="button header find"
            onClick={() => {
              if (!showInput) {
                setShowInput(!showInput);
              }
            }}
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "0px",
              borderRadius: "12px",
              border: "none",
              fontWeight: "bold",
              position: "relative",
              marginTop: "15px",
              marginLeft: "15px",
            }}
          >
            {!showInput && (
              <div style={{ padding: "8px 20px",cursor:"pointer" }}>
                Find Someone’s Signature
              </div>
            )}
            {showInput && (
              <>
                <input
                  className="non-floating-button"
                  style={{
                    margin: "0px",
                    left: "5px",
                    height: "90%",
                    cursor:"text",
                    fontWeight: "bold",
                    color: "rgb(30,30,30)",
                    backgroundColor: "rgba(0,0,0,0)",
                  }}
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleEnterKeyField}
                />
                <button
                  className="non-floating-button"
                  position="absolute"
                  style={{ right: "0px" }}
                  onClick={() => {
                    findSomeone();
                    setShowInput(!showInput);
                  }}
                >
                  Search
                </button>
              </>
            )}
          </div>
          <button
            className="button header"
            onClick={handleFindMySignature}
            style={{
              backgroundColor: "rgb(44, 76, 112)",
              color: "rgb(255,255,255)",
              padding: "8px 20px",
              marginLeft: "15px",
              borderRadius: "12px",
              border: "none",
              fontWeight: "bold",
              marginTop: "15px",
            }}
          >
            Find My Signature
          </button>
          {bought ? (
            <a href="/shop">
              <button
                className="button header"
                style={{
                  backgroundColor: "rgb(178, 34, 52)",
                  color: "#fff",
                  padding: "8px 20px",
                  borderRadius: "12px",
                  border: "none",
                  fontWeight: "bold",
                  marginTop: "15px",

                  marginLeft: "15px",
                }}
              >
                Get the replica
              </button>
            </a>
          ) : (
            <a href="/select-size">
              <button
                className="button header"
                style={{
                  backgroundColor: "rgb(178, 34, 52)",
                  color: "#fff",
                  padding: "8px 20px",
                  borderRadius: "12px",
                  border: "none",
                  fontWeight: "bold",
                  marginTop: "15px",

                  marginLeft: "15px",
                }}
              >
                Send to Congress
              </button>
            </a>
          )}
        </Flex>
      )}

      <div
        className="map"
        style={{
          display: "grid",
          overflow: "hidden",
        }}
      >
        {!hasSelected && <div className={`doifilter`} style={filterStyle} />}
        <img
          src={`${textReadable ? "/assets/Dec-text.svg" : "/assets/Dec.svg"}`}
          ref={imgRef}
          onLoad={handleImageLoad}
          className={`doi ${hasSelected ? "clicked" : ""} ${shiftDirection}`}
          style={imageStyle}
        />

        {!hasSelected && (
          <div
            onClick={handleOptionClick}
            onMouseMove={handleMouseMove}
            style={selectionGridStyle}
          >
            <div
              key={2 - 2}
              style={{
                position: "absolute",
                border: "3px",
                pointerEvents: "none",
                left: `${hoverColumn / 3}%`,
                top: `${hoverRow / 3}%`,
                borderStyle: "dashed",
                borderColor: "black",
                backgroundColor: "rgba(30, 30, 30, 0.7)",
                color: "white",
              }}
            ></div>
          </div>
        )}

        {hasSelected && (
          <div style={signatureGridStyle} onTransitionEnd={handleTransitionEnd}>
            {signatureGrid}
          </div>
        )}
        {hasSelected && (
          <div style={animationGridBottom} className="animationBlock"></div>
        )}
        {hasSelected && (
          <div style={animationGridLeft} className="animationBlock"></div>
        )}
        {hasSelected && (
          <div style={animationGridRight} className="animationBlock"></div>
        )}
        {hasSelected && (
          <div style={animationGridTop} className="animationBlock"></div>
        )}
      </div>
      <footer
        style={{ backgroundColor: "#000", color: "#fff", padding: "1vw 0" }}
      >
        <div
          className="footer-container"
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            display: "flex",
            padding: "4vw 15px",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {loggedIn && (
            <div
              className="button header find2"
              onClick={() => {
                if (!showInput) {
                  setShowInput(!showInput);
                }
              }}
              style={{
                backgroundColor: "white",
                color: "#000",
                padding: "0px",
                borderRadius: "12px",
                border: "none",
                fontWeight: "bold",
                position: "relative",
                marginBottom: "1rem",
              }}
            >
              {!showInput && (
                <div style={{ padding: "8px 20px" }}>
                  Find Someone’s Signature
                </div>
              )}
              {showInput && (
                <>
                  <input
                    className="non-floating-button"
                    style={{
                      margin: "0px",
                      left: "5px",
                      height: "90%",
                      fontWeight: "bold",
                      color: "rgb(0,0,0)",
                      backgroundColor: "rgba(0,0,0,0)",
                    }}
                    type="email"
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleEnterKeyField}
                  />
                  <button
                    className="non-floating-button"
                    position="absolute"
                    style={{
                      right: "0px",
                      color: "white",
                      backgroundColor: "#2c4c70",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      findSomeone();
                      setShowInput(!showInput);
                    }}
                  >
                    Search
                  </button>
                </>
              )}
            </div>
          )}

          <div className="accesibility2" style={{ marginBottom: "1.5rem" }}>
            <div
              style={{
                backgroundColor: "black",
                padding: "10px",
                borderRadius: "10px",
                zIndex: "9",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                marginRight: "1rem",
                width: "100vw",
              }}
            >
              <audio ref={audioRef} src="/assets/dec_audio.mp3" />
              <button className="play-pause-button" onClick={togglePlay}>
                {isPlaying ? (
                  <>
                    <FontAwesomeIcon
                      style={{ color: "black" }}
                      icon={faPause}
                    />{" "}
                    <span style={{ color: "black" }}>Pause</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon style={{ color: "black" }} icon={faPlay} />{" "}
                    <span style={{ color: "black" }}>Play</span>
                  </>
                )}
              </button>
              {/* <FontAwesomeIcon
                icon={faUniversalAccess}
                style={{ height: "50px", color: "white" }}
                onClick={showButtons}
              ></FontAwesomeIcon> */}
              <div>
                <Switch
                  onChange={handleToggleText}
                  checked={textReadable}
                  onColor="#DDD49E"
                  className="react-switch"
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 22,
                        fontFamily: "Allura",
                        marginTop: "-15",
                        paddingTop: 6,
                        paddingRight: 4,
                      }}
                    >
                      A
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 18,
                        paddingBottom: 1,
                        paddingLeft: 6,
                      }}
                    >
                      A
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="footer-logo"
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              marginBottom: "2vw",
              flexBasis: "100%",
              textAlign: "center",
            }}
          >
            Declaration of Independence Project
          </div>
          <div
            className="footer-social"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexBasis: "100%",
              justifyContent: "center",
              marginBottom: "0px",
            }}
          >
            {bought ? (
              <p
                style={{
                  marginBottom: "20px",
                  flexBasis: "100%",
                  textAlign: "justify",
                }}
              >
                Do you want to get the replica of the declaration with your
                signature in it?
              </p>
            ) : (
              <p
                style={{
                  marginBottom: "20px",
                  flexBasis: "100%",
                  textAlign: "justify",
                }}
              >
                Do you want your signature to be included in the delivery of the
                declaration of independence to congress?
              </p>
            )}

            {bought ? (
              <a href="/shop">
                <button
                  style={{
                    backgroundColor: "#B22234",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Take me to the Shop!
                </button>
              </a>
            ) : localStorage.getItem("token") ? (
              <a href="/select-size">
                <button
                  style={{
                    backgroundColor: "#B22234",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Yes I Do!
                </button>
              </a>
            ) : (
              <a href="/sign">
                <button
                  style={{
                    backgroundColor: "#B22234",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Yes I Do!
                </button>
              </a>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default DoiAtlas;
