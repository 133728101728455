import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-vertical-timeline-component/style.min.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faXTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/Home.css";
import MapSection from "./MapSection";
import Stats from "./Stats";

import Modal from "react-modal";

import Sign from "../pages/SignaturePage";

import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Establecer el elemento raíz para los modales
Modal.setAppElement("#root");

const MailingHome = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [pid, setPid] = useState(searchParams.get("pid"));

  localStorage.setItem("pid", pid);

  useEffect(() => {
    // Limpia localStorage cuando el componente se desmonta
    localStorage.clear();
  }, []);

  const heroes = [
    // {
    //   name: "George Washington",
    //   description:
    //     "First US President and Commander-in-Chief of the Continental Army.",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Gilbert_Stuart_Williamstown_Portrait_of_George_Washington.jpg/1200px-Gilbert_Stuart_Williamstown_Portrait_of_George_Washington.jpg",
    //   quote: "Happiness and moral duty are inseparably connected.",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/George_Washington_Signature.svg/1280px-George_Washington_Signature.svg.png",
    //   colony: "Virginia",
    // },
    {
      name: "Thomas Jefferson",
      description:
        "The primary author of the Declaration of Independence, third US President.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/jeffersonImg.jpeg",
      quote: "I cannot live without books.",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/JeffersonSign.png",
      colony: "Virginia",
    },
    {
      name: "John Adams",
      description:
        "The first Vice President, and the second President of the United States.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/JohnAdamsImg.jpg",
      quote: "Every problem is an opportunity in disguise.",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/JohnAdamsSign.png",
      colony: "Massachusetts",
    },
    {
      name: "Benjamin Franklin",
      description: "An influential statesman, inventor, and diplomat.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/franklinImg.jpeg",
      quote: "An investment in knowledge pays the best interest.",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/franklinSign.png",
      colony: "Pennsylvania",
    },
    // {
    //   name: "Alexander Hamilton",
    //   description:
    //     "Co-author of the Federalist Papers, and the first Secretary of the Treasury.",
    //   image:
    //     "https://clubrunner.blob.core.windows.net/00000007988/Images/2018-2019/hamilton.jpg",
    //   quote: "Those who stand for nothing fall for anything.",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Alexander_Hamilton_Signature.svg/1280px-Alexander_Hamilton_Signature.svg.png",
    //   colony: "New York",
    // },
    // {
    //   name: "James Madison",
    //   description:
    //     'The "Father of the Constitution", and the fourth President of the United States.',
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/1/1d/James_Madison.jpg",
    //   quote: "If men were angels, no government would be necessary.",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/James_Madison_sig.svg/1200px-James_Madison_sig.svg.png",
    //   colony: "Virginia",
    // },
    // {
    //   name: "John Jay",
    //   description:
    //     "Co-author of the Federalist Papers and the first Chief Justice of the US.",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Gilbert_Stuart%2C_John_Jay%2C_1794%2C_NGA_75023.jpg/1200px-Gilbert_Stuart%2C_John_Jay%2C_1794%2C_NGA_75023.jpg",
    //   quote: "Those who own the country ought to govern it.",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/John_Jay_Signature2.svg/2560px-John_Jay_Signature2.svg.png",
    //   colony: "New York",
    // },
    // {
    //   name: "Thomas Paine",
    //   description: 'The author of the influential pamphlet "Common Sense"',
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Thomas_Paine_rev1.jpg/800px-Thomas_Paine_rev1.jpg",
    //   quote: "The mind once enlightened cannot again become dark.",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Thomas_Paine_Signature.svg/2560px-Thomas_Paine_Signature.svg.png",
    //   colony: "Pennsylvania",
    // },
    {
      name: "Samuel Adams",
      description: "A political leader and organizer in Boston.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/SamuelAdamsImg.jpeg",
      quote:
        "I have but one lamp by which my feet are guided, and that is the lamp of experience.",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/SamuelAdamsSign.png",
      colony: "Massachusetts",
    },
    // {
    //   name: "Patrick Henry",
    //   description: "A passionate orator.",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/e/ea/Patrick_henry.JPG",
    //   quote: "Give me liberty or give me death!",
    //   colony: "Virginia",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Patrick_Henry_Signature.svg/2560px-Patrick_Henry_Signature.svg.png",
    // },
    // {
    //   name: "Paul Revere",
    //   description:
    //     "Silversmith, patriot, and early member of the Sons of Liberty.",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/J_S_Copley_-_Paul_Revere_%28cropped%29.jpg/1200px-J_S_Copley_-_Paul_Revere_%28cropped%29.jpg",
    //   quote: "The British are coming! The British are coming!",
    //   colony: "Massachusetts",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Paul_Revere_signature.svg/1280px-Paul_Revere_signature.svg.png",
    // },
    {
      name: "John Hancock",
      description: "The President of the Continental Congress.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/HancockImg.jpeg",
      quote: "Proclaim your truth and do not be silent about it",
      colony: "Massachusetts",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/HancockSign.png",
    },
    {
      name: "George Wythe",
      description: "Legal scholar, Virginia judge.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/wytheImg.jpeg",
      quote: "Do equal and exact justice",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/WytheSign.png",
      colony: "Virginia",
    },
    {
      name: "Roger Sherman",
      description: "Founding Father, member of the Continental Congress.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/ShermanImg.jpeg",
      quote:
        "When you are in the minority, talk; when you are in the majority, vote.",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/ShermanSign.png",
      colony: "Connecticut",
    },
    {
      name: "Robert Morris",
      description:
        "Financier of the American Revolution, signer of the Declaration of Independence, U.S. Senator.",
      image:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/MorrisImg.jpeg",
      quote:
        "To save our country from the designs of an intriguing, artful, and ambitious man",
      signature:
        "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/MorrisSign.png",
      colony: "Pennsylvania",
    },

    // {
    //   name: "Nathanael Greene",
    //   description: "A major general in the Continental Army.",
    //   image:
    //     "https://www.gannett-cdn.com/authoring/2015/01/20/NPRJ/ghows-PJ-02f39918-cecc-47a7-a5c5-aaaf34544f84-14cad580.jpeg?width=1200&disable=upscale&format=pjpg&auto=webp",
    //   quote: "We fight, get beat, rise, and fight again.",
    //   colony: "Rhode Island",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Nathaniel_Greene_Signature.svg/1200px-Nathaniel_Greene_Signature.svg.png",
    // },
    // {
    //   name: "Marquis de Lafayette",
    //   description:
    //     "A French nobleman who served as a major general in the Continental Army.",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/5/52/Gilbert_du_Motier_Marquis_de_Lafayette.jpg",
    //   signature:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Marquis_de_La_Fayette_Signature.svg/1280px-Marquis_de_La_Fayette_Signature.svg.png",
    //   quote: "Humanity has won its battle. Liberty now has a country.",
    //   colony: "France",
    // },
    // {
    //   name: "Baron von Steuben",
    //   description:
    //     "A Prussian military officer who helped train and organize the Continental Army.",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/c/cd/Baron_Steuben_by_Peale%2C_1780.jpg",
    //   signature: "",
    //   quote: "Discipline is the soul of an army.",
    //   colony: "Prussia",
    // },
    // {
    //   name: "Betsy Ross",
    //   description:
    //     "She is popularly credited with sewing the first American flag.",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/1/10/Betsy_Ross_1893.png",
    //   signature: "",
    //   quote:
    //     "Proclaim liberty throughout all the land unto all the inhabitants thereof.",
    //   colony: "Pennsylvania",
    // },
  ];
  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);

  const [showButton, setShowButton] = useState(false); // Estado para mostrar el botón

  // Estados para rastrear si los popups están abiertos
  const [isProcessPopupOpen, setIsProcessPopupOpen] = useState(false);
  const [isDeliveryPopupOpen, setIsDeliveryPopupOpen] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIcon
        icon={faChevronRight}
        onClick={onClick}
        style={{ ...style, display: "block", color: "black", margin: "10px" }}
        className={className}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FontAwesomeIcon
        icon={faChevronLeft}
        onClick={onClick}
        style={{ ...style, display: "block", color: "black", margin: "10px" }}
        className={className}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // autoplay habilitado
    autoplaySpeed: 6000, // 3 segundos entre cada slide
    responsive: [
      {
        breakpoint: 1024, // Pantalla de escritorio
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "25%",
          dots: false,
        },
      },
      {
        breakpoint: 912, // Tablet
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20%",
          dots: false,
        },
      },
      {
        breakpoint: 600, // Pantalla móvil
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); // Agrega un listener de scroll
    return () => window.removeEventListener("scroll", handleScroll); // Elimina el listener cuando el componente se desmonta
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Hace scroll al inicio de la página con una animación suave
  };

  const handleClick2 = () => {
    // Obtén el elemento con el ID "stats"
    const statsElement = document.getElementById("stats");

    // Verifica que el elemento exista antes de intentar desplazarse a él
    if (statsElement) {
      // Desplázate suavemente hasta el elemento
      statsElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Cálculo del tiempo restante
    const countDownDate = new Date("July 4, 2024 00:00:00").getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDaysLeft(days);
      setHoursLeft(hours);
      setMinutesLeft(minutes);
      setSecondsLeft(seconds);
    }, 1000);
    return () => clearInterval(x);
  }, []);

  useEffect(() => {
    if (!isProcessPopupOpen) {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
      document.body.classList.remove("modal-open");
    }
  }, [isProcessPopupOpen]);

  useEffect(() => {
    if (!isDeliveryPopupOpen) {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
      document.body.classList.remove("modal-open");
    }
  }, [isDeliveryPopupOpen]);

  return (
    <>
      <Helmet>
        {/* <!-- Meta Pixel Code --> */}
        <script>
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '283598554003171');
        fbq('track', 'PageView');
      `}
        </script>
        <noscript>
          {`
        <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=283598554003171&ev=PageView&noscript=1"
        />
      `}
        </noscript>
        {/* <!-- End Meta Pixel Code --> */}
      </Helmet>
      <div className="homeC">
        <section
          className="homeSec"
          style={{
            backgroundColor: "#fff",
            color: "#000",
            padding: "50px",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // backgroundImage:
            //   "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/homePen.png')",
            backgroundSize: "cover",
            backgroundPosition: "center calc(70% - 70%)",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              textAlign: "center",
              // marginTop: "-10rem",
            }}
          >
            <h1
              className="titleDec"
              style={{
                fontSize: "55px",
                fontWeight: "bold",
                marginBottom: "30px",
                color: "white",
                textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
                // backgroundColor: "black", // Transparencia blanca
                // padding: "5px 10px",
              }}
            >
              The Declaration of Independence Project
            </h1>
            <p
              className="p-mac"
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "1.5",
                marginBottom: "30px",
                textAlign: "center",
                color: "white",
                padding: "10px",
                width: "fit-content",
                textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto

                // backgroundColor: "black", // Transparencia blanca
                // padding: "5px 10px",
              }}
            >
              Join the movement to deliver your signatures to Congress. Now is
              the time to reaffirm our commitment to the most important document
              in American history.
            </p>
            <p
              className="typewriter-text" // Clase CSS para el efecto typewriter
              style={{
                margin: "0 auto",
                textAlign: "center",
                fontSize: "26px",
                fontWeight: "bold",
                color: "white",
                width: "fit-content",
                padding: "10px",
                textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto

                // backgroundColor: "black", // Transparencia blanca
                // padding: "5px 10px",
              }}
            >
              Are you brave enough to sign?
            </p>
            <a href="#sign">
              <button
                style={{
                  // backgroundColor: "white",
                  backgroundColor: "#B22234", // Azul oscuro
                  marginTop: "1rem",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  padding: "15px 40px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  letterSpacing: "1px",
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Sombra
                }}
              >
                Yes, I am!
              </button>
            </a>
          </div>
          <div
            className="process-container2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "fit-content",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <button
              className="box"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.05)", // Transparencia blanca
                backdropFilter: "blur(10px)", // Efecto de desenfoque
                // backgroundColor: "#2C4C70 ",
                // opacity: "0.8",
                padding: "20px",
                marginRight: "15px",
                borderRadius: "10px",
                width: "15vw",
                height: "fit-content",
                maxHeight: "18vh",
              }}
              onClick={() => {
                setIsProcessPopupOpen(true);
                document.body.classList.add("modal-open");
              }}
            >
              <img
                className="img-process"
                style={{
                  objectFit: "cover",
                  height: "5rem",
                  borderRadius: "50px",
                  width: "5rem",
                  margin: "0 auto",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/process.png"
                alt=""
              />
              <p
                className="p-process"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "0.9rem",
                }}
              >
                The Process
              </p>
            </button>

            <Modal
              isOpen={isProcessPopupOpen}
              onRequestClose={() => {
                document.body.style.overflow = "auto";
                document.body.classList.remove("modal-open");
                setIsProcessPopupOpen(false);
              }}
              className="modal modal-content"
              style={{
                content: {
                  position: "relative",
                  top: "15%",
                  width: "400px",
                  height: "fit-content",
                  margin: "auto",
                  padding: "30px",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#f8f8f8",
                },
              }}
            >
              <button
                className="modal-close-button"
                onClick={() => {
                  setIsProcessPopupOpen(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <img
                className="modal-img"
                style={{
                  objectFit: "cover",
                  height: "8rem",
                  borderRadius: "100px",
                  width: "8rem",
                  margin: "0 auto",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/process.png"
                alt=""
              />
              <h2
                className="modal-h2"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                The Process
              </h2>
              <p
                className="modal-p"
                style={{
                  fontSize: "0.85rem",
                  color: "#555",
                  marginBottom: "15px",
                  textAlign: "justify",
                }}
              >
                The process of creating new signature pages involves a{" "}
                <span style={{ fontWeight: "600", color: "#000" }}>
                  combination of high-tech and traditional techniques.
                </span>{" "}
                We will be using a CNC pen-writing robot to apply citizens’
                signatures to a long scroll of parchment paper. <br /> <br />{" "}
                <span style={{ fontWeight: "600", color: "#000" }}>
                  Citizens can sign{" "}
                </span>{" "}
                their name inputting it as text that will be represented in a
                classic calligraphy font to achieve an authentic handwriting
                style. This process results in an end product that looks true to
                the era of the Declaration of Independence, while still
                utilizing modern technology. <br /> <br /> Once the signature is
                registered you will choose if you want to be included in the
                Delivery to Congress for $3 per signature or more. You can also{" "}
                <span style={{ fontWeight: "600", color: "#000" }}>
                  {" "}
                  get a physical copy of the Declaration of Independence with
                  your signature on it.
                </span>{" "}
              </p>
            </Modal>
            <button
              className="box"
              style={{
                // backgroundColor: "#B22234 ",
                // opacity: "0.8",
                backgroundColor: "rgba(255, 255, 255, 0.05)", // Transparencia blanca
                backdropFilter: "blur(10px)", // Efecto de desenfoque
                padding: "20px",
                marginRight: "15px",
                borderRadius: "10px",
                width: "15vw",
                height: "fit-content",
                maxHeight: "18vh",
                textAlign: "center",
              }}
              onClick={() => {
                setIsDeliveryPopupOpen(true);
                document.body.classList.add("modal-open");
              }}
            >
              <img
                className="img-process"
                style={{
                  objectFit: "cover",
                  height: "5rem",
                  borderRadius: "100px",
                  width: "5rem",
                  margin: "0 auto",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/congress.jpeg"
                alt=""
              />
              <p
                className="p-process"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                The Project
              </p>
            </button>
            <Modal
              isOpen={isDeliveryPopupOpen}
              onRequestClose={() => setIsDeliveryPopupOpen(false)}
              className="modal modal-content"
              style={{
                content: {
                  position: "relative",
                  top: "18%",
                  width: "400px",
                  height: "fit-content",
                  margin: "auto",
                  padding: "30px",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#f8f8f8",
                },
              }}
            >
              <button
                className="modal-close-button"
                onClick={() => {
                  setIsDeliveryPopupOpen(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <img
                className="modal-img"
                style={{
                  objectFit: "cover",
                  height: "8rem",
                  borderRadius: "100px",
                  width: "8rem",
                  margin: "0 auto",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/congress.jpeg"
                alt=""
              />
              <h2
                className="modal-h2"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                The Project
              </h2>
              <p
                className="modal-p"
                style={{
                  fontSize: "0.85rem",
                  color: "#555",
                  marginBottom: "15px",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontWeight: "600", color: "#000" }}>
                  The "DOI Project"{" "}
                </span>{" "}
                is on a mission to{" "}
                <span style={{ fontWeight: "600", color: "#000" }}>
                  {" "}
                  unify our great nation
                </span>{" "}
                , taking inspiration from the very document that embodies every
                American's values—{" "}
                <span style={{ fontWeight: "600", color: "#000" }}>
                  {" "}
                  The Declaration of Independence
                </span>{" "}
                . In an era where its significance seems to be overlooked by
                Congress, our project emphasizes its pivotal role in educating
                our youth and future generations. It's a reminder, a call to
                action, to recognize and uphold the principles that guarantee
                our{" "}
                <span style={{ fontWeight: "600", color: "#000" }}>
                  freedom.
                </span>{" "}
                <br />
                <br />
                Stand with us, as we deliver the DOI to Congress on{" "}
                <span style={{ fontWeight: "600", color: "#000" }}>
                  September 2024
                </span>{" "}
                , backed by the signatures of all those courageous enough to
                reaffirm its enduring legacy. <br />
                <br />
                <span style={{ fontWeight: "600", color: "#000" }}>
                  Sign the Declaration today and become part of one of the most
                  important movements in USA's modern history!
                </span>{" "}
              </p>
            </Modal>
          </div>
        </section>

        <section
          style={{
            position: "relative",
            backgroundColor: "#000000",
            color: "#FFFFFF",
            padding: "50px 20px",
          }}
        >
          <h2
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Join the movement!
          </h2>
          {/* <p
          style={{
            fontSize: "18px",
            lineHeight: 1.5,
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          Join us in signing the Declaration of Independence
        </p> */}
          {/* <a href="/sign">
          <button
            style={{
              backgroundColor: "#B22234",
              color: "#fff",
              fontSize: "18px",
              fontWeight: "bold",
              padding: "15px 30px",
              borderRadius: "30px",
              border: "none",
              cursor: "pointer",
              display: "block",
              margin: "0 auto",
            }}
          >
            Yes I do
          </button>
        </a> */}
        </section>

        <section
          className="what-section"
          id="importance"
          style={{
            backgroundColor: "#4d4d4d", // Tono claro de rojo
            color: "#fff",
            padding: "50px",
            height: "72vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            backgroundImage:
              "linear-gradient(#ededede6, #ededede6), url('/assets/Dec.svg')",
            backgroundSize: "cover",
            backgroundSize: "cover", // Ajusta el tamaño de la imagen de fondo para cubrir el elemento
            // backgroundPosition: "0px -140px", // Centra la imagen de fondo
            backgroundRepeat: "no-repeat", // Evita que la imagen de fondo se repita
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <h2
              style={{
                fontSize: "36px",
                fontWeight: "bold",
                marginBottom: "30px",
                color: "#000",
              }}
            >
              What is the DOI
            </h2>
            <img
              style={{
                position: "absolute",
                zIndex: "9",
                right: "60%",
                opacity: "0.25",
                mixBlendMode: "multiply",
              }}
              src="/assets/stamp.png"
              alt=""
            />

            <p
              style={{
                fontSize: "18px",
                lineHeight: "1.5",
                marginBottom: "30px",
                textAlign: "justify",
                color: "#000",
                fontWeight: "600",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              The Declaration of Independence is a foundational document of the
              United States. Drafted primarily by Thomas Jefferson, it announced
              the thirteen American colonies' separation from Great Britain and
              articulated the principles of liberty, individual rights, and
              self-governance. Beyond its historical significance, it symbolizes
              the universal aspirations for freedom, equality, and the pursuit
              of happiness, serving as an enduring beacon for nations worldwide.
            </p>
          </div>
        </section>

        {/* Sección de cuenta regresiva con efecto de glassmorphism */}

        {/* <section
        className="importance-section"
        id="importance"
        style={{
          backgroundColor: "#f7f7f7", // Tono claro de gris
          color: "#000",
          padding: "50px",
          height: "72vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
          backgroundImage:
            "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')", // Agrega la URL de la imagen deseada aquí
          backgroundSize: "cover", // Ajusta el tamaño de la imagen de fondo para cubrir el elemento
          // backgroundPosition: "0px -140px", // Centra la imagen de fondo
          backgroundRepeat: "no-repeat", // Evita que la imagen de fondo se repita
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h2
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#fff",
              backgroundColor: "#B22234",
              padding: "0px 20px",
            }}
          >
            Why it's important
          </h2>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "1.5",
              marginBottom: "30px",
              textAlign: "justify",
              color: "#fff",
              fontWeight: "600",
              textShadow: "7px 7px 7px rgba(0, 0, 0, 0.5)", // Sombras de texto
              // backgroundColor: "black",
              // opacity: "0.7",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            The Declaration of Independence is not merely a historical document;
            it is a living testament to the power of revolutionary ideas and the
            human spirit. As a cornerstone of American democracy, it asserts the
            rights of citizens to life, liberty, and the pursuit of happiness.
            By signing the online declaration, you join a proud tradition of
            Americans who have stood up for these principles and have fought to
            preserve and protect them for future generations.
          </p>
        </div>
      </section> */}

        {/* <section>
        <div
          style={{
            height:"40vh",
            wdith:"100vw",
            backgroundImage:
              "url('https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/vintage-illustration-of-george-washington-watching-betsy-ross-sew-the-american-flag-american-school.jpg')", // Agrega la URL de la imagen deseada aquí
            backgroundSize: "cover", // Ajusta el tamaño de la imagen de fondo para cubrir el elemento
            backgroundPosition: "0px -140px", // Centra la imagen de fondo
            backgroundRepeat: "no-repeat", // Evita que la imagen de fondo se repita
          }}
        ></div>
      </section> */}

        <div
          id="sign"
          style={{ position: "relative", marginTop: "-2rem" }}
        ></div>

        <Sign />

        <div id="stats"></div>

        <section style={{ marginTop: "6rem" }}>
          <h2
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center",
              width: "fit-content",
              margin: "0 auto",
              padding: "0px 20px",
              marginBottom: "3rem",
            }}
          >
            Statistics
          </h2>
          <div style={{ width: "85vw", margin: "0 auto" }}>
            <p
              className="intro-stats"
              style={{
                fontSize: "18px",
                lineHeight: "1.5",
                marginBottom: "30px",
                textAlign: "justify",
                width: "fit-content",
                marginTop: "30px",
              }}
            >
              The 56 signers of the Declaration of Independence represented a
              diverse cross-section of the American colonies in terms of
              background, profession, and social status. Here are some more
              detailed statistics and facts about the signers:
            </p>
          </div>
        </section>

        {/* <section style={{ marginTop: "6rem" }}>
        <h2
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            marginBottom: "20px",
            textAlign: "center",
            backgroundColor: "#1C3F8C ",
            width: "fit-content",
            margin: "0 auto",
            color: "white",
            padding: "0px 20px",
            marginBottom: "3rem",
          }}
        >
          Statistics
        </h2>
        <div style={{ width: "85vw", margin: "0 auto" }}>
          <p
            className="intro-stats"
            style={{
              fontSize: "18px",
              lineHeight: "1.5",
              marginBottom: "30px",
              textAlign: "justify",
              width: "fit-content",
              marginTop: "30px",
            }}
          >
            The 56 signers of the Declaration of Independence represented a
            diverse cross-section of the American colonies in terms of
            background, profession, and social status. Here are some more
            detailed statistics and facts about the signers:
          </p>
        </div>
      </section>

      {/* <MapSection></MapSection> */}
        <section style={{ padding: "0px" }}>
          <Stats></Stats>
        </section>

        <section
          className="sacrifice-section"
          style={{
            backgroundColor: "#222",
            color: "#fff",
            padding: "50px 0",
            // backgroundImage:
            //   "url('https://www.gannett-cdn.com/media/2019/06/30/USATODAY/usatsports/gettyimages-153718849.jpg?crop=1365,768,x0,y0&width=1365&height=682&format=pjpg&auto=webp')", // Agrega la URL de la imagen deseada aquí
            // backgroundRepeat: "no-repeat",
            // filter: "grayscale(100%)",
            height: "fit-content",
          }}
        >
          <div
            className="sacrifice-container"
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              textAlign: "center",
              marginBottom: "2rem",
            }}
          >
            <h2
              style={{
                fontSize: "36px",
                fontWeight: "bold",
                marginBottom: "30px",
              }}
            >
              Sacrifices
            </h2>
            <p
              style={{
                fontSize: "18px",
                lineHeight: "1.5",
                textAlign: "justify",
                textShadow: "6px 6px 6px rgba(0, 0, 0, 0.5)", // Sombras de texto
                fontWeight: "600",
              }}
            >
              Many people have continued to make sacrifices for the United
              States of America since the signing of the Declaration of
              Independence. From the soldiers who fought in the Revolutionary
              War to those who have served in the military in the years since,
              to the everyday citizens who work to make our country a better
              place, we owe a debt of gratitude to all those who have sacrificed
              for our freedom and independence. As a testament to their
              unwavering dedication, we have created an archive that showcases
              the sacrifices made by the signers themselves.
            </p>
            <a href="/signers">
              <button
                style={{
                  backgroundColor: "#B22234",
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding: "15px 30px",
                  borderRadius: "30px",
                  border: "none",
                  cursor: "pointer",
                  display: "block",
                  margin: "0 auto",
                  marginTop: "2rem",
                }}
              >
                Visit the Archive
              </button>
            </a>
          </div>
        </section>

        <section
          className="heroes-section"
          id="heroes"
          style={{
            backgroundColor: "#fff",
            padding: "50px 0",
            height: "fit-content",
          }}
        >
          <div className="hero-carousel-wrapper">
            <h2
              style={{
                fontSize: "36px",
                fontWeight: "bold",
                textAlign: "center",
                // backgroundColor: "#DAA520 ",
                margin: "0 auto",
                color: "black",
                width: "fit-content",
                // padding:"0px 20px"
              }}
            >
              Our Heroes
            </h2>
            <p
              style={{
                fontSize: "19px",
                lineHeight: "1.5",
                marginTop: "15px",
                textAlign: "center",
              }}
            >
              Many prominent figures contributed to the success of the
              Declaration of Independence and the American Revolution
            </p>
            <Slider {...settings}>
              {heroes.map((hero) => (
                <Link to={`/signers?heroName=${hero.name}`}>
                  <div key={hero.name} className="heroC">
                    <img
                      src={hero.image}
                      alt={hero.name}
                      className="heroImage"
                    />
                    {hero.signature !== "" && (
                      <img className="heroSign" src={hero.signature} />
                    )}
                    {hero.signature === "" && (
                      <div
                        className="heroSign"
                        style={{ fontFamily: "Beth Ellen", fontSize: "1.1rem" }}
                      ></div>
                    )}

                    <h2 className="heroName">{hero.name}</h2>
                    <p className="heroP">{hero.description}</p>
                    <p className="heroP" style={{ fontStyle: "italic" }}>
                      "{hero.quote}"
                    </p>
                    <p style={{ fontWeight: "600" }} className="heroP">
                      {hero.colony}
                    </p>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </section>

        <section
          className="flag-section"
          id="flags"
          style={{
            backgroundColor: "#f5f5f5",
            color: "#333",
            padding: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              marginBottom: "5rem",
              textAlign: "center",
            }}
          >
            Flags involved in the Declaration of Independence
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="flag-disposition"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "3rem",
              }}
            >
              <div
                style={{ margin: "10px", marginBottom: "3rem" }}
                data-nosnippet
              >
                <img
                  src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/grandUnionFlag.png"
                  alt="Grand Union Flag"
                  style={{
                    width: "200px",
                    height: "120px",
                    borderRadius: "4px",
                    margin: "0 auto",
                    marginBottom: "15px",
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "3px",
                  }}
                >
                  The Grand Union Flag
                </h2>
                <p
                  style={{
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  Also known as the Continental Colors, was the first national
                  flag of the United States, used from 1775 to the year 1777.
                </p>
              </div>
              <div style={{ margin: "10px", marginBottom: "3rem" }}>
                <img
                  src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/BetsyRossFlag.png"
                  alt="Betsy Ross Flag"
                  style={{
                    width: "200px",
                    height: "120px",
                    borderRadius: "4px",
                    margin: "0 auto",
                    marginBottom: "15px",
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "3px",
                  }}
                >
                  The Betsy Ross Flag
                </h2>
                <p
                  style={{
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  Also known as the Stars and Stripes, was created in 1776 and
                  is considered the first official national flag of the United
                  States.
                </p>
              </div>
              <div style={{ margin: "10px", marginBottom: "3rem" }}>
                <img
                  src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/starSpangledFlag.svg"
                  alt="Star-Spangled Banner Flag"
                  style={{
                    width: "200px",
                    height: "120px",
                    borderRadius: "4px",
                    margin: "0 auto",
                    marginBottom: "15px",
                    objectFit: "cover",
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "3px",
                  }}
                >
                  The Star-Spangled Banner Flag
                </h2>

                <p
                  style={{
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  {" "}
                  Was created in 1813 and was the flag that inspired Francis
                  Scott Key to write the poem that became the national anthem of
                  the US.
                </p>
              </div>
            </div>

            <div
              className="flag-disposition"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "10px", marginBottom: "3rem" }}>
                <img
                  src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/GadsdenFlag.png"
                  alt="Star-Spangled Banner Flag"
                  style={{
                    width: "200px",
                    height: "120px",
                    borderRadius: "4px",
                    margin: "0 auto",
                    marginBottom: "15px",
                    objectFit: "cover",
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "3px",
                  }}
                >
                  Gadsden Flag
                </h2>

                <p
                  style={{
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  {" "}
                  Yellow flag with coiled rattlesnake and motto "DONT TREAD ON
                  ME." Symbol of resistance and independence.
                </p>
              </div>

              <div style={{ margin: "10px", marginBottom: "3rem" }}>
                <img
                  src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Bennington_Flag.svg"
                  alt="Star-Spangled Banner Flag"
                  style={{
                    width: "200px",
                    height: "120px",
                    borderRadius: "4px",
                    margin: "0 auto",
                    marginBottom: "15px",
                    objectFit: "cover",
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "3px",
                  }}
                >
                  Bennington Flag
                </h2>

                <p
                  style={{
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  {" "}
                  Flag with thirteen stripes, blue canton with "76," and white
                  stars. Represents unity in the fight for independence.
                </p>
              </div>

              <div style={{ margin: "10px", marginBottom: "3rem" }}>
                <img
                  src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/BedfordFlag.png"
                  alt="Star-Spangled Banner Flag"
                  style={{
                    width: "200px",
                    height: "120px",
                    borderRadius: "4px",
                    margin: "0 auto",
                    marginBottom: "15px",
                    objectFit: "cover",
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "3px",
                  }}
                >
                  Bedford Flag
                </h2>

                <p
                  style={{
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  {" "}
                  Crimson flag with armored arm, sword, and motto "VINCE AUT
                  MORIRE." Emblem of determination for rights and freedoms.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div id="background"></div>

        <section>
          <h2
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
              marginTop: "5rem",
            }}
          >
            Historical Background
          </h2>
          <p
            className="historical-text"
            style={{
              fontSize: "19px",
              lineHeight: "1.5",
              textAlign: "center",
              marginBottom: "3rem",
              maxWidth: "60vw",
              margin: "0 auto",
            }}
          >
            The path to the United States' Declaration of Independence on July
            4, 1776, was paved with a decade of tensions, grievances, and
            clashes with British rule. This narrative will explore the seminal
            events and decisions that propelled the colonies to sever ties and
            assert their sovereignty.
          </p>

          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid  #FFFFFF" }}
              date="Mid-18th century"
              iconStyle={{ background: "#B22234", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  1
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                Taxation Without Representation
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                The British government, seeking to offset expenses from the
                French and Indian War (1754-1763) and finance the administration
                and defense of the American colonies, initiated a series of
                taxes. These impositions, which notably included events like the
                Boston Tea Party, were met with significant colonial dissent,
                largely due to the absence of American representation in the
                British Parliament.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  borderRadius: "8px",
                  objectPosition: "0px -20px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history1.jpeg"
                alt=""
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid  #FFFFFF" }}
              date="1773"
              iconStyle={{ background: "#2C4C70", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  2
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                Growing Resistance
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                The colonists began to organize and resist British rule.
                Incidents like the Boston Tea Party, where colonists dumped a
                shipment of British tea into Boston Harbor, demonstrated the
                mounting frustration and anger toward British policies.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  // borderRadius: "8px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history2.jpeg"
                alt=""
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid  #FFFFFF" }}
              date="September 1774"
              iconStyle={{ background: "#B22234", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  3
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                The First Continental Congress
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                Delegates from 12 of the 13 colonies (excluding Georgia) met in
                Philadelphia for the First Continental Congress. They discussed
                their grievances and issued a declaration asserting their rights
                and their opposition to British taxation policies. The Congress
                also agreed to reconvene in May 1775 if their demands were not
                met.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  // borderRadius: "8px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history3.jpeg"
                alt=""
              />
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid #FFFFFF" }}
              date="1775"
              iconStyle={{ background: "#2C4C70", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  4
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                The Outbreak of War
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                Before the Second Continental Congress convened, violence
                erupted between British troops and American colonists in the
                Battles of Lexington and Concord in April 1775, marking the
                beginning of the American Revolutionary War.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  // borderRadius: "8px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history4.jpeg"
                alt=""
              />
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid #FFFFFF" }}
              date="1775"
              iconStyle={{ background: "#B22234", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  5
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                The Second Continental Congress
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                The Congress reconvened in May 1775, and among other actions,
                they appointed George Washington as the commander-in-chief of
                the Continental Army. As the war escalated, the Congress
                increasingly took on the responsibilities of a central
                government for the colonies.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  // borderRadius: "8px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history5.jpeg"
                alt=""
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid  #FFFFFF" }}
              date="January 1776"
              iconStyle={{ background: "#2C4C70", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  6
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                The Push for Independence
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                In January 1776, Thomas Paine's influential pamphlet "Common
                Sense" was published, arguing passionately for American
                independence from Britain. The pamphlet gained widespread
                support among colonists, further fueling the desire for
                independence.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  // borderRadius: "8px",
                  objectPosition: "0px 0px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history6.jpeg"
                alt=""
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid  #FFFFFF" }}
              date="June 7, 1776"
              iconStyle={{ background: "#B22234", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  7
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                The Resolution for Independence
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                On June 7, 1776, Richard Henry Lee of Virginia introduced a
                resolution in the Second Continental Congress, declaring the
                colonies to be independent states. The Congress then appointed a
                committee, consisting of Thomas Jefferson, John Adams, Benjamin
                Franklin, Roger Sherman, and Robert R. Livingston, to draft a
                formal statement of independence.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  // borderRadius: "8px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history7.jpeg"
                alt=""
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#FFFFFF", color: "#000000" }}
              contentArrowStyle={{ borderRight: "7px solid  #FFFFFF" }}
              date="July 4, 1776"
              iconStyle={{ background: "#2C4C70", color: "#FFFFFF" }}
              icon={
                <span
                  style={{
                    transform: "translateX(-50%)",
                    position: "relative",
                    top: "30%",
                    left: "42%",
                  }}
                >
                  8
                </span>
              }
            >
              <h3
                className="vertical-timeline-element-title"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                Adoption and Signing
              </h3>
              <p style={{ fontWeight: "400", textAlign: "justify" }}>
                The Second Continental Congress debated and revised the
                Declaration of Independence, and on July 2, 1776, they voted in
                favor of Lee's resolution for independence. On July 4, 1776, the
                Congress formally adopted the Declaration of Independence, and
                it was subsequently signed by 56 delegates from the 13 colonies.
              </p>
              <img
                className="background-img"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "10rem",
                  objectFit: "cover",
                  // borderRadius: "8px",
                }}
                src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/history8.jpeg"
                alt=""
              />
            </VerticalTimelineElement>
          </VerticalTimeline>
        </section>

        {/* <section
        style={{ marginBottom: "4rem", marginTop: "3rem" }}
      >
        <h2
          style={{
            fontSize: "30px",
            fontWeight: "600",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          The Process
        </h2>
        <div style={{ width: "85vw", margin: "0 auto" }}>
          <p
            className="intro-stats"
            style={{
              fontSize: "18px",
              lineHeight: "1.5",
              marginBottom: "30px",
              textAlign: "justify",
              width: "fit-content",
            }}
          >
            The process is simple. You just need to enter your name and email (if you wish), verify your email, and provide us with additional information about yourself. If you cannot provide additional information, you can sign anonymously. Your signature will be added to the Declaration of Independence, which you can <a style={{fontWeight:"bold"}} href="/declaration">view here</a> .
          </p>
        </div>


      </section> */}

        <footer
          style={{ backgroundColor: "#000", color: "#fff", padding: "50px 0" }}
        >
          <div
            className="footer-container"
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              className="footer-logo"
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                marginBottom: "20px",
                flexBasis: "100%",
                textAlign: "center",
              }}
            >
              Declaration of Independence Project
            </div>
            <div
              className="footer-social"
              style={{
                display: "flex",
                alignItems: "center",
                flexBasis: "100%",
                justifyContent: "center",
                marginBottom: "0px",
              }}
            >
              <a href="/sign">
                <button
                  style={{
                    backgroundColor: "#fff",
                    color: "#222",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Do I Dare?
                </button>
              </a>
            </div>
            <div
              className="footer-additional"
              style={{
                backgroundColor: "#000",
                color: "#fff",
                padding: "20px 0",
                flexBasis: "100%",
              }}
            >
              <div
                className="social-icons"
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                <a href="https://instagram.com/sign1776?igshid=OGQ5ZDc2ODk2ZA==">
                  <FontAwesomeIcon
                    style={{ height: "40px" }}
                    icon={faInstagram}
                  />
                </a>
                <a href="https://x.com/doiproject">
                  <FontAwesomeIcon
                    style={{
                      height: "40px",
                      marginLeft: " 1.5rem",
                      marginRight: "1.5rem",
                    }}
                    icon={faXTwitter}
                  />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100092544002652&mibextid=LQQJ4d">
                  <FontAwesomeIcon
                    style={{ height: "40px" }}
                    icon={faFacebook}
                  />
                </a>
              </div>
              <div
                className="footer-additional-container"
                style={{
                  maxWidth: "800px",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {/* <p
                style={{
                  marginBottom: "20px",
                  flexBasis: "100%",
                  textAlign: "justify",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse non ex non metus dapibus hendrerit eu non quam.
                Pellentesque commodo massa mi, ac ullamcorper lorem tristique
                at. Maecenas vel lobortis dolor. Duis bibendum turpis eget
                rutrum tincidunt.
              </p> */}
                <div
                  className="footer-links"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexBasis: "100%",
                    marginTop: "2rem",
                    marginBottom: "-2.3rem",
                  }}
                >
                  <a
                    href="/privacy"
                    style={{
                      color: "#fff",
                      marginRight: "20px",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="/terms"
                    style={{
                      color: "#fff",
                      marginRight: "20px",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                  >
                    Terms and Conditions
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="footer-copy"
            style={{
              backgroundColor: "#000",
              color: "#fff",
              padding: "20px 0",
              textAlign: "center",
            }}
          >
            <p style={{ margin: "0 auto", padding: "10px", marginTop: "0px" }}>
              © 2023 Declaration of Independence Project. All Rights Reserved.
            </p>
            <div
              style={{ width: "100px", margin: "0 auto", paddingTop: "10px" }}
            >
              {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 110 32"
              class="sc-1y6dgmj-1 hhvqHc"
            >
              <path
                fill="#fff"
                d="M40.654 4.384H31.03v21.274h3.393v-7.19h6.23c2.287 0 4.093-.626 5.458-1.917 1.364-1.253 2.065-2.986 2.065-5.125 0-2.102-.701-3.834-2.028-5.125-1.402-1.29-3.245-1.917-5.494-1.917zm3.06 9.844c-.7.664-1.77.996-3.208.996h-6.083V7.665h6.083c1.438 0 2.508.332 3.208.996.7.663 1.032 1.585 1.032 2.802s-.331 2.102-1.032 2.765zM53.08 4.384h-3.392v21.274h3.392V4.384zM59.31 9.324h-3.392v16.334h3.393V9.324zM59.385 4.384h-3.577V7.85h3.577V4.384zM74.059 10.8c-1.217-1.144-2.802-1.734-4.72-1.734-2.47 0-4.461.812-5.936 2.434-1.438 1.549-2.175 3.54-2.175 5.862 0 2.323.737 4.314 2.175 5.863 1.475 1.622 3.43 2.433 5.789 2.433 1.99 0 3.65-.59 4.904-1.77v.627c0 1.438-.332 2.508-1.033 3.208-.663.7-1.843 1.07-3.429 1.07-2.101 0-3.392-.664-3.945-2.028l-.147-.332h-3.65l.22.737c.48 1.438 1.365 2.655 2.655 3.503 1.29.885 3.06 1.327 5.273 1.327 2.25 0 4.056-.627 5.42-1.88 1.364-1.254 2.065-3.06 2.065-5.42V9.325h-3.466v1.474zm-1.106 10.213a4.732 4.732 0 01-1.475 1.069 4.921 4.921 0 01-1.991.405c-1.512 0-2.655-.479-3.54-1.474-.885-.996-1.29-2.176-1.29-3.65 0-1.476.405-2.692 1.29-3.65.885-.996 2.065-1.476 3.54-1.476 1.438 0 2.58.48 3.466 1.438.848.959 1.253 2.176 1.253 3.65-.037 1.55-.442 2.729-1.253 3.688zM90.688 9.546c-.775-.295-1.733-.48-2.84-.48a5.66 5.66 0 00-3.023.848 6.75 6.75 0 00-1.143.885V4.384H80.29v21.274h3.392V16.33c0-1.217.369-2.175 1.18-2.95.811-.774 1.733-1.143 2.876-1.143 1.106 0 1.991.369 2.692 1.07.7.737 1.032 1.696 1.032 2.986v9.365h3.392v-9.402c0-2.175-.7-3.945-2.028-5.272-.627-.664-1.327-1.143-2.138-1.438zM105.731 9.324h-3.502v-4.94h-3.393v4.94h-3.134v3.098h3.134v13.236h3.393V12.422h3.502V9.324zM109.308 22.192h-3.577v3.466h3.577v-3.466z"
              ></path>
              <path
                fill="#FD6053"
                d="M16.252 0h-5.997v17.536h5.997c4.783 0 8.658-3.925 8.658-8.768C24.91 3.925 21.035 0 16.252 0z"
              ></path>
              <path
                fill="#A93F36"
                d="M5.15 29.985c-2.841 0-5.15-2.33-5.15-5.215V10.43C0 4.67 4.61 0 10.3 0v24.77c0 2.885-2.309 5.215-5.15 5.215z"
              ></path>
              <path
                fill="#FD6053"
                d="M.18 26.152c.562 2.262 2.586 3.94 4.992 3.94 2.842 0 5.15-2.339 5.15-5.216v-7.34c-5.075 0-9.295 3.72-10.142 8.616z"
              ></path>
            </svg> */}
            </div>

            {/* <p style={{ margin: "0 auto", padding: "10px", marginTop: "0px" }}>
            Powered by Plight.
          </p> */}
          </div>
        </footer>

        <div className="scroll-top-container">
          {showButton ? (
            <></>
          ) : (
            <button className="button-floating" onClick={handleClick2}>
              Learn More
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default MailingHome;
