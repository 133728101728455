import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import crypto from "crypto-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const VerifyGHL = ({ action = "GHL" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email"));
  const [GHL_ID, setGHL_ID] = useState(searchParams.get("GHL_ID"));
  const redirectTo = searchParams.get("redirect");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isMailingLogin, setIsMailingLogin] = useState(false);
  let redirectStr;
  let renderComponent;

  const referralSkeleton = (
    <Flex
      align="center"
      justify={"center"}
      py={4}
      px={2}
      flexDir="column"
      spacing={8}
      style={{
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/homePen.png')",
        backgroundSize: "cover",
        height: "fit-content",
        color: "white",
        minHeight: "93.7vh",
      }}
    >
      <Heading className="raffle-title" style={{ marginTop: "4rem" }}>
        Operation John Hancock
      </Heading>
      <Heading
        fontSize="lg"
        style={{ marginTop: "0.5rem" }}
        className="raffle-sub"
      >
        Earn stars by sharing your referral link with your friends and family!
      </Heading>

      {/* {isLoading && <Text>Loading...</Text>} */}

      {!error && (
        <Text
          bgColor="red.400"
          p={4}
          borderRadius={4}
          style={{ marginTop: "2rem", marginBottom: "2rem" }}
        >
          There was an error fetching the data
        </Text>
      )}

      <Box
        className="raffle-prize"
        bg="rgba(000, 000, 000, 0.1)"
        p={4}
        borderRadius="md"
        backdropFilter="blur(5px)"
        style={{ width: "41vw", textAlign: "center" }}
      >
        {/* <FontAwesomeIcon icon={faTrophy} size="2x" /> */}
        <Heading fontSize="2xl" mt={2} style={{ marginBottom: "-2rem" }}>
          Draw Prizes
        </Heading>
        <Text mt={2}>
          <div style={{ textAlign: "left", padding: "1rem" }}>
            {" "}
            <br />
            Opportunity to Join the DOIP Team in Delivering the physical copy,
            SIGN 1776 to Congress: <br />
            <br />
            • Fully Paid trip to D.C for two (3 Days, 2 Nights)
            <br />
            • Flights from anywhere in the U.S (Planned for September 2024)
            <br />
            • Lodging, Meals, Events
            <br />
            • Two Chances to Win (Announced 45 days before travel)
            <br />
            <br /> Draw – Luck of the draw,{" "}
            <span style={{ fontWeight: "bold" }}>4 All-Inclusive Packages</span>
            <br /> <span style={{ fontWeight: "bold" }}>
              Top Star Earner
            </span>{" "}
            gets 1 All-Inclusive Package (excludes Random Drawing)
          </div>
        </Text>
      </Box>
      <Heading
        className="raffle-title2"
        style={{ marginTop: "2rem", marginBottom: "2rem", fontSize: "1.8rem" }}
      >
        More Stars, More Chances!
      </Heading>

      <Box
        display={{ base: "block", md: "grid" }}
        gridTemplateColumns={{ md: "repeat(2, 1fr)" }}
        gap={4}
        maxWidth="60vw"
        className="raffle-box"
      >
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          {/* <FontAwesomeIcon icon={faSignature} size="2x" /> */}
          <Heading fontSize="lg" mt={2}>
            Your Digital Signature
          </Heading>
          <Text mt={2}>- 1 star for your digital signature.</Text>
        </Box>
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          {/* <FontAwesomeIcon icon={faPenFancy} size="2x" /> */}
          <Heading fontSize="lg" mt={2}>
            Your Physical Signature
          </Heading>
          <Text mt={2}>
            - 3 stars by becoming a Percenter (Small Signature)
            <br /> - 15 stars by being part of the Colonies (Medium Signature)
            <br /> - 25 stars by becoming a founder (John Hancock
            Sized Signature)
          </Text>
        </Box>
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          {/* <FontAwesomeIcon icon={faAsterisk} size="2x" /> */}
          <Heading fontSize="lg" mt={2}>
            Referral Bonuses
          </Heading>
          <Text mt={2}>
            - 2 Stars for each referred individual that digitally signs.
            <br /> - 5 Stars for each referred individual that becomes a COLONY
            or FOUNDER.
          </Text>
        </Box>
        <Box
          bg="rgba(000, 000, 000, 0.1)"
          p={4}
          borderRadius="md"
          backdropFilter="blur(5px)"
          style={{ width: "20vw" }}
          className="raffle-box"
        >
          {/* <FontAwesomeIcon icon={faPlus} size="2x" /> */}
          <Heading fontSize="lg" mt={2}>
            Additional Incentives{" "}
          </Heading>
          <Text mt={2}>
            - 20 Stars for every 10 COLONIES or FOUNDERS referred. <br />-
            Framed hard copy of the DOI (Declaration of Independence) for
            referring 20 individuals that become COLONIES or FOUNDERS <br />
          </Text>
        </Box>
      </Box>

      <a href="/select-size">
        <button
          style={{
            // backgroundColor: "white",
            backgroundColor: "#B22234", // Azul oscuro
            marginTop: "2rem",
            color: "white",
            border: "none",
            borderRadius: "8px",
            padding: "15px 40px",
            fontWeight: "bold",
            fontSize: "16px",
            letterSpacing: "1px",
            cursor: "pointer",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Sombra
            marginBottom: "2rem",
          }}
        >
          Get Physical Signature
        </button>
      </a>
    </Flex>
  );

  const selectSizeRSSkeleton = (
    <section
      style={{
        width: "99.99vw",
        minHeight: "93.8vh",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
      }}
      className="sec-size"
    >
      <a href="/thanks">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="is-mobile"
          style={{
            marginLeft: "1rem",
            position: "absolute",
            top: "80px",
            left: "10px",
            color: "white",
            height: "25px",
            marginBottom: "1rem",
          }}
        />
      </a>

      <Heading
        as="h1"
        fontSize="4xl"
        style={{
          color: "white",
          textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
          marginBottom: "1rem",
          marginTop: "2rem",
          textAlign: "center",
        }}
        className="title"
      >
        Roger Stone supports The DOI Project <br className="br" /> just like you
        do!
      </Heading>

      <div className="video-container">
        <video
          className="video"
          // src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/BRAVE_CUT_v03.mp4"
          autoPlay={!true}
          loop
          controls
        />
      </div>

      <div
        id="down"
        className="div-first"
        style={{ textAlign: "center", marginTop: "-2rem" }}
      >
        {/* <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginBottom: "1rem",
          }}
          className="title"
        >
          Help us protect our future!
        </Heading> */}
        <Text
          fontSize="xl"
          color={"white"}
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
          }}
          className="subro"
        >
          Be Brave like Roger Stone and join the DOIP Team in Delivering the
          physical copy of The DOI to congress!{" "}
        </Text>
      </div>
      <div className="reachout">
        <div className="container container2">
          <div className="signature-overlay">
            {1 >= 0 && 1 <= 2 && (
              <div className="preview-img2" style={{ position: "relative" }}>
                <img
                  className="preview-img2"
                  src="\assets\signature-preview.png"
                  alt=""
                />
                <h1
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontFamily: "Beth Ellen",
                    fontSize: 1,
                  }}
                  className={`size${0 + 1}`}
                >
                  John Hancock
                </h1>
              </div>
            )}
          </div>
        </div>
        <div className="container container2">
          <button
            className={`square2 ${1 === 0 ? "active2" : ""}`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $3{" "}
            </h1>
            <h1 className="text">Percenters</h1>

            <h1 className="sub">Small Signature</h1>
          </button>
          <button
            className={`square2 ${0 === 1 ? "active2" : ""}`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $13{" "}
            </h1>
            <h1 className="text">Colonies</h1>

            <h1 className="sub">Medium Signature</h1>
          </button>
          <button
            className={`square2 ${0 === 2 ? "active2" : ""}`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $17.76{" "}
            </h1>
            <h1 className="text">Founders</h1>

            <h1 className="sub">John Hancock Sized</h1>
          </button>
        </div>
      </div>

      <div className="container3">
        <div className="button-container2">
          <button
            style={{
              backgroundColor: "gray",
              borderColor: "gray",
            }}
            className="purchase-button2"
          >
            <div>
              <span>Purchase Now</span>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: "1rem" }}
              />
            </div>
            <div>
              {0 === 0 && (
                <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                  1x Small signature
                </div>
              )}
              {0 === 1 && (
                <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                  1x Medium signature
                </div>
              )}
              {0 === 2 && (
                <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                  1x John Hancock sized signature
                </div>
              )}
            </div>
          </button>

          <a href="/declaration" style={{ marginRight: "10px" }}>
            <button
              className="cancel-button2"
              // onClick={handleCancel} // Función de manejo de clic en cancelar
            >
              Go back
            </button>
          </a>
        </div>
      </div>
    </section>
  );

  const selectSizeSkeleton = (
    <div>
      <FontAwesomeIcon
        icon={faArrowLeft}
        className="is-mobile"
        style={{
          marginLeft: "1rem",
          position: "absolute",
          top: "80px",
          left: "10px",
          color: "white",
          height: "25px",
          marginBottom: "1rem",
        }}
      />

      <div
        className="div-first"
        style={{ textAlign: "center", marginTop: "-2rem" }}
      >
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginBottom: "1rem",
          }}
          className="title"
        >
          Help us protect our future!
        </Heading>
        <Text
          fontSize="xl"
          color={"white"}
          style={{
            marginBottom: "1rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
          }}
          className="subro"
        >
          Select a size and add your name to the Declaration of Independence.
        </Text>
      </div>
      <div className="reachout">
        <div className="container container2">
          <div className="signature-overlay">
            <div className="preview-img2" style={{ position: "relative" }}>
              <img
                className="preview-img2"
                src="\assets\signature-preview.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container container2">
          <button
            className={`square2 `}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $3{" "}
            </h1>
            <h1 className="text">Percenters</h1>

            <h1 className="sub">Small Signature</h1>
          </button>
          <button
            className={`square2 `}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $13{" "}
            </h1>
            <h1 className="text">Colonies</h1>

            <h1 className="sub">Medium Signature</h1>
          </button>
          <button
            className={`square2 `}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $17.76{" "}
            </h1>
            <h1 className="text">Founders</h1>

            <h1 className="sub">John Hancock Sized</h1>
          </button>
        </div>
      </div>

      <div className="container3">
        <div className="button-container2">
          <button
            style={{
              backgroundColor: "#b22234",
              borderColor: "#b22234",
            }}
            className="purchase-button2"
          >
            <div>
              <span>Purchase Now</span>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: "1rem" }}
              />
            </div>
            <div>
              <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                1x John Hancock sized signature
              </div>
            </div>
          </button>

          <a href="/declaration" style={{ marginRight: "10px" }}>
            <button
              className="cancel-button2"
              // onClick={handleCancel} // Función de manejo de clic en cancelar
            >
              Go back
            </button>
          </a>
        </div>
      </div>
    </div>
  );

  const thanksSkeleton = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "93.8vh",
        backgroundImage:
          "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        backgroundSize: "cover",
      }}
    >
      <div
        className="div-firstth"
        style={{ textAlign: "center", marginTop: "3rem" }}
      >
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginBottom: "1rem",
          }}
          className="titleth"
        >
          Thank you for signing the online Declaration of Independence!
        </Heading>
        <Text
          fontSize="xl"
          color={"white"}
          style={{
            marginBottom: "1rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
          }}
          className="subtitleth"
        >
          Select a size for your signature on the physical Declaration of
          Independence that will be presented to Congress.
        </Text>
      </div>

      <div className="reachout">
        <div className="container container2">
          <div className="signature-overlay"></div>
        </div>
        <div className="container container2">
          <button
            className={`square2`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $3
            </h1>
            <h1 className="text">Percenters</h1>
            <h1 className="sub"> Small Signature</h1>
          </button>
          <button
            className={`square2`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $13{" "}
            </h1>
            <h1 className="text">Colonies</h1>

            <h1 className="sub">Medium Signature</h1>
          </button>
          <button
            className={`square2`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $17.76{" "}
            </h1>
            <h1 className="text">Founders</h1>

            <h1 className="sub">John Hancock Sized</h1>
          </button>
        </div>
      </div>

      <div className="container3">
        <div className="button-container2">
          <button
            style={{
              backgroundColor: "#b22234",
              borderColor: "#b22234",
            }}
            className="purchase-button3"
          >
            <div>
              <span>Purchase Now</span>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: "1rem" }}
              />
            </div>
            <div>
              <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                1x John Hancock sized signature
              </div>
            </div>
          </button>

          <a href="/declaration" style={{ marginRight: "10px" }}>
            <button
              className="cancel-button2"
              // onClick={handleCancel} // Función de manejo de clic en cancelar
            >
              Go back
            </button>
          </a>
        </div>
      </div>

      <div
        className="gif-div"
        style={{
          height: "fit-content",
          width: "100%",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <div style={{ position: "absolute", textAlign: "center" }}>
          <a href="/declaration">
            <button
              className="find-button"
              style={{
                backgroundColor: "#2C4C70",
                borderRadius: "8px",
                width: "fit-content",
                maxWidth: "50vw",
                padding: "12px 40px",
                color: "white",
                fontSize: "1.4rem",
                fontWeight: "400",
                marginTop: "1rem",
              }}
            >
              Find my signature in the online Declaration of Independence!
            </button>
          </a>
        </div>
      </div>

      <div className="share-section">
        <h1 className="share-title">Share with your friends!</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box h="50px"></Box>
        </div>
      </div>
    </div>
  );

  switch (redirectTo) {
    case "select-size":
      redirectStr = "/select-size/r";
      renderComponent = selectSizeSkeleton;
      break;
    case "select-size-rs":
      redirectStr = "/select-size/rs";
      renderComponent = selectSizeRSSkeleton;
      break;
    case "thanks":
      redirectStr = "/thanks";
      renderComponent = thanksSkeleton;
      break;
    case "thanks-signature":
      redirectStr = "/thanks-signature";
      renderComponent = thanksSkeleton;
      break;
    case "shop":
      redirectStr = "/shop";
      renderComponent = selectSizeSkeleton;
      break;
    case "referral":
      redirectStr = "/referral";
      renderComponent = referralSkeleton;
      break;
    default:
      redirectStr = "/select-size/r";
      renderComponent = selectSizeSkeleton;
      break;
  }

  const verifyUser = async () => {
    try {
      const response = await axios.post(`/api/verify/${action}`, {
        email,
        GHL_ID,
      });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("signature", response.data.signature);
      localStorage.setItem("email", email);

      // trigger navigation to the home page on a 1 second timeout
      setTimeout(() => {
        window.location.href = redirectStr;
      }, 1000);
    } catch (error) {
      // setError(true);
      setMessage("Something went wrong. Please try again later.");
      // setMessage(error.response.data.error);
    }
  };

  useEffect(() => {
    verifyUser();
  }, []);

  return (
    <div>
      <section
        style={{
          width: "99.99vw",
          minHeight: "99.99vh",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        }}
        className="sec-size"
      >
        {renderComponent}
      </section>
      <Flex
        position="absolute"
        backdropFilter={"blur(6px)"}
        top={0}
        height="99.99vh"
        width="99.99vw"
        // bgColor="blue"
      >
        <AbsoluteCenter>
          {error ? (
            <Flex
              h="10vh"
              align="center"
              justify="center"
              borderRadius={12}
              p={12}
              bgColor="#b22234"
              color="white"
            >
              <Text fontWeight={600}>
                Oops! Something went wrong. Please try again in a few minutes.
              </Text>
            </Flex>
          ) : (
            <Spinner boxSize={32} thickness="8px" color="#b22234" />
          )}
        </AbsoluteCenter>
      </Flex>
    </div>
  );
};

export default VerifyGHL;
