import { createBrowserRouter } from "react-router-dom";
import NavBar from "../components/Layout";
import WithSubnavigation from "../components/Navigation";
import Background from "./Background";
import Countdown from "./Countdown";
import Simple from "./DeclarationCopy";
import Shop from "./Donate";
import FileUpload from "./FileUpload";
import Home from "./Home";
import Privacy from "./Privacy";
import Process from "./Process";
import SignaturePage from "./SignaturePage";
import DoiAtlas from "../components/DoiAtlas";
import Subscribe from "./Subscribe";
import Terms from "./Terms";
import Declaration from "./declaration";
import Verify from "./Verify";
import Thanks from "./Thanks";
import Sizes from "./Sizes";
import VerifyEmailPage from "./VerifyEmailPage";
import Login from "./Login";
import ThanksStripe from "./ThanksStripe";
import MailingHome from "./MailingHome";
import Resend from "./Resend";
import Unsubscribe from "./Unsubscribe";
import CePromo from "./CePromo";
import SignatureCe from "./SignatureCe";
import VerifyEmailCe from "./VerifyEmailCe";
import Signers from "./Signers";
import ContactUs from "./ContactUs";
import ReachOut from "./ReachOut";
import ReachOutRS from "./ReachOutRS";

import VerifyGHL from "./VerifyGHL";

// import PromoG from "./PromoG";
import { Referrals } from "./Referrals";
import ReachOutRS_Sign from "./ReachOutRS_Sign";
import Testimonials from "./Testimonials";
import AdvertisingLanding from "./AdvertisingLanding";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <NavBar />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/cepromo",
        element: <CePromo />,
      },
      {
        path: "/sign",
        element: <SignaturePage />,
      },
      {
        path: "/signce",
        element: <SignatureCe />,
      },
      {
        path: "/background",
        element: <Background />,
      },
      {
        path: "/process",
        element: <Process />,
      },
      {
        path: "/fileupload",
        element: <FileUpload />,
      },
      {
        path: "/countdown",
        element: <Countdown />,
      },
      {
        path: "/atlas",
        element: <DoiAtlas />,
      },
      {
        path: "/declaration",
        element: <Declaration />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/subscribe",
        element: <Subscribe />,
      },
      {
        path: "/unsubscribe",
        element: <Unsubscribe />,
      },
      {
        path: "/shop",
        element: <Shop />,
      },
      {
        path: "/shop/declaration",
        element: <Simple />,
      },
      {
        path: "/thanks",
        element: <Thanks />,
      },
      {
        path: "/select-size",
        element: <Sizes />,
      },
      {
        path: "/verify-email-page",
        element: <VerifyEmailPage />,
      },
      {
        path: "/verify-email-ce",
        element: <VerifyEmailCe />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/resend",
        element: <Resend />,
      },
      {
        path: "/thanks-signature",
        element: <ThanksStripe />,
      },
      {
        path: "/signers",
        element: <Signers />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/select-size/r",
        element: <ReachOut />,
      },
      {
        path: "/select-size/rs",
        element: <ReachOutRS />,
      },
      {
        path: "/referral",
        element: <Referrals />,
      },
      {
        path: "/testimonials",
        element: <Testimonials />,
      },
    ],
  },
  {
    path: "/verify/login",
    element: <Verify action={"login"} />,
  },
  {
    path: "/verify/mailinglogin",
    element: <Verify action={"mailinglogin"} />,
  },
  {
    path: "/verify/register",
    element: <Verify action={"register"} />,
  },
  {
    path: "/landing/g",
    element: <VerifyGHL />,
  },
  {
    path: "/sign/rs",
    element: <ReachOutRS_Sign />,
  },
  {
    path: "/promo",
    element: <AdvertisingLanding />,
  },
]);
