import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

// import declThumnbnail from "../assets/thumb decl.png";

import ProductCard from "../components/ProductCard";
import axios from "axios";
import TagManager from "react-gtm-module";

const handlePurchase = () => {
  if (!localStorage.getItem("email")) {
    window.location.href = "/login";
  }
  axios
    .post("/api/generate-payment-link", {
      email: localStorage.getItem("email"),
      item: "replica",
    })
    .then((res) => {
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: "itemSold",
      //     value: 50,
      //     currency: "USD",
      //   },
      // });
      // redirect to the URL from the response
      window.location.href = res.data;
    });
};

const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={useColorModeValue("gray.100", "gray.700")}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
        <Button variant={"link"} colorScheme={"blue"} size={"sm"}>
          Learn more
        </Button>
      </Stack>
    </Box>
  );
};

const Shop = () => {
  return (
    <Flex
      p={2}
      flexDir="column"
      style={{
        backgroundImage:
          "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        backgroundSize: "cover",
        color: "white",
      }}
    >
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
        align="center"
        style={{ marginTop: "2rem" }}
      >
        Get our newest products here!
      </Heading>
      <Box>
        <Container maxW={"5xl"} mt={12}>
          <Flex
            borderRadius={8}
            p={6}
            h="fit-content"
            align="center"
            justify="center"
            flexDir="column"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)", // Transparencia blanca
              backdropFilter: "blur(10px)",
            }}
          >
            <Image
              src={"/assets/dec_shop.jpg"}
              alt="Declaration of Independence"
              // w="200px"
              h="60vh"
              w="100%"
              objectFit={"cover"}
              objectPosition='top center'
              borderRadius={8}
            />
            <Heading
              lineHeight={1}
              fontWeight={600}
              fontSize={{ base: "l", sm: "xl", lg: "2xl" }}
              align="center"
              style={{ marginTop: "1rem" }}
              p={2}
            >
              Get a replica of the Declaration of Independence with <b>YOUR</b>{" "}
              signature on it!
            </Heading>
            <Button
              style={{ marginTop: "1rem" }}
              bgColor="yellow.500"
              onClick={handlePurchase}
            >
              Buy Now!
            </Button>
          </Flex>
          <Flex flexWrap="wrap" gridGap={5} justify="center" p={4}>
            {/* <ProductCard
              product={{
                name: "Independence Shirt",
                image: "/assets/shirt.png",
              }}
            /> */}
            <ProductCard
              product={{
                name: "Independence Mug",
                image:
                  "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/cupShop.png",
              }}
            />
            <ProductCard
              product={{
                name: "Independence Cap",
                image:
                  "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/hatShop.png",
              }}
            />
            <ProductCard
              product={{
                name: "Independence Hoodie DOI",
                image:
                  "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/SwDOIback.png",
              }}
            />
            <ProductCard
              product={{
                name: "Independence Hoodie Declaration",
                image:
                  "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/SwDecBack.png",
              }}
            />
            <ProductCard
              product={{
                name: "Independence T-shirt DOI",
                image:
                  "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/ShirtDoiBack.png",
              }}
            />
            <ProductCard
              product={{
                name: "Independence T-shirt Declaration",
                image:
                  "https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/ShirtDecBack.png",
              }}
            />
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
};

export default Shop;
