import logo from "./logo.svg";
import "./App.css";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TWR6RM2",
  events: {
    userSigned: "userSigned",
    itemSold: "itemSold",
  },
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
