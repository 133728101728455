import { Hero } from "../components/Advertising/hero/Hero";
import { Against } from "../components/Advertising/against/Against";
import { DeclarationCTA } from "../components/Advertising/declarationCTA/DeclarationCTA";
import { Participate } from "../components/Advertising/participate/Participate";
import { Banner } from "../components/Advertising/banner/Banner";
import { Carousel } from "../components/Advertising/carousel/Carousel";
import { BannerText } from "../components/Advertising/bannerText/BannerText";
import { OurMission } from "../components/Advertising/ourMission/OurMission";
import { SignerCarousel } from "../components/Advertising/signerCarousel/SignerCarousel";
import { Footer } from "../components/Advertising/footer/Footer";
import { Freedom } from "../components/Advertising/freedom/Freedom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useState, useEffect } from "react";
import axios from "axios";

const AdvertisingLanding = () => {
  const [currentNumber, setCurrentNumber] = useState(59700);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  useEffect(() => {
    axios.get("/api/users/signaturecount").then((res) => {
      setCurrentNumber(res.data.signatureCount);
    });
  }, []);

  return (
    <>
      <Hero />
      <Against />
      <DeclarationCTA />
      <Participate />
      <Banner />
      <Carousel />
      <BannerText />
      <OurMission />
      <SignerCarousel
        currentNumber={currentNumber}
        setCurrentNumber={setCurrentNumber}
      />
      <Freedom />
      <Footer />
    </>
  );
};

export default AdvertisingLanding;
