import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  HStack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import "../styles/Navigation.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser } from "@fortawesome/free-solid-svg-icons";

import { useLocation } from "react-router-dom";

export default function WithSubnavigation() {
  const token = localStorage.getItem("token") || null;
  const { isOpen, onToggle } = useDisclosure();

  // Obtén la ruta actual utilizando el hook useLocation
  const location = useLocation();
  const currentPath = location.pathname;
  // Establece el enlace predeterminado
  let href = "/sign";

  // Si la ruta actual es /cepromo, cambia el enlace
  if (currentPath === "/cepromo") {
    href = "/cepromo#sign"; // Cambia '/otro-enlace' por la ruta deseada
  }
  if (currentPath === "/promo") {
    href = "/promo#sign"; // Cambia '/otro-enlace' por la ruta deseada
  }

  return (
    <Box position="relative" top="0" zIndex="sticky">
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          {(currentPath === "/select-size/rs" || currentPath === "/sign/rs") ? (
              <></>
            ) : (
              <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          )}
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"heading"}
            color={useColorModeValue("gray.800", "white")}
          >
            {/* Verifica si el path es "/select-size/rs" y solo muestra el logo */}
            {(currentPath === "/select-size/rs" || currentPath === "/sign/rs") ? (
              <div className="logo-rs">
                <img src="/assets/Doi_alternative.png" width="50" alt="" />
              </div>
            ) : (
              <a href="/">
                <img src="/assets/Doi_alternative.png" width="50" alt="" />
              </a>
            )}
          </Text>

          {(currentPath !== "/select-size/rs" && currentPath !== "/sign/rs") && (
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          )}
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
          align="center"
        >
          {token && (currentPath !== "/select-size/rs" && currentPath !== "/sign/rs") ? (
            <HStack>
              <Button
                as={"a"}
                display={"inline-flex"}
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"black"}
                href={"/sign"}
                _hover={{
                  bg: "black",
                }}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: "#FFF" }}
                  className="fa-xl"
                />
                <Text p={2} display={{ base: "none", md: "inline" }}>
                  My Profile
                </Text>
              </Button>
            </HStack>
          ) : (currentPath === "/select-size/rs" || currentPath === "/sign/rs") ? (
            <></>
          ) : (
            <Button
              as={"a"}
              display={"inline-flex"}
              fontSize={"sm"}
              fontWeight={600}
              color={"white"}
              bg={"black"}
              href={href}
              _hover={{
                bg: "black",
              }}
            >
              Sign Declaration
            </Button>
          )}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onToggle={onToggle} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = ({ onToggle }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
      style={{ position:"relative", zIndex:'9', width:"100vw"}}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onToggle={onToggle} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, onToggle }) => {
  const { isOpen, onToggle: onToggleSubmenu } = useDisclosure();

  // Función para cerrar el menú
  const closeMenu = () => {
    if (isOpen) {
      onToggle();
    }
  };

  // Función para ejecutar todas las funciones en el onClick del Stack
  const handleClick = () => {
    if (children) {
      onToggle(); // Cierra el menú
    }
  };

  return (
    <Stack spacing={4} onClick={children ? onToggleSubmenu : onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        onClick={closeMenu}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "View Declaration",
    href: "/declaration",
  },
  {
    label: "Heroes Archive",
    href: "/signers",
  },
  // {
  //   label: "Testimonials",
  //   href: "/testimonials",
  // },
  {
    label: "Operation John Hancock",
    href: "/referral",
  },
];
