import React, { useEffect, useState, useRef } from "react";

import "../styles/Home.css";
import "../styles/Thanks.css";
import "../styles/size.css";

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IoLogoFacebook } from "react-icons/io";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { BiMessageRounded } from "react-icons/bi";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";

import { ReactSVG } from "react-svg";

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Thanks = () => {
  const purchaseOptions = [
    { item: "small_signature" },
    { item: "medium_signature" },
    { item: "hancock_signature" },
  ];

  const gifRef = useRef(null);

  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(2);
  const svgWidths = ["17%", "20%", "26%"];
  const fontSizes = ["1.8rem", "2.5rem", "3.8rem"];
  const emailLogged = localStorage.getItem("email") || null;

  const [signature, setSignature] = useState("");

  const [svg, setSvg] = useState("");

  const token = localStorage.getItem("token") || null;
  let [searchParams, setSearchParams] = useSearchParams();

  const [bought, setBought] = useState(false);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const handlePurchase = () => {
    if (activeIndex !== null) {
      axios
        .post("/api/generate-payment-link", {
          email: localStorage.getItem("email"),
          item: purchaseOptions[activeIndex].item,
        })
        .then((res) => {
          // TagManager.dataLayer({
          //   dataLayer: {
          //     event: "itemSold",
          //   },
          // });
          // redirect to the URL from the response
          window.location.href = res.data;
        });
    }
  };

  useEffect(() => {
    axios.get(`/api/user/orders?email=${emailLogged}`).then((res) => {
      console.log(res.data);
      if (res.data.boughtSignature == true) {
        setBought(true);
      }
    });
  }, []);

  useEffect(() => {
    if (emailLogged) {
      axios
        .get(`/api/user?email=${emailLogged}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const usr = res.data;

            setSignature(usr.signature.name);
            setSvg(usr.signature.svg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Reinicia el GIF
      gifRef.current.src = "./assets/frame.gif";
    }, 4000); // Repite cada 3 segundos

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const shareUrl = "https://sign1776.com";
  const subject =
    "I just signed the Declaration of Independence! Do you dare to do the same?";
  const encodedUrl = encodeURIComponent(shareUrl);
  const shareText =
    "I just signed The Declaration of Independence! Be brave like me and reaffirm your commitment to the most important document in American History!";
  const encodedShareText = encodeURIComponent(shareText);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "93.8vh",
        backgroundImage:
          "url('https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/Ship.jpeg')",
        backgroundSize: "cover",
      }}
    >
      <div
        className="div-firstth"
        style={{ textAlign: "center", marginTop: "3rem" }}
      >
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginBottom: "1rem",
          }}
          className="titleth"
        >
          Thank you for signing the online Declaration of Independence!
        </Heading>
        <Text
          fontSize="xl"
          color={"white"}
          style={{
            marginBottom: "1rem",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
          }}
          className="subtitleth"
        >
          Select a size for your signature on the physical Declaration of
          Independence that will be presented to Congress.
        </Text>
      </div>

      <div className="reachout">
        <div className="container container2">
          <div className="signature-overlay">
            {activeIndex >= 0 && activeIndex <= 2 && (
              <div className="preview-img3" style={{ position: "relative" }}>
                <img
                  className="preview-img3"
                  src="\assets\signature-preview_hancock.png"
                  alt=""
                />

                {svg ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "65%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: svgWidths[activeIndex],
                      maxWidth: "70vw !important",
                    }}
                  >
                    <ReactSVG src={svg} />
                  </div>
                ) : (
                  <h1
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "65%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontFamily: "Beth Ellen",
                      fontSize: fontSizes[activeIndex],
                      userSelect: "none",
                      WebkitUserSelect: "none",
                    }}
                    className={`size${activeIndex + 1}`}
                  >
                    {signature}
                  </h1>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="container container2">
          <button
            className={`square2 ${activeIndex === 0 ? "active2" : ""}`}
            onClick={() => handleClick(0)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $3
            </h1>
            <h1 className="text">Percenters</h1>
            <h1 className="sub"> Small Signature</h1>
          </button>
          <button
            className={`square2 ${activeIndex === 1 ? "active2" : ""}`}
            onClick={() => handleClick(1)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $13{" "}
            </h1>
            <h1 className="text">Colonies</h1>

            <h1 className="sub">Medium Signature</h1>
          </button>
          <button
            className={`square2 ${activeIndex === 2 ? "active2" : ""}`}
            onClick={() => handleClick(2)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span
              className="most-popular"
              style={{
                position: "absolute",
                top: "-5px",
                backgroundColor: "#b22234",
                width: "101%",
                borderRadius: "12px 12px 0px 0px",
                textAlign: "center",
              }}
            >
              {" "}
              Most Popular
            </span>
            <h1 className="price" style={{ marginBottom: "0px" }}>
              $17.76{" "}
            </h1>
            <h1 className="text">Founders</h1>

            <h1 className="sub">John Hancock Sized</h1>
          </button>
        </div>
      </div>

      <div className="container3">
        <div className="button-container2">
          {bought ? (
            <button
              style={{
                backgroundColor: "#b22234",
                borderColor: "#b22234",
                cursor: "default",
              }}
              className="purchase-button3"
              onClick={handlePurchase}
              disabled={bought}
            >
              <div>
                <span>
                  You already paid for your Signature. Thanks for your support!
                </span>
              </div>
            </button>
          ) : (
            <button
              style={{
                backgroundColor: bought ? "gray" : "#b22234",
                borderColor: bought ? "gray" : "#b22234",
              }}
              className="purchase-button2"
              onClick={handlePurchase}
              disabled={bought}
            >
              <div>
                <span>Purchase Now</span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginLeft: "1rem" }}
                />
              </div>
              <div>
                {activeIndex === 0 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x Small signature
                  </div>
                )}
                {activeIndex === 1 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x Medium signature
                  </div>
                )}
                {activeIndex === 2 && (
                  <div style={{ fontWeight: "400", fontSize: "0.9rem" }}>
                    1x John Hancock sized signature
                  </div>
                )}
              </div>
            </button>
          )}

          {/* <a href="/declaration" style={{ marginRight: "10px" }}>
            <button
              className="cancel-button2"
              // onClick={handleCancel} // Función de manejo de clic en cancelar
            >
              Go back
            </button>
          </a> */}
        </div>
      </div>

      <div
        className="gif-div"
        style={{
          height: "fit-content",
          width: "100%",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <img
          ref={gifRef}
          className="gif"
          src=".\assets\frame.gif"
          alt=""
          style={{
            height: "25vh",
            width: "55vw",
            objectFit: "cover",
            borderRadius: "12px",
          }}
        />

        <div style={{ position: "absolute", textAlign: "center" }}>
          <a href="/declaration">
            <button
              className="find-button"
              style={{
                backgroundColor: "#2C4C70",
                borderRadius: "8px",
                width: "fit-content",
                maxWidth: "50vw",
                padding: "12px 40px",
                color: "white",
                fontSize: "1.4rem",
                fontWeight: "400",
                marginTop: "1rem",
              }}
            >
              Find my signature in the online Declaration of Independence!
            </button>
          </a>
        </div>
      </div>

      <div className="share-section operation" style={{ marginBottom: "0px" }}>
        <Heading
          as="h1"
          fontSize="4xl"
          style={{
            color: "white",
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)", // Sombras de texto
            marginTop: "2rem",
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "600",
          }}
          className="titleth"
        >
          Operation John hancock{" "}
        </Heading>

        <Text
          fontSize="xl"
          color={"white"}
          style={{
            textShadow: "4px 4px 4px rgba(0, 0, 0, 0.3)",
          }}
          className="subtitleth"
        >
          Earn stars by sharing your referral link with your friends and family!
        </Text>
        <a href="/referral">
          <button
            style={{
              // backgroundColor: "white",
              backgroundColor: "#B22234", // Azul oscuro
              marginTop: "2rem",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "15px 40px",
              fontWeight: "bold",
              fontSize: "16px",
              letterSpacing: "1px",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Sombra
            }}
          >
            Participate
          </button>
        </a>
      </div>

      <div className="share-section">
        <h1 className="share-title">Share with your friends!</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            className="share-button"
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${encodedShareText}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-icon"
              style={{ width: "30px" }}
              src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/social_icons/fb_logo.webp"
              alt=""
            />
          </a>
          <a
            className="share-button"
            href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodedShareText}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-icon-tw social-icon"
              style={{ width: "50px", marginLeft: "3px" }}
              src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/social_icons/tw_logo.webp"
              alt=""
            />
          </a>
          <a
            className="share-button"
            href={`sms:?&body=${encodedShareText} ${encodedUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-icon"
              style={{ width: "30px", marginLeft: "2px" }}
              src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/social_icons/imessage_logo.png"
              alt=""
            />
          </a>
          <a
            className="share-button"
            href={`mailto:?subject=${subject}&body=${encodedShareText} ${encodedUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-icon-mail social-icon"
              style={{ width: "35px", marginLeft: "10px" }}
              src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/social_icons/gmail_logo.png"
              alt=""
            />
          </a>
          <a
            className="share-button"
            href="https://www.instagram.com/p/CrRHNYXpW7n/?igshid=NTc4MTIwNjQ2YQ=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-icon"
              style={{ width: "33px", marginLeft: "10px" }}
              src="https://sfo3.digitaloceanspaces.com/ww-space/doi/doi_assets/social_icons/instagram_logo.webp"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
